<div class="header">{{ "QUIZ.QUIZZES" | translate }}</div>
<mat-card *ngFor="let quiz of allQuizzes | async"
          class="quiz-card">
    <mat-card-header>
        <mat-card-title>{{ quiz.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>{{ quiz.id }}</mat-card-subtitle>
    <mat-card-content>
        <div>{{ quiz.description }}</div>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button
                color="primary"
                (click)="runQuiz(quiz.id)">
            {{ "QUIZ.RUN" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
