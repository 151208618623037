import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { ProgressComponent } from './progress/progress.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { TranslateModule } from '@ngx-translate/core';
// import { AngularTiltModule } from 'angular-tilt';

@NgModule({
    declarations: [
        HeaderComponent,
        UserHeaderComponent,
        ProgressComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        TranslateModule
        // AngularTiltModule
    ],
    exports: [
        HeaderComponent,
        UserHeaderComponent,
        ProgressComponent
    ]
})
export class HeaderModule {}
