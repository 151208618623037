<div class="auth-wrapper">
    <img src="assets/images/Logo.png"
         alt="Gambo"
         class="logo">
    <div>Login or sign up to continue</div>
    <button mat-stroked-button
            color="primary"
            (click)="authService.authWithGoogle()">
        Login with Google
    </button>
    <button mat-stroked-button
            color="primary"
            (click)="authService.authWithFacebook()">
        Login with Facebook
    </button>

    <ng-container *ngIf="allowAnonymous">
        <div>Or just enter nickname to play without saving history</div>
        <mat-form-field appearance="fill"
                        color="primary">
            <mat-label>Nickname</mat-label>
            <input matInput type="text" [(ngModel)]="authService.nickname">
        </mat-form-field>
        <br/>
        <button mat-raised-button
                color="primary"
                *ngIf="authService.nickname"
                (click)="authService.authAnon()">
            Login anonymously
        </button>
    </ng-container>
</div>
