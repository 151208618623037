import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerType, QuestionType, QuizConstructorService, QuizQuestionInfo, QuestionTemplateInfo } from '../../quiz-constructor.service';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    questionTemplateSelector,
    selectedQuestionSelector,
    selectedQuizFilesUploadingStatusSelector
} from '../../store/quiz-constructor.selectors';
import { AppState } from '../../../../app/app.state';
import { distinctUntilChanged } from 'rxjs';
import * as quizConstructorActions from '../../store/quiz-constructor.actions';
import { convertAudioBufferToShape } from '../../../../libs/player/audio-buffert-shape-converter';
import { UnsubscribeSubjectComponent } from '../../../../libs/unsubscribe-subject-component';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-question-generation',
    templateUrl: './question-generation.component.html',
    styleUrls: ['./question-generation.component.scss']
})
export class QuestionGenerationComponent extends UnsubscribeSubjectComponent implements OnInit {

    public selectedQuestion: QuizQuestionInfo;
    public questionTemplate: boolean;

    public premium = true;

    public questionFileChanged = false;
    public questionFileNeedUpload = false;
    public answerDescriptionFileChanged = false;
    public answerDescriptionFileNeedUpload = false;

    public questionTypes = QuestionType;
    // public selectedQuestionType = QuestionType.TEXT;

    public answerTypes = AnswerType;
    /*public selectedAnswerType = AnswerType.ONE;

    public textQuestion: string;
    public textAnswers: Array<AnswerInfo>;
    public time = 20;
    public points = 10;*/


    file: Blob;
    fileSrc: string;


    public processing = false;
    public sourceAudioFile: Blob;
    public sourceAudioBuffer: AudioBuffer;
    public audioLength: number;
    public startCutValue: number;
    public endCutValue: number;
    public shortAudioFile: Blob;
    public shortAudioFileSrc: string;
    private reader = new FileReader();

    public audioBuffers: Array<Float32Array>;
    public audioShape: number[];
    public waveformCoordinates: number[][];

    public activePlayerKey = '';

    public loading = false;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private snackBar: MatSnackBar,
        private quizConstructorService: QuizConstructorService
    ) {
        super();
        /*if (this.router.getCurrentNavigation().extras?.state?.quizQuestionInfo) {
            const quizQuestionInfo = JSON.parse(this.router.getCurrentNavigation().extras.state.quizQuestionInfo) as QuizQuestionInfo;
            this.selectedQuestionType = quizQuestionInfo.type;
            this.selectedAnswerType = quizQuestionInfo.answerType;
            this.textQuestion = quizQuestionInfo.value;
            this.textAnswers = quizQuestionInfo.answers;
            this.time = quizQuestionInfo.timeout;
            this.points = quizQuestionInfo.points;
        } else {
            console.log('It\'s a new question');
        }*/
    }

    // TODO tmp
    onFileChange(event) {
        const reader = new FileReader();
        console.log('FILE CHANGE');

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.store.dispatch(new quizConstructorActions.SetQuestionFileBlob(file as Blob));
            this.file = file;

            this.sourceAudioFile = file;
            // Convert blob into ArrayBuffer
            new Response(file).arrayBuffer().then(arrayBuffer => {
                const audioContext = new AudioContext();
                // Convert ArrayBuffer into AudioBuffer
                audioContext.decodeAudioData(arrayBuffer).then(audioBuffer => {
                    this.sourceAudioBuffer = audioBuffer;
                    this.audioLength = this.sourceAudioBuffer.duration;
                    this.startCutValue = 0;
                    this.endCutValue = this.audioLength;
                    this.processing = false;

                    this.audioBuffers = new Array<Float32Array>(this.sourceAudioBuffer.numberOfChannels);
                    for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
                        this.audioBuffers[i] = this.sourceAudioBuffer.getChannelData(i);
                    }

                    this.audioShape = convertAudioBufferToShape(audioBuffer.getChannelData(0), true);
                    const yOffset = 1;
                    this.waveformCoordinates = this.audioShape.map((y, index) => {
                        // Shift the y value 50% so that a 0 position is in the middle.
                        return [(100 / (this.audioShape.length - 1)) * index, y + yOffset];
                    });
                });
            });
        }
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(selectedQuestionSelector)
        ).subscribe(selectedQuestion => {
            this.selectedQuestion = selectedQuestion;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionTemplateSelector)
        ).subscribe(questionTemplate => {
            this.questionTemplate = questionTemplate;
        });
    }

    changeSelectedQuestionType(questionType: QuestionType) {
        if (!this.premium && questionType !== QuestionType.TEXT) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.store.dispatch(new quizConstructorActions.SetQuestionType(questionType));
        }
    }

    changeSelectedAnswerType(answerType: AnswerType) {
        if (!this.premium && answerType === AnswerType.CUSTOM) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            console.log(answerType);
            this.store.dispatch(new quizConstructorActions.SetAnswerType(answerType));
        }
    }

    /*changeSelectedAnswerType(answerType: AnswerType) {
        if (answerType === AnswerType.CUSTOM) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.selectedAnswerType = answerType;
        }
    }*/

    saveQuestion() {
        // TODO check for correct input
        this.loading = true;

        // TODO check why selectedQuestion.type isn't from protobuf and after that make one isMedia check, only by protobuf
        if (this.questionFileChanged && this.isMediaQuestion()) {
            this.questionFileNeedUpload = true;
            this.store.dispatch(new quizConstructorActions.UploadQuestionMedia());
        }

        if (this.answerDescriptionFileChanged && this.isMediaAnswerDescription()) {
            this.answerDescriptionFileNeedUpload = true;
            this.store.dispatch(new quizConstructorActions.UploadAnswerDescriptionMedia());
        }

        if (!this.questionFileNeedUpload && !this.answerDescriptionFileNeedUpload || this.questionTemplate) {
            this.uploadQuestion();
        } else {
            const filesUploadingStatusSubscription = this.store.pipe(
                takeUntil(this.unsubscribeSubject),
                select(selectedQuizFilesUploadingStatusSelector),
                distinctUntilChanged((prev, curr) =>
                    prev.selectedQuestionFileUploadStatus === curr.selectedQuestionFileUploadStatus
                    && prev.selectedQuestionAnswerDescriptionFileUploadStatus === curr.selectedQuestionAnswerDescriptionFileUploadStatus
                ),
                delay(0)
            ).subscribe(uploadingFileStatus => {
                if (
                    (!this.questionFileNeedUpload
                        || (this.questionFileNeedUpload && uploadingFileStatus.selectedQuestionFileUploadStatus))
                    && (!this.answerDescriptionFileNeedUpload
                        || (this.answerDescriptionFileNeedUpload && uploadingFileStatus.selectedQuestionAnswerDescriptionFileUploadStatus))
                ) {
                    this.questionFileNeedUpload = false;
                    this.answerDescriptionFileNeedUpload = false;
                    filesUploadingStatusSubscription.unsubscribe();
                    this.uploadQuestion();
                }
            });
        }
    }

    private uploadQuestion() {
        if (this.questionTemplate) {
            this.store.dispatch(new quizConstructorActions.AddQuestionTemplate());
        } else {
            this.selectedQuestion.id
                ? this.store.dispatch(new quizConstructorActions.UpdateQuestion())
                : this.store.dispatch(new quizConstructorActions.AddQuestion());
        }
    }

    private isMediaQuestion() {
        return this.selectedQuestion.type === QuestionType.IMAGE
            || this.selectedQuestion.type === QuestionType.AUDIO
            || this.selectedQuestion.type === QuestionType.VIDEO;
    }

    private isMediaAnswerDescription() {
        return this.selectedQuestion.answerDescriptionType === QuestionType.IMAGE
        || this.selectedQuestion.answerDescriptionType === QuestionType.AUDIO
        || this.selectedQuestion.answerDescriptionType === QuestionType.VIDEO;
    }

    fileChangedHandler(changed: boolean) {
        this.questionFileChanged = changed;
    }

    answerDescriptionFileChangedHandler(changed: boolean) {
        this.answerDescriptionFileChanged = changed;
    }
}
