import { Component, OnInit } from '@angular/core';
import { RoomManagerService } from '../room-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { PlayerInfo } from '../../../../proto/generated/room_pb';
import { environment } from '../../../environments/environment';
import { UserInfo } from '../../ai-partygames/ai-quiz/ai-quiz.component';
import { HttpClient } from '@angular/common/http';

export interface QuizAdditionalRoomInfo {
    quizName: string;
    quizDescription?: string;
    questionsCount: number;
    playableHostId: string;
}

export interface QrData {
    id: string;
    host: string;
    playableHostId: string;
}

@Component({
    selector: 'app-room-manager',
    templateUrl: './room-manager.component.html',
    styleUrls: ['./room-manager.component.scss'],
    providers: [RoomManagerService]
})
export class RoomManagerComponent implements OnInit {

    public isHost = false;
    public action = '';
    public roomId = '';
    public players = new Array<PlayerInfo>();
    public quizType: 'AI_TEXT' | 'SONG' | 'EMPTY' = 'EMPTY';
    public playableHostId = '';
    public additionalRoomInfo: QuizAdditionalRoomInfo;

    constructor(
        private httpClient: HttpClient,
        private roomManagerService: RoomManagerService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.quizType = localStorage.getItem('quizType') as 'AI_TEXT' | 'SONG' | 'EMPTY';
        this.roomManagerService.waitRoomMessageResponseSubject.subscribe(roomState => {
            console.log('Got new room state');
            console.log('===>>> WAIT ROOM RESPONSE: ', roomState.getAdditionalroominfo());
            if (roomState.getStarted()) {
                this.router.navigate(['quiz-runner']);
            } else {
                this.players = new Array(...roomState.getConnectedplayersList());
                this.additionalRoomInfo = JSON.parse(roomState.getAdditionalroominfo());
            }
        });
        this.roomManagerService.roomStartGameMessageResponseSubject
            .pipe(first())
            .subscribe(roomStartGameMessageResponse => {
                if (roomStartGameMessageResponse.getResult()) {
                    this.router.navigate(['quiz-runner']);
                }
            });
        this.httpClient.post<UserInfo>(
            environment.URLPrefix + '/user/info',
            {}
        ).subscribe(userInfo => {
            localStorage.setItem('userId', userInfo.id);
            this.activatedRoute.queryParams.subscribe(params => {
                if (params.action === 'run') {
                    this.isHost = true;
                    localStorage.setItem('isHost', 'true');
                    if (localStorage.getItem('roomId')) {
                        this.roomId = localStorage.getItem('roomId');
                        this.playableHostId = localStorage.getItem('playableHostId');
                        this.roomManagerService.roomStatusRequest(this.roomId);
                    } else {
                        // Subscribe to get response message once on create
                        this.roomManagerService.createRoomMessageResponseSubject
                            .pipe(first())
                            .subscribe(createRoomMessageResponse => {
                                const roomId = createRoomMessageResponse.getRoomid().toString();
                                // If host want to play from another device
                                // if (this.quizType !== 'EMPTY') {
                                this.additionalRoomInfo = JSON.parse(createRoomMessageResponse.getAdditionalroominfo());
                                console.log('CREATE ROOM RESPONSE', this.additionalRoomInfo);
                                this.playableHostId = this.additionalRoomInfo.playableHostId;
                                localStorage.setItem('playableHostId', this.playableHostId);
                                // }

                                localStorage.setItem('roomId', roomId);
                                this.roomId = roomId;
                            });
                        const quizId: string = localStorage.getItem('quizId');
                        console.log(quizId);
                        // console.log(`Run game ${params.game} with id ${quizId}`);
                        if (quizId === 'deadbeef-dead-beef-dead-beeeeeeeeeef' && this.quizType === 'AI_TEXT') {
                            this.roomManagerService.createRoom(
                                quizId,
                                'AI_TEXT',
                                localStorage.getItem('quizTopic'),
                                localStorage.getItem('quizDifficulty') as 'EASY' | 'MEDIUM' | 'HARD',
                                localStorage.getItem('quizLanguage'),
                                +localStorage.getItem('questionsCount')
                            );
                        } else {
                            this.roomManagerService.createRoom(quizId, 'EMPTY', 'EMPTY');
                        }
                    }
                } else if (params.data) {
                    const qrData: QrData = JSON.parse(atob(params.data));
                    console.log(qrData);
                    console.log(qrData);
                    if (localStorage.getItem('userId') === qrData.host) {
                        localStorage.setItem('playableHostId', qrData.playableHostId);
                    } else {
                        localStorage.removeItem('playableHostId');
                    }
                    localStorage.removeItem('isHost');
                    this.roomId = qrData.id || localStorage.getItem('roomId');
                    if (localStorage.getItem('roomId') && localStorage.getItem('roomId') === this.roomId) {
                        this.roomManagerService.roomStatusRequest(this.roomId);
                    } else {
                        // Subscribe to get response message once on join
                        this.roomManagerService.joinRoomMessageResponseSubject
                            .pipe(first())
                            .subscribe(joinRoomMessageResponse => {
                                const roomId = joinRoomMessageResponse.getRoomid().toString();
                                console.log('CREATE ROOM JOIN', roomId);
                                localStorage.setItem('roomId', roomId);
                                this.roomId = roomId;
                                this.roomManagerService.roomStatusRequest(roomId);
                            });
                        this.roomManagerService.joinRoom(this.roomId);
                    }
                }
            });
        });
    }

    startButtonHandler() {
        this.roomManagerService.startRequest(this.roomId);
    }

    getQrData() {
        const json: QrData = {
            id: this.roomId,
            host: localStorage.getItem('userId'),
            playableHostId: this.playableHostId
        };
        return btoa(JSON.stringify(json));
        // return Buffer.from(JSON.stringify(json), 'base64');
    }
}
