<div *ngIf="numberOfQuestions && questionNumber && !mini"
     class="quiz-progress">
    <div class="number">
        {{questionNumber}} / {{numberOfQuestions}}
    </div>
    <svg *ngFor="let item of arrayByNumber(16 * questionNumber / numberOfQuestions - 1)"
         xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        <circle cx="5.5" cy="5.5" r="5.5" fill="black"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <circle cx="10.5" cy="10.5" r="10.5" fill="black"/>
    </svg>
    <svg *ngFor="let item of arrayByNumber(16 - 16 * questionNumber / numberOfQuestions)"
         xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        <circle cx="5.5" cy="5.5" r="5.5" fill="black" fill-opacity="0.1"/>
    </svg>
</div>

<div *ngIf="numberOfQuestions && questionNumber && mini"
     class="quiz-progress-mini">
    <svg *ngFor="let item of arrayByNumber(16 * questionNumber / numberOfQuestions - 1)"
         xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="4" fill="black"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
        <circle cx="7.5" cy="7.5" r="7.5" fill="black"/>
    </svg>
    <svg *ngFor="let item of arrayByNumber(16 - 16 * questionNumber / numberOfQuestions)"
         xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="4" fill="black" fill-opacity="0.1"/>
    </svg>
</div>
