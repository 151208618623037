<mat-form-field class="search-form-field" appearance="fill">
    <mat-label>{{ "FRIENDS.SEARCH" | translate }}</mat-label>
    <input matInput
           type="text"
           [(ngModel)]="searchString"
           (input)="searchFriends()">
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<div *ngIf="searchString.length !== 0">
    <mat-card *ngFor="let friend of searchList">
        <mat-card-header>
            <img mat-card-avatar src="{{ friend.avatarUrl }}" alt="avatar">
            <mat-card-title>{{ friend.nickname }}</mat-card-title>
        </mat-card-header>
        <mat-card-actions>
            <button mat-raised-button
                    (click)="addFriend(friend.id)">
                {{ "FRIENDS.ADD" | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
</div>
<div *ngIf="searchString.length === 0">
    <mat-card *ngFor="let friend of incomingList">
        <mat-card-header>
            <img mat-card-avatar src="{{ friend.avatarUrl }}" alt="avatar">
            <mat-card-title>{{ friend.nickname }}</mat-card-title>
            <mat-card-subtitle>{{ "FRIENDS.INCOMING_REQUEST" | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
            <button mat-raised-button
                    (click)="addFriend(friend.id)">
                {{ "FRIENDS.APPLY" | translate }}
            </button>
            <button mat-raised-button
                    (click)="removeFriend(friend.id)">
                {{ "FRIENDS.REJECT" | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
    <mat-card *ngFor="let friend of outgoingList">
        <mat-card-header>
            <img mat-card-avatar src="{{ friend.avatarUrl }}" alt="avatar">
            <mat-card-title>{{ friend.nickname }}</mat-card-title>
            <mat-card-subtitle>{{ "FRIENDS.WAITING_FOR_APPLY" | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
            <button mat-raised-button
                    (click)="removeFriend(friend.id)">
                {{ "FRIENDS.CANCEL" | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
    <mat-card *ngFor="let friend of friendList">
        <mat-card-header>
            <img mat-card-avatar src="{{ friend.avatarUrl }}" alt="avatar">
            <mat-card-title>{{ friend.nickname }}</mat-card-title>
            <mat-card-subtitle>{{ "FRIENDS.FRIENDS" | translate }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-actions>
            <button mat-raised-button
                    (click)="removeFriend(friend.id)">
                {{ "FRIENDS.REMOVE" | translate }}
            </button>
        </mat-card-actions>
    </mat-card>
</div>
