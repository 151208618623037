import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-newsletter-unsubscribe',
    templateUrl: './newsletter-unsubscribe.component.html',
    styleUrls: ['./newsletter-unsubscribe.component.scss']
})
export class NewsletterUnsubscribeComponent implements OnInit {

    public subscriptionId: string;
    public form: FormGroup;
    public reasons: string[] = [
        'REASON_FORGET',
        'REASON_FREQUENT',
        'REASON_MANY',
        'REASON_FEW',
        'REASON_NOT_SUBSCRIBED',
        'REASON_MANY_MAILS',
        'REASON_OTHER'
    ];
    public selectedReason = '';
    public successfullyUnsubscribed = false;
    public unsubscribeFailure = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        public translate: TranslateService,
        private gtmService: GoogleTagManagerService
    ) {
        if (translate.getBrowserLang() === 'ru') {
            translate.setDefaultLang('ru');
        } else {
            translate.setDefaultLang('en');
        }
        this.form = this.formBuilder.group({
            reason: ''
        });
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.subscriptionId = params.subscriptionId;
            console.log(this.subscriptionId);
        });
    }

    onReasonChange(reason: string) {
        this.selectedReason = reason;
        console.log(reason);
    }

    onSubmit(data) {
        this.successfullyUnsubscribed = false;
        this.unsubscribeFailure = false;
        if (this.selectedReason === '') {
            return;
        }

        const params = new HttpParams()
            .set('subscriptionId', this.subscriptionId)
            .set('reason', this.selectedReason);
        this.httpClient.post<any>(
            '/wsite/unsubscribe',
            {},
            {
                withCredentials: true,
                params
            }
        ).subscribe(
            (answer: any) => {
                if (answer.success) {
                    this.successfullyUnsubscribed = true;

                    const gtmTag = {
                        event: 'button-click',
                        data: 'send-unsubscribe-form-button-success',
                    };
                    this.gtmService.pushTag(gtmTag);
                } else {
                    this.unsubscribeFailure = true;
                }
            },
            error => {
                this.unsubscribeFailure = true;

                const gtmTag = {
                    event: 'button-click',
                    data: 'send-unsubscribe-form-button-error',
                };
                this.gtmService.pushTag(gtmTag);
            }
        );
    }
}
