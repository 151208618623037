import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizComponent } from './quiz/quiz.component';
import { JoinComponent } from './join/join.component';
import { AnswerConstructorComponent } from './question-generation/answer-constructor/answer-constructor.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';
import { QuestionConstructorComponent } from './question-generation/question-constructor/question-constructor.component';
import { QuestionGenerationComponent } from './question-generation/question-generation/question-generation.component';
import { QuizListComponent } from './quiz-list/quiz-list.component';
import { QuizConstructorService } from './quiz-constructor.service';
import { CreateQuizDialogComponent } from './quiz-list/create-quiz-dialog/create-quiz-dialog.component';
import { QuizEditorComponent } from './quiz-editor/quiz-editor.component';
import { QuizRunnerComponent } from '../quiz-runner/quiz-runner/quiz-runner.component';
import { QuizRunnerModule } from '../quiz-runner/quiz-runner.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StoreModule } from '@ngrx/store';
import { quizConstructorReducer } from './store/quiz-constructor.reducer';
import { EffectsModule } from '@ngrx/effects';
import { QuizConstructorEffects } from './store/quiz-constructor.effects';
import { AdminQuizListComponent } from './quiz-list/admin-quiz-list/admin-quiz-list.component';
import { AudioEditorComponent } from '../audio-editor/audio-editor.component';
import { WaveLineComponent } from '../audio-editor/audio-player/wave-line/wave-line.component';
import { AudioPlayerComponent } from '../audio-editor/audio-player/audio-player/audio-player.component';
import { WaveControlComponent } from '../audio-editor/audio-player/wave-control/wave-control.component';
import { AudioEditorModule } from '../audio-editor/audio-editor.module';
import {
    AnswerDescriptionConstructorComponent
} from './question-generation/answer-description-constructor/answer-description-constructor.component';
import { DragAndDropZoneDirective } from '../../libs/directives/drag-and-drop-zone.directive';
import { QuestionsBankComponent } from './questions-bank/questions-bank.component';

@NgModule({
    declarations: [
        QuizComponent,
        JoinComponent,
        QuestionConstructorComponent,
        AnswerConstructorComponent,
        AnswerDescriptionConstructorComponent,
        QuestionGenerationComponent,
        QuizListComponent,
        CreateQuizDialogComponent,
        QuizEditorComponent,
        AdminQuizListComponent,
        DragAndDropZoneDirective,
        QuestionsBankComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        StoreModule.forFeature('quizConstructorFeature', {
            quizConstructor: quizConstructorReducer
        }),
        EffectsModule.forFeature([
            QuizConstructorEffects
        ]),
        // QuizRunnerModule
        AudioEditorModule
    ],
    providers: [
        QuizConstructorService
    ]
})
export class QuizConstructorModule {}
