<div mat-dialog-content
     class="dialog-content-wrapper">
    <div class="header">{{ 'QUIZ.INFO' | translate }}</div>
    <mat-form-field appearance="fill"
                    class="dialog-input">
        <mat-label>{{ 'COMMON.TITLE' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="fill"
                    class="dialog-input">
        <mat-label>{{ 'COMMON.DESCRIPTION' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.description">
    </mat-form-field>
    <mat-slide-toggle [(ngModel)]="data.public">{{ 'QUIZ.PUBLIC' | translate }}</mat-slide-toggle>
</div>
<div mat-dialog-actions
     class="dialog-actions">
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="data"
            cdkFocusInitial>
        {{ 'COMMON.SAVE' | translate }}
    </button>
    <button mat-raised-button
            (click)="onNoClick()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>
</div>
