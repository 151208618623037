import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { PreviousRouteService } from '../../../app/previous-route.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateNicknameDialogComponent } from '../create-nickname-dialog/create-nickname-dialog.component';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent {

    @Input()
    public allowAnonymous = false;

    public nickname = '';
    private NICKNAME_ERRORS = ['NICKNAME_DOES_NOT_EXIST', 'NICKNAME_EXISTS', 'NICKNAME_EMPTY', 'NICKNAME_TOO_LONG'];

    constructor(
        private router: Router,
        public authService: AuthService,
        private location: Location,
        private previousRouteService: PreviousRouteService,
        private matDialog: MatDialog
    ) {
        if (!previousRouteService.getPreviousUrl() || previousRouteService.getPreviousUrl().includes('room-manager')) {
            this.allowAnonymous = true;
        }

        authService.authSubject.subscribe(
            success => {
                console.log('SUCCESS');
                if (success) {
                    // this.router.navigate(['quiz-list']);
                    console.log('LOCATION STATE: ', this.location.getState());
                    this.location.back();
                }
            }
        );

        authService.authErrorSubject.subscribe(
            error => {
                console.log(error);
                if (this.NICKNAME_ERRORS.includes(error)) {
                    this.matDialog.open(CreateNicknameDialogComponent, {
                        width: '400px',
                        data: this.nickname
                    }).afterClosed().subscribe(result => {
                        if (result) {
                            this.authService.finishRegistration(result);
                        }
                    });
                }
            }
        );
    }

    public signIn() {
        this.authService.authWithGoogle();
    }
}
