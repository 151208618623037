<div mat-dialog-content
     class="dialog-content-wrapper">
    <div class="header">{{ 'QUIZ.TOPIC' | translate }}</div>
    <div *ngIf="this.topics.length === 0"
         class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
    <mat-form-field *ngIf="this.topics.length > 0">
        <mat-label>Select topic</mat-label>
        <mat-select [value]="this.topics[0]"
                    (valueChange)="changeTopicHandler($event)">
            <mat-option *ngFor="let topic of topics"
                        [value]="topic">
                {{topic}}
            </mat-option>
            <mat-option [value]="'custom'">Custom</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill"
                    class="dialog-input">
        <mat-label>{{ 'USER.NICKNAME' | translate }}</mat-label>
        <input matInput
               minlength="1"
               maxlength="64"
               [value]="customTopic"
               [disabled]="selectedTopic !== 'custom'"
               (input)="changeCustomTopicHandler($event.target)">
    </mat-form-field>
</div>
<div mat-dialog-actions
     class="dialog-actions">
    <button mat-raised-button
            color="primary"
            [disabled]="data === ''"
            [mat-dialog-close]="data"
            cdkFocusInitial>
        {{ 'COMMON.CREATE' | translate }}
    </button>
    <button mat-raised-button
            (click)="onNoClick()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>
</div>
