import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuizInfo, RoomInfo } from '../../quiz-constructor/quiz-constructor.service';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/app.state';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { quizListSelector, userRoomsSelector } from '../../quiz-constructor/store/quiz-constructor.selectors';
import * as quizConstructorActions from '../../quiz-constructor/store/quiz-constructor.actions';
import { TypedRoomInfo } from '../../quiz-constructor/quiz-list/quiz-list.component';

@Component({
  selector: 'app-my-quizzes',
  templateUrl: './my-quizzes.component.html',
  styleUrls: ['./my-quizzes.component.scss']
})
export class MyQuizzesComponent implements OnInit, OnDestroy {

  public nickname: string;

  public quizList = new Array<QuizInfo>();
  public userRooms: Array<TypedRoomInfo> = new Array<TypedRoomInfo>();

  private unsubscribeSubject: Subject<void> = new Subject();

  constructor(
      private store: Store<AppState>,
      private router: Router,
      private authService: AuthService,
      public domSanitizer: DomSanitizer
  ) {
  }

  public ngOnInit() {

    this.store.pipe(
        takeUntil(this.unsubscribeSubject),
        select(quizListSelector)
    ).subscribe(quizList => {
      this.quizList = quizList;
    });

    this.store.pipe(
        takeUntil(this.unsubscribeSubject),
        select(userRoomsSelector)
    ).subscribe(userRooms => {
      this.userRooms = [
        ...userRooms.createdRooms.map(room => ({ ...room, roomStatus: 'created' } as TypedRoomInfo)),
        ...userRooms.startedRooms.map(room => ({ ...room, roomStatus: 'started' } as TypedRoomInfo)),
        ...userRooms.finishedRooms.map(room => ({ ...room, roomStatus: 'finished' } as TypedRoomInfo))
      ].sort((firstRoom, secondRoom) => {
        return firstRoom.createTime > secondRoom.createTime ? -1 : 1;
      });
      // this.userRooms = userRooms;
    });
    this.store.dispatch(new quizConstructorActions.GetAllQuizzes());
    this.store.dispatch(new quizConstructorActions.GetUserRooms());
  }

  private setRoomInfo(roomInfo: RoomInfo) {
    console.log(roomInfo.roomId);
    localStorage.setItem('roomId', roomInfo.roomId.toString());
    localStorage.setItem('isHost', roomInfo.host.toString());
  }

  public open(roomInfo: TypedRoomInfo) {
    this.setRoomInfo(roomInfo);
    if (roomInfo.roomStatus === 'created') {
      localStorage.setItem('game', 'quiz');
      this.router.navigate(
          ['room-manager'],
          {
            queryParams: {
              action: roomInfo.host ? 'run' : 'join'
            }
          }
      );
    }
    if (roomInfo.roomStatus === 'started') {
      this.setRoomInfo(roomInfo);
      this.router.navigate(['quiz-runner']);
    }
    // this.router.navigate(['room-manager']);
  }

  private localSignOut() {
    localStorage.removeItem('sid');
    this.authService.authSubject.next(false);
    this.router.navigate(['auth']);
  }

  public signOut() {
    this.authService.signOut().subscribe((ans) => {
      console.log('Sign out result', ans);
      if (ans.result) {
        this.localSignOut();
      }
    });
  }

  public link() {
    this.authService.linkWithGoogle(this.nickname);
  }

  public ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
