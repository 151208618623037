import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public isDesktopDevice = true;

    constructor(
        private deviceService: DeviceDetectorService
    ) {
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    ngOnInit(): void {
    }

}
