import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuizRunnerState } from './quiz-runner.reducer';

export const quizRunnerFeatureSelector = createFeatureSelector<{ quizRunner: QuizRunnerState }>('quizRunnerFeature');

export const quizReadySelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.quizReady);
export const quizNameSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.quizName);
export const quizTotalQuestionsSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.totalQuestions);
export const questionSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.quizQuestion);
export const questionStatusSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.questionStatus);
export const scoreboardListSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.scoreboardList);
export const playersListSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.playersList);
export const playerNumberSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.playerNumber);
export const remainingTimeSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.remainingTime);
export const playerAnswerSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.playerAnswer);
export const questionFileSrcSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.questionFileSrc);
export const answeredPlayersListSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.answeredPlayersList);
export const gameFinishedSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.gameFinished);
export const errorCodeSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) => quizRunner.errorCode);
export const currentPlayerInfo
    = createSelector(quizRunnerFeatureSelector, ({quizRunner}) => quizRunner.playersList[quizRunner.playerNumber]);

export const firstAnsweredPlayersSelector = createSelector(quizRunnerFeatureSelector, ({ quizRunner }) =>
    quizRunner.playersList.find(player => player.getPlayernumber() === quizRunner.answeredPlayersList[0])
);
