<mat-card *ngFor="let quiz of quizList$ | async"
          class="quiz-card">
    <mat-card-header>
        <mat-card-title>{{ quiz.name }}</mat-card-title>
    </mat-card-header>
    <mat-card-subtitle>{{ quiz.id }}</mat-card-subtitle>
    <mat-card-content>{{ quiz.description }}</mat-card-content>
    <mat-card-actions>
        <button mat-raised-button
                (click)="openQuiz(quiz)">
            {{ "QUIZ.EDIT" | translate }}
        </button>
        <button mat-raised-button
                (click)="submitReview(quiz, QuizStatus.READY)"
                color="primary">
            {{ "QUIZ.APPROVE" | translate }}
        </button>
        <mat-form-field class="search-form-field" appearance="fill">
            <mat-label>{{ "QUIZ.REJECTION_REASON" | translate }}</mat-label>
            <input matInput
                   type="text"
                   [(ngModel)]="rejectionReason">
        </mat-form-field>
        <button mat-raised-button
                (click)="submitReview(quiz, QuizStatus.REJECTED)"
                color="accent">
            {{ "QUIZ.REJECT" | translate }}
        </button>
    </mat-card-actions>
</mat-card>
