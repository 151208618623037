import { Component, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

declare var pay: any;

export interface UserInfo {
    id: string;
    email: string;
    phone: string;
    nickname: string;
    name: string;
    avatarUrl: string;
    premium: boolean;
    shouldRefreshToken: boolean;
}

export enum QuizDifficulty {
    EASY = 'EASY',
    MEDIUM = 'MEDIUM',
    HARD = 'HARD'
}

export interface QuizTopic {
    name: string;
    difficulty: QuizDifficulty;
}

@Component({
    selector: 'app-ai-quiz',
    templateUrl: './ai-quiz.component.html',
    styleUrls: ['./ai-quiz.component.scss']
})
export class AiQuizComponent {

    public language = 'RUSSIAN';
    public quizTopic: string;
    public topics = new Array<QuizTopic>();
    // public topics = ['Science', 'Math', 'Songs'];
    public selectedTopic = '';
    public customTopic = '';
    public quizDifficulty: QuizDifficulty = QuizDifficulty.MEDIUM;
    public questionsCount = 10;

    public userInfo: UserInfo;
    public receipt = '';

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private renderer: Renderer2
    ) {
        // this.topics = ['Общая'];
        // this.quizTopic = 'Общая';
        this.httpClient.get<{ topics: Array<QuizTopic> }>(
            environment.URLPrefix + '/quiz/topics',
            {}
        ).subscribe(result => {
            if (result.topics) {
                this.topics = result.topics;
                this.quizTopic = result.topics[0].name;
            }
        });

        this.httpClient.post<UserInfo>(
            environment.URLPrefix + '/user/info',
            {}
        ).subscribe(userInfo => {
            this.userInfo = userInfo;
            localStorage.setItem('userId', userInfo.id);
        });

        this.receipt = JSON.stringify({
            EmailCompany: 'info@ai-partygames.com',
            Taxation: 'usn_income',
            FfdVersion: '1.2',
            Items: [
                {
                    Name: 'Премиум подписка',
                    Price: 100 + '00',
                    Quantity: 1.00,
                    Amount: 100 + '00',
                    PaymentMethod: 'full_prepayment',
                    PaymentObject: 'service',
                    Tax: 'none',
                    MeasurementUnit: 'месяц'
                }
            ]
        });
    }

    public changeTopicHandler(selectedTopic: string) {
        this.selectedTopic = selectedTopic;
        if (selectedTopic !== 'custom') {
            this.quizTopic = selectedTopic;
        } else {
            this.quizTopic = this.customTopic;
        }
    }

    public changeQuizDifficultyHandler(quizDifficulty: QuizDifficulty) {
        this.quizDifficulty = quizDifficulty;
    }

    public changeLanguageHandler(selectedLanguage: string) {
        this.language = selectedLanguage;
    }

    public changeQuestionsCountHandler(questionsCount: number) {
        this.questionsCount = questionsCount;
    }

    public getMediumTopics() {
        return this.topics.filter(topic => topic.difficulty === QuizDifficulty.MEDIUM);
    }

    public changeCustomTopicHandler(eventTarget: EventTarget) {
        this.customTopic = (eventTarget as HTMLInputElement).value;
        this.quizTopic = this.customTopic;
    }

    public createGame() {
        localStorage.setItem('game', 'quiz');
        localStorage.setItem('quizId', 'deadbeef-dead-beef-dead-beeeeeeeeeef');
        localStorage.setItem('quizType', 'AI_TEXT'); // AI_TEXT | SONG | EMPTY
        localStorage.setItem('quizTopic', this.quizTopic);
        localStorage.setItem('quizDifficulty', this.quizDifficulty);
        localStorage.setItem('quizLanguage', this.language);
        localStorage.setItem('questionsCount', this.questionsCount.toString());
        localStorage.removeItem('roomId');
        this.router.navigate(
            ['/room-manager'],
            {queryParams: {action: 'run'}}
        );
    }

    sendForm() {

        this.httpClient.post<{ orderId: string }>(
            environment.URLPrefix + '/payment/create',
            {
                productId: 'MONTHLY'
            }
        ).subscribe(response => {
            console.log(response.orderId);

            const recaptchaContainer = this.renderer.createElement('form');
            this.renderer.setProperty(recaptchaContainer, 'class', 'payform-tinkoff');
            this.renderer.setProperty(recaptchaContainer, 'name', 'payform-tinkoff');

            const inputTerminalKey = this.renderer.createElement('input');
            this.renderer.setProperty(inputTerminalKey, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputTerminalKey, 'type', 'hidden');
            this.renderer.setProperty(inputTerminalKey, 'name', 'terminalkey');
            this.renderer.setProperty(inputTerminalKey, 'value', '1717520094789DEMO');
            this.renderer.appendChild(recaptchaContainer, inputTerminalKey);

            const inputFrame = this.renderer.createElement('input');
            this.renderer.setProperty(inputFrame, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputFrame, 'type', 'hidden');
            this.renderer.setProperty(inputFrame, 'name', 'frame');
            this.renderer.setProperty(inputFrame, 'value', 'false');
            this.renderer.appendChild(recaptchaContainer, inputFrame);

            const inputLanguage = this.renderer.createElement('input');
            this.renderer.setProperty(inputLanguage, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputLanguage, 'type', 'hidden');
            this.renderer.setProperty(inputLanguage, 'name', 'language');
            this.renderer.setProperty(inputLanguage, 'value', 'ru');
            this.renderer.appendChild(recaptchaContainer, inputLanguage);

            const inputReceipt = this.renderer.createElement('input');
            this.renderer.setProperty(inputReceipt, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputReceipt, 'type', 'hidden');
            this.renderer.setProperty(inputReceipt, 'name', 'receipt');
            this.renderer.setProperty(inputReceipt, 'value', this.receipt);
            this.renderer.appendChild(recaptchaContainer, inputReceipt);

            const inputAmount = this.renderer.createElement('input');
            this.renderer.setProperty(inputAmount, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputAmount, 'type', 'hidden');
            this.renderer.setProperty(inputAmount, 'name', 'amount');
            this.renderer.setProperty(inputAmount, 'value', '100');
            this.renderer.appendChild(recaptchaContainer, inputAmount);

            const inputOrder = this.renderer.createElement('input');
            this.renderer.setProperty(inputOrder, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputOrder, 'type', 'hidden');
            this.renderer.setProperty(inputOrder, 'name', 'order');
            this.renderer.setProperty(inputOrder, 'value', response.orderId);
            this.renderer.appendChild(recaptchaContainer, inputOrder);

            const inputMail = this.renderer.createElement('input');
            this.renderer.setProperty(inputMail, 'class', 'payform-tinkoff-row');
            this.renderer.setProperty(inputMail, 'type', 'email');
            this.renderer.setProperty(inputMail, 'name', 'email');
            this.renderer.setProperty(inputMail, 'value', this.userInfo.email);
            this.renderer.appendChild(recaptchaContainer, inputMail);

            console.log(recaptchaContainer);
            pay(recaptchaContainer);
        });
    }
}
