import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BetaAccessShareComponent } from '../beta-access-share/beta-access-share.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-beta-access-form',
    templateUrl: './beta-access-form.component.html',
    styleUrls: ['./beta-access-form.component.scss']
})
export class BetaAccessFormComponent {

    form: FormGroup;
    system = '';
    email = '';

    constructor(
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private translate: TranslateService,
        public dialog: MatDialog,
        private gtmService: GoogleTagManagerService
    ) {
        this.form = this.formBuilder.group({
            system: '',
            email: ''
        });
    }

    setSystem(system: string) {
        this.system = system;
    }

    // psql -U w_site -d w_site_db -c "SELECT * FROM backend.subscriber"
    // psql -U gambo_local -d gambo
    onSubmit(data) {
        if (this.form.invalid || !this.system) {
            this.form.markAllAsTouched();
            return;
        }

        // this.items = this.cartService.clearCart();
        const apiRequest = {
            email: data.email,
            o_sys: this.system,
            lang: localStorage.getItem('selectedLang') ?? this.translate.getDefaultLang()
        };

        const options = {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        };
        this.httpClient.post<any>(
            '/wsite/subscribe',
            apiRequest,
            options
        ).subscribe(
            (answer: any) => {
                if (answer.success) {
                    this.system = '';
                    this.form.reset();
                    Object.keys(this.form.controls).forEach(key => this.form.controls[key].setErrors(null));

                    this.openShareWindow();

                    const gtmTag = {
                        event: 'button-click',
                        data: 'send-form-button-success',
                    };
                    this.gtmService.pushTag(gtmTag);
                }
            },
            error => {
                Object.keys(this.form.controls).forEach(key => this.form.controls[key].setErrors(null));

                this.openErrorWindow();

                const gtmTag = {
                    event: 'button-click',
                    data: 'send-form-button-error',
                };
                this.gtmService.pushTag(gtmTag);
            }
        );
    }

    openShareWindow() {
        this.dialog.open(BetaAccessShareComponent, {
            width: '550px',
            maxHeight: '80%'
        });
    }

    openErrorWindow() {
        this.dialog.open(BetaAccessFormErrorDialogComponent);
    }

    onEmailChange() {
        this.form.setValue({
            system: this.form.get('system').value,
            email: this.form.get('email').value.trim()
        });
    }
}

@Component({
    selector: 'app-beta-access-form-error-dialog',
    template: `
        <h1 mat-dialog-title>{{ "BETA_ACCESS_FORM_ERROR_DIALOG.TITLE" | translate }}</h1>
        <div mat-dialog-content>{{ "BETA_ACCESS_FORM_ERROR_DIALOG.MESSAGE" | translate }} <a href="mailto:info@gambo.app" class="mail">info@gambo.app</a></div>
        <div mat-dialog-actions>
            <button mat-raised-button mat-dialog-close>{{ "BETA_ACCESS_FORM_ERROR_DIALOG.BUTTON" | translate }}</button>
        </div>
    `
})
export class BetaAccessFormErrorDialogComponent {}
