import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    ViewChild
} from '@angular/core';
import * as THREE from 'three';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as Rellax from 'rellax';
import * as Parallax from 'parallax-js';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Use gltf-pipeline to encode glb (gltf) with draco
 * gltf-pipeline -i Island.glb -o IslandDraco.glb -d
 */

@Component({
    selector: 'app-quiz-main-landing',
    templateUrl: './quiz-main-landing.component.html',
    styleUrls: ['./quiz-main-landing.component.scss']
})
export class QuizMainLandingComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    title = 'Gambo';
    lang = 'en';

    @ViewChild('canvas', {static: true})
    private canvasRef: ElementRef;

    @ViewChild('slide_1', {static: true})
    private containerRef1: ElementRef;

    @ViewChild('slide_2', {static: true})
    private containerRef2: ElementRef;

    @ViewChild('slide_3', {static: true})
    private containerRef3: ElementRef;

    @ViewChild('slide_4', {static: true})
    private containerRef4: ElementRef;

    public pageWidth: number;
    public pageHeight: number;

    public sceneDivWidth: number;
    public sceneDivHeight: number;

    public scene = new THREE.Scene();
    public camera = new THREE.PerspectiveCamera(10, 1.33, 1, 500);
    public renderer: THREE.WebGLRenderer;

    public model: GLTF;
    private modelPositionX = 0;
    private modelPositionY = 0;
    private modelPositionZ = -35;
    private modelRotationX = 13;
    private modelScale = 0.3;

    private modelRotationValue = 0.006;

    public selectedSlide = 1;
    public homeOffset = null;
    public aboutOffset = null;
    public benefitsOffset = null;
    public contactOffset = null;

    public isDesktopDevice = true;

    public rellax;

    private onResizeBound;

    constructor(
        private meta: Meta,
        public translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private deviceService: DeviceDetectorService
    ) {

        if (localStorage.getItem('selectedLang')) {
            this.lang = localStorage.getItem('selectedLang');
        } else if (translate.getBrowserLang() === 'ru') {
            this.lang = 'ru';
        }
        translate.setDefaultLang(this.lang);
        this.translate.use(this.lang).subscribe(() => {
            this.meta.addTags([
                // { name: 'description', content: this.translate.instant('META.DESCRIPTION') },
                // { name: 'keywords', content: this.translate.instant('META.KEYS') },
                { property: 'og:title', content: this.translate.instant('META.OG_TITLE') },
                { property: 'og:description', content: this.translate.instant('META.OG_DESCRIPTION') }
            ]);
        });
        /*this.meta.addTags([
            { property: 'og:image', content: 'https://ai-partygames.com/assets/images/Logo.png' },
            { property: 'og:site_name', content: 'Gambo' },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: 'ai-partygames.com' }
        ]);*/

        this.isDesktopDevice = this.deviceService.isDesktop();

        this.pageWidth = window.innerWidth;
        this.pageHeight = window.innerHeight;
    }

    ngAfterViewInit() {
        window.scroll(0, 0);

        this.sceneDivWidth = this.canvasRef.nativeElement.parentElement.offsetWidth;
        this.sceneDivHeight = this.canvasRef.nativeElement.parentElement.offsetHeight;
        this.camera.position.set(0, 0, 0);

        // Load a Renderer
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvasRef.nativeElement,
            antialias: true,
            alpha: true
        });
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        /*this.renderer.setSize(
            window.innerWidth > window.innerHeight ? 400 * window.innerWidth / window.innerHeight : 400,
            window.innerWidth > window.innerHeight ? 400 : 400 * window.innerHeight / window.innerWidth
        );*/
        // (this.containerRef1.nativeElement as HTMLElement).appendChild(this.renderer.domElement);
        // document.body.appendChild(this.renderer.domElement);

        // Load the Orbitcontroller
        // const controls = new THREE.OrbitControls( camera, renderer.domElement );

        // Load Light
        const ambientLight = new THREE.AmbientLight(0xFFFFFF);
        this.scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight('#FFFFFF');
        directionalLight.intensity = 0.5;
        directionalLight.position.set(0, 100, 0).normalize();
        this.scene.add(directionalLight);

        // DRACOLoader.setDecoderPath('path/to/draco-decoder');

        // TODO: THIS CAUSE ERRORS
        /*const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('../assets/gltf/draco/');
        loader.setDRACOLoader(dracoLoader);
        loader.load('../assets/gltf/IslandWithCloudsDraco.glb', gltf => {
            // Vertical, Height, Width
            /!*gltf.scene.scale.set(
                window.innerWidth > window.innerHeight ? 1 : window.innerHeight / window.innerWidth,
                1,
                window.innerWidth > window.innerHeight ? window.innerWidth / window.innerHeight : 1);*!/
            this.model = gltf;
            // Horizontal (right positive), Vertical (up positive), Front (front positive)
            this.model.scene.position.set(this.modelPositionX, this.modelPositionY, this.modelPositionZ);
            // Up down, Rotating around, Left right
            this.model.scene.rotation.set(
                THREE.MathUtils.degToRad(this.modelRotationX),
                THREE.MathUtils.degToRad(30),
                0
            );

            this.scene.add(this.model.scene);
            this.render();
            // this.addMouseHandler(this.canvasRef.nativeElement);
        });*/

        if (!this.onResizeBound) {
            this.onResizeBound = this.onWindowResize.bind(this);
        }
        window.addEventListener('resize', this.onResizeBound, false);
        // this.animate();

        if (this.pageWidth > 991 && this.pageHeight > 860) {
            this.rellax = new Rellax('.rellax');
        }

        if (this.isDesktopDevice) {
            const scene = document.getElementById('background-wrapper');
            const parallaxInstance = new Parallax(scene);
        }
    }

    ngAfterViewChecked() {
        this.homeOffset = this.containerRef1.nativeElement.offsetTop;
        this.aboutOffset = this.containerRef2.nativeElement.offsetTop;
        this.benefitsOffset = this.containerRef3.nativeElement.offsetTop;
        this.contactOffset = this.containerRef4.nativeElement.offsetTop;
        this.checkOffsetTop();
    }

    ngOnDestroy() {
        this.canvasRef.nativeElement.remove();
        window.removeEventListener('resize', this.onResizeBound, false);
        if (this.rellax) {
            this.rellax.destroy();
        }
    }

    @HostListener('window:scroll', ['$event'])
    checkOffsetTop(event?: Event) {
        if (event) {
            setTimeout(() => {
                if (window.pageYOffset >= this.homeOffset && window.pageYOffset < this.aboutOffset) {
                    this.selectedSlide = 1;
                } else if (window.pageYOffset >= this.aboutOffset && window.pageYOffset < this.benefitsOffset) {
                    this.selectedSlide = 2;
                } else if (window.pageYOffset >= this.benefitsOffset && window.pageYOffset < this.contactOffset) {
                    this.selectedSlide = 3;
                } else if (window.pageYOffset >= this.contactOffset) {
                    this.selectedSlide = 4;
                }
            }, 0);
        }
    }

    onWindowResize() {
        this.pageWidth = window.innerWidth;
        this.pageHeight = window.innerHeight;

        this.sceneDivWidth = this.canvasRef.nativeElement.parentElement.offsetWidth;
        this.sceneDivHeight = this.canvasRef.nativeElement.parentElement.offsetHeight;

        this.renderer.setSize(this.sceneDivWidth, this.sceneDivHeight);
        this.camera.aspect = this.sceneDivWidth / this.sceneDivHeight;
        this.camera.updateProjectionMatrix();

        if (this.pageWidth > 991 && this.pageHeight > 860) {
            if (!this.rellax) {
                this.rellax = new Rellax('.rellax');
            }
        } else {
            if (this.rellax) {
                this.rellax.destroy();
                delete this.rellax;
            }
        }

        // this.renderer.setSize(window.innerWidth, window.innerHeight);
        /*this.renderer.setSize(
            window.innerWidth > window.innerHeight ? 400 * window.innerWidth / window.innerHeight : 400,
            window.innerWidth > window.innerHeight ? 400 : 400 * window.innerHeight / window.innerWidth
        );*/
    }

    /*ngAfterViewInit() {
        this.render();
        // this.animate();
        // console.log(this.scene, this.camera, this.renderer);
        // this.renderer.render(this.scene, this.camera);
        // requestAnimationFrame(this.animate);
    }*/

    // animate() {
    //     // this.render();
    //     // console.log('LOG');
    //     this.scene.rotation.y += 0.5;
    //     this.renderer.render(this.scene, this.camera);
    //     this.ngZone.runOutsideAngular(() => {
    //         requestAnimationFrame(this.animate);
    //     });
    // }

    render() {
        setTimeout(() => {
            requestAnimationFrame(this.render.bind(this));

            this.model.scene.rotation.set(
                this.model.scene.rotation.x,
                this.model.scene.rotation.y + this.modelRotationValue,
                0
            );

            this.model.scene.scale.set(this.modelScale, this.modelScale, this.modelScale);

            this.renderer.render(this.scene, this.camera);
        }, 30);
        // this.renderer.render(this.scene, this.camera);
    }

    scrollToElement(element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    changeLang(lang: string) {
        this.lang = lang;
        localStorage.setItem('selectedLang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang).subscribe(() => {
            /*this.meta.updateTag(
                { name: 'description', content: this.translate.instant('META.DESCRIPTION') }
            );
            this.meta.updateTag(
                { name: 'keywords', content: this.translate.instant('META.KEYS') }
            );*/
            this.meta.updateTag(
                { property: 'og:title', content: this.translate.instant('META.OG_TITLE') }
            );
            this.meta.updateTag(
                { property: 'og:description', content: this.translate.instant('META.OG_DESCRIPTION') }
            );
        });
    }

    openInstagram() {
        window.open('https://www.instagram.com/ai-partygames.com/', '_blank');
    }

    openGambo() {
        window.open('https://www.ai-partygames.com', '_self');
    }
}
