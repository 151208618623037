<div class="header-wrapper">
    <div *ngIf="isDesktopDevice"
         aTilt
         class="logo">
        <div style="transform: translateZ(20px)">
            <img src="assets/images/LogoFront.png"
                 alt="LogoFront">
        </div>
    </div>
</div>
