import { Component, OnDestroy, OnInit } from '@angular/core';
import { FriendsService, User, UserList } from './friends.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-friends',
    templateUrl: './friends.component.html',
    styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit, OnDestroy {

    public friendList = Array<User>();
    public incomingList = Array<User>();
    public outgoingList = Array<User>();
    public searchList = Array<User>();
    public searchString = '';

    constructor(
        public translate: TranslateService,
        private friendsService: FriendsService
    ) { }

    ngOnInit() {
        this.friendsService.friendsListSubject.subscribe(friendList => this.friendList = friendList.users);
        this.friendsService.incomingListSubject.subscribe(incomingList => this.incomingList = incomingList.users);
        this.friendsService.outgoingListSubject.subscribe(outgoingList => this.outgoingList = outgoingList.users);
        this.friendsService.searchListSubject.subscribe(searchList => this.searchList = searchList.users);
        this.friendsService.friendsInfoRequest();
    }

    searchFriends() {
        if (this.searchString !== '') {
            this.friendsService.searchFriends(this.searchString);
        } else {
            this.searchList = [];
            this.friendsService.friendsInfoRequest();
        }
    }

    addFriend(id: number) {
        this.friendsService.addFriend(id);
        this.searchList = [];
        this.searchString = '';
    }

    removeFriend(id: number) {
        this.friendsService.removeFriend(id);
        this.searchList = [];
        this.searchString = '';
    }

    ngOnDestroy() {
        this.friendsService.friendsListSubject.unsubscribe();
        this.friendsService.incomingListSubject.unsubscribe();
        this.friendsService.outgoingListSubject.unsubscribe();
        this.friendsService.searchListSubject.unsubscribe();
    }
}
