import { Component, OnDestroy, OnInit } from '@angular/core';
import { GamesService, ShortGameInfo } from './games.service';
import { MatDialog } from '@angular/material/dialog';
import { FriendsComponent } from '../../friends/friends/friends.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-games',
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit, OnDestroy {

    public shortGameInfoList: Array<ShortGameInfo>;
    public shortGameInfoListS: Subscription;

    constructor(
        public translate: TranslateService,
        private gamesService: GamesService,
        private matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.gamesService.shortGameInfoListSubject.subscribe(shortGameInfoList => this.shortGameInfoList = shortGameInfoList.games);
        this.gamesService.loadGames();
    }

    openFriendsDialog() {
        this.matDialog.open(FriendsComponent, {
            width: '600px',
            height: '80vh'
        });
    }

    ngOnDestroy() {
        this.shortGameInfoListS.unsubscribe();
    }
}
