import { QuizConstructorActions, QuizConstructorActionType } from './quiz-constructor.actions';
import {
    AnswerInfo,
    AnswerType,
    QuestionType,
    QuizConstructorErrors,
    QuizInfo,
    QuizQuestionInfo, QuestionTemplateInfo,
    UserRooms
} from '../quiz-constructor.service';
import * as storage from '../../../app/app.storage';

export interface QuizConstructorState {
    quizList: Array<QuizInfo>;
    userRooms: UserRooms;
    moderatingQuizList: Array<QuizInfo>;
    selectedQuiz: QuizInfo;
    selectedQuestion: QuizQuestionInfo;
    questionTemplate: boolean;
    selectedQuestionFileBlob: Blob;
    selectedQuestionFileUploadStatus: boolean;
    selectedQuestionAnswerDescriptionFileBlob: Blob;
    selectedQuestionAnswerDescriptionFileUploadStatus: boolean;
    quizConstructorErrors: QuizConstructorErrors;
    questionTemplateList: Array<QuestionTemplateInfo>;
}

export const initialState: QuizConstructorState = {
    quizList: storage.getItem('quizConstructorState').quizList || new Array<QuizInfo>(),
    userRooms: storage.getItem('quizConstructorState').userRooms,
    moderatingQuizList: storage.getItem('quizConstructorState').moderatingQuizList || new Array<QuizInfo>(),
    selectedQuiz: storage.getItem('quizConstructorState').selectedQuiz,
    questionTemplate: storage.getItem('quizConstructorState').questionTemplate,
    selectedQuestion: storage.getItem('quizConstructorState').selectedQuestion,
    selectedQuestionFileBlob: null,
    selectedQuestionFileUploadStatus: false,
    selectedQuestionAnswerDescriptionFileBlob: null,
    selectedQuestionAnswerDescriptionFileUploadStatus: false,
    quizConstructorErrors: storage.getItem('quizConstructorState').quizConstructorErrors || null,
    questionTemplateList: new Array<QuestionTemplateInfo>()
};

export function quizConstructorReducer(state = initialState, action: QuizConstructorActions): QuizConstructorState {
    switch (action.type) {
        case QuizConstructorActionType.GetAllQuizzesSuccess:
            return {
                ...state,
                quizList: action.quizList,
                selectedQuiz: action.quizList.find(quiz => quiz.id === state.selectedQuiz?.id)
            };
        case QuizConstructorActionType.GetUserRoomsSuccess:
            console.log('User Rooms', action.userRooms);
            return {
                ...state,
                userRooms: action.userRooms
            };
        case QuizConstructorActionType.GetAllModeratingQuizzesSuccess:
            return {
                ...state,
                moderatingQuizList: action.quizList,
                selectedQuiz: action.quizList.find(quiz => quiz.id === state.selectedQuiz?.id)
            };
        case QuizConstructorActionType.GetQuizSuccess:
            return {
                ...state,
                quizList: state.quizList.map(quiz => quiz.id === action.quizInfo.id ? action.quizInfo : quiz),
                selectedQuiz: action.quizInfo,
                quizConstructorErrors: {
                    ...state.quizConstructorErrors,
                    getQuizError: null
                }
            };
        case QuizConstructorActionType.GetQuizError:
            return {
                ...state,
                selectedQuiz: null,
                quizConstructorErrors: {
                    ...state.quizConstructorErrors,
                    getQuizError: action.error
                }
            };
        case QuizConstructorActionType.SetSelectedQuestion:
            return {
                ...state,
                selectedQuestion:
                    action.quizQuestionInfo ?
                    action.quizQuestionInfo :
                    {
                        number: -1,
                        type: QuestionType.TEXT,
                        value: '',
                        answerType: AnswerType.ONE,
                        answers: Array<AnswerInfo>(4),
                        timeout: 30,
                        points: 10
                    },
                questionTemplate: action?.questionTemplate
            };
        case QuizConstructorActionType.UploadQuestionMediaSuccess:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    media: action.fileUrl
                },
                selectedQuestionFileUploadStatus: true
            };
        case QuizConstructorActionType.UploadAnswerDescriptionMediaSuccess:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answerDescriptionMedia: action.fileUrl
                },
                selectedQuestionAnswerDescriptionFileUploadStatus: true
            };
        case QuizConstructorActionType.SetQuestionFileBlob:
            return {
                ...state,
                selectedQuestionFileBlob: action.questionFileBlob
            };
        case QuizConstructorActionType.SetQuestionType:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    type: action.questionType
                }
            };
        case QuizConstructorActionType.SetAnswerType:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answerType: action.answerType,
                    /*answers: (action.answerType === AnswerType.CUSTOM || action.answerType === AnswerType.SPEED) ?
                        new Array<AnswerInfo>() :
                        state.selectedQuestion.answers*/
                }
            };
        case QuizConstructorActionType.SetQuestionText:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    value: action.questionText
                }
            };
        case QuizConstructorActionType.SetAnswersText:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answers: action.questionAnswersText
                }
            };
        case QuizConstructorActionType.SetCustomAnswerText:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    correctAnswer: action.customAnswerText
                }
            };
        case QuizConstructorActionType.SetQuestionTimeout:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    timeout: action.timeout
                }
            };
        case QuizConstructorActionType.SetQuestionPoints:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    points: action.points
                }
            };
        case QuizConstructorActionType.SetAnswerDescriptionType:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answerDescriptionType: action.answerDescriptionType
                }
            };
        case QuizConstructorActionType.SetAnswerDescriptionValue:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answerDescriptionValue: action.answerDescriptionValue
                }
            };
        case QuizConstructorActionType.SetAnswerDescriptionMedia:
            return {
                ...state,
                selectedQuestion: {
                    ...state.selectedQuestion,
                    answerDescriptionMedia: action.answerDescriptionMedia
                }
            };
        case QuizConstructorActionType.SetAnswerDescriptionFileBlob:
            return {
                ...state,
                selectedQuestionAnswerDescriptionFileBlob: action.answerDescriptionFileBlob
            };
        case QuizConstructorActionType.AddQuestionSuccess:
        case QuizConstructorActionType.UpdateQuestionSuccess:
        case QuizConstructorActionType.AddQuestionTemplateSuccess:
            return {
                ...state,
                selectedQuestionAnswerDescriptionFileBlob: null,
                selectedQuestionAnswerDescriptionFileUploadStatus: false,
                selectedQuestionFileBlob: null,
                selectedQuestionFileUploadStatus: false
            };
        case QuizConstructorActionType.GetQuestionTemplateListSuccess:
            return {
                ...state,
                questionTemplateList: action.quizQuestionTemplates
            };
        case QuizConstructorActionType.GetQuestionTemplateListError:
            return {
                ...state,
                questionTemplateList: null
            };
        default:
            return state;
    }
}
