import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    BetaAccessFormComponent,
    BetaAccessFormErrorDialogComponent
} from './beta-access-form/beta-access-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ShareButtonsConfig, ShareModule } from 'ngx-sharebuttons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { BetaAccessShareComponent } from './beta-access-share/beta-access-share.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

const customConfig: ShareButtonsConfig = {
    include: ['facebook', 'twitter', 'vk', 'telegram', 'whatsapp'],
    theme: 'modern-light',
    gaTracking: true
};

@NgModule({
    declarations: [BetaAccessFormComponent, BetaAccessShareComponent, BetaAccessFormErrorDialogComponent],
    exports: [BetaAccessFormComponent],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        ShareModule.withConfig(customConfig),
        FontAwesomeModule,
        MatDialogModule,
        TranslateModule
    ]
})
export class BetaAccessFormModule {

    constructor(library: FaIconLibrary) {
        library.addIcons(faFacebookF);
        library.addIcons(faTwitter);
        library.addIcons(faVk);
        library.addIcons(faTelegramPlane);
        library.addIcons(faWhatsapp);
    }
}
