import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatestAll, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { from, of, zip } from 'rxjs';
import * as actions from './quiz-constructor.actions';
import { Router } from '@angular/router';
import { QuizConstructorService, QuizDescriptionInfo, QuizInfo, QuestionTemplateInfo, UserRooms } from '../quiz-constructor.service';
import { select, Store } from '@ngrx/store';
import {
    selectedQuestionAnswerDescriptionFileBlobSelector,
    selectedQuestionFileBlobSelector,
    selectedQuestionSelector,
    selectedQuizIdSelector,
    selectedQuizSelector
} from './quiz-constructor.selectors';
import { FileManagerService } from '../../../libs/file-manager/file-manager.service';

@Injectable()
export class QuizConstructorEffects {

    constructor(
        private actions$: Actions,
        private quizConstructorService: QuizConstructorService,
        private router: Router,
        private location: Location,
        private store: Store,
        private fileManagerService: FileManagerService
    ) { }

    // Create quiz
    createQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.CreateQuiz),
        mergeMap((action: actions.CreateQuiz) => from(this.quizConstructorService.createQuiz(action.quizInfo))),
        map((quizId: string) => new actions.GetAllQuizzes())
    ));

    // Update quiz info
    updateQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.UpdateQuiz),
        mergeMap((action: actions.UpdateQuiz) => from(this.quizConstructorService.updateQuiz(action.quizInfo))),
        map((accept: boolean) => new actions.GetAllQuizzes())
    ));

    // Update quiz status from review
    reviewQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.ReviewQuiz),
        mergeMap((action: actions.ReviewQuiz) => from(this.quizConstructorService.reviewQuiz(action.quizInfo))),
        map((accept: boolean) => new actions.GetAllModeratingQuizzes())
    ));

    // Enable quiz creation share
    enableQuizCreationShare$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.EnableQuizCreationShare),
        mergeMap((action: actions.EnableQuizCreationShare) => from(this.quizConstructorService.enableQuizCreationShare(action.quizId))),
        map((shareId: string) => new actions.GetAllQuizzes())
    ));

    // Disable quiz creation share
    disableQuizCreationShare$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.DisableQuizCreationShare),
        mergeMap((action: actions.DisableQuizCreationShare) => from(this.quizConstructorService.disableQuizCreationShare(action.quizId))),
        map((result: boolean) => new actions.GetAllQuizzes())
    ));

    // Delete quiz
    deleteQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.DeleteQuiz),
        mergeMap((action: actions.DeleteQuiz) => from(this.quizConstructorService.deleteQuiz(action.quizId))),
        map((accept: boolean) => new actions.GetAllQuizzes())
    ));

    // Get all user quizzes
    getAllQuizzes$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetAllQuizzes),
        mergeMap(() => from(this.quizConstructorService.getAllQuizzes())),
        map((data: Array<QuizInfo>) => new actions.GetAllQuizzesSuccess(data))
    ));

    // Get all user history quizzes
    getUserRooms$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetUserRooms),
        mergeMap(() => from(this.quizConstructorService.getUserRooms())),
        map((data: UserRooms) => new actions.GetUserRoomsSuccess(data))
    ));

    // Get all quizzes for moderation
    getAllModeratingQuizzes$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetAllModeratingQuizzes),
        mergeMap(() => from(this.quizConstructorService.getAllModeratingQuizzes())),
        map((data: Array<QuizInfo>) => new actions.GetAllModeratingQuizzesSuccess(data))
    ));

    // Get user quiz bu ID
    getQuizById$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetQuiz),
        mergeMap((action: actions.GetQuiz) => from(this.quizConstructorService.getQuiz(action.quizId))),
        map((quizInfo: QuizInfo) => new actions.GetQuizSuccess(quizInfo)),
        catchError(error => of(new actions.GetQuizError(error.error.errorMessage)))
    ));

    // Get shared quiz bu share link
    getSharedQuizByShareLink$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetSharedQuiz),
        mergeMap((action: actions.GetSharedQuiz) => from(this.quizConstructorService.getSharedQuiz(action.shareLink))),
        map((quizInfo: QuizInfo) => new actions.GetQuizSuccess(quizInfo)),
        catchError(error => of(new actions.GetQuizError(error.error.errorMessage)))
    ));

    setSelectedQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.SetSelectedQuiz),
        map((quizInfo: QuizInfo) => new actions.GetQuiz(quizInfo.id)),
        // tap(() => this.router.navigate(['quiz-editor']))
    ));

    setSelectedQuestion$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.SetSelectedQuestion),
        tap(() => this.router.navigate(['question-generation']))
    ), { dispatch: false });

    // Upload media before add or update question
    uploadQuestionMedia$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.UploadQuestionMedia),
        withLatestFrom(
            this.store.select(selectedQuestionFileBlobSelector),
            this.store.select(selectedQuizSelector),
            (action, fileBlob, selectedQuiz) => {
                return {action, fileBlob, selectedQuiz};
            }
        ),
        mergeMap((input) =>
            this.fileManagerService.uploadMediaFile(input.fileBlob, input.selectedQuiz.shareLink, input.selectedQuiz.owner)
        ),
        map(uploadResult => {
            return new actions.UploadQuestionMediaSuccess(uploadResult.ref.fullPath);
        }),
        // catchError(() => new UploadQuestionMediaError())
    ));

    uploadAnswerDescriptionMedia$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.UploadAnswerDescriptionMedia),
        withLatestFrom(
            this.store.select(selectedQuestionAnswerDescriptionFileBlobSelector),
            this.store.select(selectedQuizSelector),
            (action, fileBlob, selectedQuiz) => {
                return {action, fileBlob, selectedQuiz};
            }
        ),
        mergeMap((input) =>
            this.fileManagerService.uploadMediaFile(input.fileBlob, input.selectedQuiz.shareLink, input.selectedQuiz.owner)
        ),
        map(uploadResult => {
            return new actions.UploadAnswerDescriptionMediaSuccess(uploadResult.ref.fullPath);
        }),
        /*catchError(() => new UploadAnswerDescriptionMediaError())*/
    ));

    /*uploadQuestionMediaSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.UploadQuestionMediaSuccess),
        withLatestFrom(this.store.select(selectedQuestionSelector)),
        map(([action, questionInfo]) => questionInfo.id ? new actions.UpdateQuestion() : new actions.AddQuestion())
    ));*/

    /*
     poiskSoundPlay$ = createEffect(() => this.actions$.pipe(
     ofType(actions.PoiskActionType.GotNewTarget),
     withLatestFrom(this.store$.pipe(select(getSoundMuteForceByRouteSelector))),
     filter(([action, forceSoundOff]) => forceSoundOff !== true),
     map(([action]) => action),
     withLatestFrom(this.store$.pipe(select(getSoundMuteSettingSelector))),
     filter(([action, soundMode]) => soundMode !== 'mute'),
     tap(([action, soundMode]) => this.alertPlayer.speakTarget((action as actions.GotNewTarget).payload.target, soundMode))
     ), { dispatch: false });
    */

    addQuizQuestion$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.AddQuestion),
        withLatestFrom(this.store.select(selectedQuizSelector)),
        withLatestFrom(this.store.select(selectedQuestionSelector)),
        mergeMap(([[action, selectedQuiz], questionInfo]) => from(this.quizConstructorService.createQuestion(selectedQuiz, questionInfo))),
        map((result: {id: string}) => {
            console.log('New question ID: ' + result.id);
            return new actions.AddQuestionSuccess(result.id);
        })
    ));

    addQuestionTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.AddQuestionTemplate),
        withLatestFrom(this.store.select(selectedQuestionSelector)),
        mergeMap(([action, questionInfo]) => from(this.quizConstructorService.createQuestionTemplate(questionInfo))),
        map((result: {id: string}) => {
            console.log('New question ID: ' + result.id);
            return new actions.AddQuestionTemplateSuccess(result.id);
        })
    ));

    updateQuizQuestion$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.UpdateQuestion),
        withLatestFrom(this.store.select(selectedQuestionSelector)),
        mergeMap(([action, questionInfo]) => from(this.quizConstructorService.updateQuestion(questionInfo))),
        map((answer: {result: boolean}) => new actions.UpdateQuestionSuccess(answer.result))
    ));

    deleteQuizQuestion$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.DeleteQuestion),
        mergeMap((action: actions.DeleteQuestion) => from(this.quizConstructorService.deleteQuestion(action.questionId))),
        withLatestFrom(this.store.select(selectedQuizSelector)),
        map(([answer, quizInfo]) => new actions.GetQuiz(quizInfo.id)) // {result: boolean}
    ));

    // Moving back and update edited quiz question on adding new question or update existing
    navigateBackWithUpdate$ = createEffect(() => this.actions$.pipe(
        ofType(
            actions.QuizConstructorActionType.UpdateQuestionSuccess,
            actions.QuizConstructorActionType.AddQuestionSuccess
        ),
        withLatestFrom(this.store.select(selectedQuizIdSelector)),
        map(([action, quizId]) => new actions.GetQuiz(quizId)),
        tap(action =>  this.location.back())
    ));

    navigateBack$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.AddQuestionTemplateSuccess),
        tap(() => this.location.back())
    ), { dispatch: false });
     /*setSelectedQuestion$ = createEffect(() => this.actions$.pipe(
     ofType(actions.QuizConstructorActionType.SetSelectedQuestion),
     tap(() => this.router.navigate(['question-generation']))
     ), { dispatch: false });
    */

    getQuestionTemplateList$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizConstructorActionType.GetQuestionTemplateList),
        mergeMap(() => from(this.quizConstructorService.getQuestionTemplateList())),
        map((data: Array<QuestionTemplateInfo>) => new actions.GetQuestionTemplateListSuccess(data))
    ));
}
