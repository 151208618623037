<div class="content">
    <div *ngIf="isDesktopDevice"
         aTilt
         class="logo">
        <div style="transform: translateZ(20px)">
            <img src="assets/images/LogoFront.png"
                 alt="LogoFront">
        </div>
    </div>
    <div>Сервис для создания лучших квизов для вечеринок</div>
    <div *ngIf="!isDesktopDevice"
         class="logo">
        <div style="transform: translateZ(20px)">
            <img src="assets/images/LogoFront.png"
                 alt="LogoFront">
        </div>
    </div>

    <mat-select [value]="lang"
                (valueChange)="changeLang($event)"
                class="lang-select">
        <mat-option value="en">EN</mat-option>
        <mat-option value="ru">RU</mat-option>
    </mat-select>

    <div class="selectors-wrapper">
        <div (click)="selectedBlock = 0"
             class="first-block-color"
             [ngClass]="{'selected-selector': selectedBlock === 0}">
            <img src="assets/images/promo/WithFriends.jpeg">
            <div>Играй с друзьями</div>
        </div>
        <div (click)="selectedBlock = 1"
             class="second-block-color"
             [ngClass]="{'selected-selector': selectedBlock === 1}">
            <img src="assets/images/promo/Party.jpeg">
            <div>Создавай и проводи</div>
        </div>
        <div (click)="selectedBlock = 2"
             class="third-block-color"
             [ngClass]="{'selected-selector': selectedBlock === 2}">
            <img src="assets/images/promo/Work.jpeg">
            <div>Создавай для всех</div>
        </div>
    </div>

    <div *ngIf="selectedBlock === 0"
         class="selected-block first-block-color">
        <div class="block-description">В нашем сервисе ты можешь найти множество разнообразных викторин и сыграть в них
            с друзьями. Соревнуйтесь, получайте награды и повышайте свою эрудицию!
        </div>
        <div class="step-wrapper">
            <div class="step-number">1</div>
            <div class="step-content">Выбирай с друзьями понравившийся квиз на удобном для просмотра устройстве</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">2</div>
            <div class="step-content">Подключайтесь мобильными устройствами по QR коду или номеру комнаты</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">3</div>
            <div class="step-content">Соревнуйтесь и получаете массу эмоций</div>
        </div>
    </div>
    <div *ngIf="selectedBlock === 1"
         class="selected-block second-block-color">
        <div class="block-description">Можно не только играть в квизы, но и создавать свои! Сделай незабываемый подарок
            на праздник или используй проведения мероприятий.
        </div>
        <div class="step-wrapper">
            <div class="step-number">1</div>
            <div class="step-content">Перейди в список своих квизов</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">2</div>
            <div class="step-content">Создай новый квиз и наполни его вопросами</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">3</div>
            <div class="step-content">Запусти на устройстве, на котором будешь показывать</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">4</div>
            <div class="step-content">Попроси всех желающих принять участие подключиться с помощью мобильных устройств
                по QR коду или номеру комнаты
            </div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">5</div>
            <div class="step-content">Проведи незабываемую викторину</div>
        </div>
    </div>
    <div *ngIf="selectedBlock === 2"
         class="selected-block third-block-color">
        <div class="block-description">Создавай викторины для всех желающих и зарабатывай!</div>
        <div class="step-wrapper">
            <div class="step-number">1</div>
            <div class="step-content">Перейди в список своих квизов</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">2</div>
            <div class="step-content">Создай новый квиз и наполни его вопросами</div>
        </div>
        <div class="step-wrapper">
            <div class="step-number">3</div>
            <div class="step-content">Сделай его публичным и он станет доступным всем желающим</div>
        </div>
    </div>
    <div>У нас можно сделать самые необычные викторины и игры</div>
    <div>Какие есть разновидности вопросов, наши фишки</div>
</div>
