import { delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AudioEditorService } from '../audio-editor.service';
import { select, Store } from '@ngrx/store';
import * as actions from './audio-editor.actions';
import { croppedFileSelector, selectedFileSelector } from './audio-editor.selectors';
import { QuizInfo } from '../../quiz-constructor/quiz-constructor.service';

@Injectable()
export class AudioEditorEffects {

    constructor(
        private actions$: Actions,
        private audioEditorService: AudioEditorService,
        private store: Store,
    ) {
    }

    // Send file to audio processing
    sendToProcessing$ = createEffect(() => this.actions$.pipe(
        ofType(actions.AudioEditorActionType.SendToProcessing),
        withLatestFrom(this.store.select(selectedFileSelector)),
        mergeMap(([action, selectedFile]) =>
            from(this.audioEditorService.sendFileToProcess((action as actions.SendToProcessing).processingType, selectedFile))),
        map((filename: string) => new actions.GetFileStatus(filename))
    ));

    getFileStatus$ = createEffect(() => this.actions$.pipe(
        ofType(actions.AudioEditorActionType.GetFileStatus),
        delay(5000),
        mergeMap((action: actions.GetFileStatus) => from(this.audioEditorService.getFileStatus(action.filename))),
        map((data: { filename: string, status: string }) => {
            console.log('DEBUG: STATUS', data.status);
            if (data.status === 'SUCCESS') {
                return new actions.GetProcessedFile(data.filename);
            } else if (data.status === 'IN_PROGRESS' || data.status === 'FAILURE') {
                return new actions.GetFileStatus(data.filename);
            }
        })
    ));

    getProcessedFile$ = createEffect(() => this.actions$.pipe(
        ofType(actions.AudioEditorActionType.GetProcessedFile),
        mergeMap((action: actions.GetFileStatus) => from(this.audioEditorService.getProcessedFile(action.filename))),
        map((processedFile: Blob) => new actions.GetProcessedFileSuccess(this.audioEditorService.processingType, processedFile))
    ));
}
