import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

export interface ShortGameInfo {
    logoUrl: string;
    name: string;
    displayName: string;
    players: string;
    averagePlayTime: string;
    description: string;
    available: boolean;
}

export interface ShortGameInfoList {
    games: Array<ShortGameInfo>;
}

@Injectable()
export class GamesService {

    public shortGameInfoListSubject = new Subject<ShortGameInfoList>();

    constructor(private httpClient: HttpClient) { }

    loadGames() {
        this.httpClient.post<ShortGameInfoList>(
            environment.URLPrefix + '/game/list',
            {}
        ).subscribe(ans => {
            this.shortGameInfoListSubject.next(ans);
        });
    }
}
