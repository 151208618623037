import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { questionTemplateListSelector } from '../store/quiz-constructor.selectors';
import { AnswerInfo, QuestionTemplateInfo } from '../quiz-constructor.service';
import { UnsubscribeSubjectComponent } from '../../../libs/unsubscribe-subject-component';
import { AppState } from '../../../app/app.state';
import * as quizConstructorActions from '../store/quiz-constructor.actions';

@Component({
    selector: 'app-questions-bank',
    templateUrl: './questions-bank.component.html',
    styleUrls: ['./questions-bank.component.scss']
})
export class QuestionsBankComponent extends UnsubscribeSubjectComponent implements OnInit {

    public questionTemplateList: Array<QuestionTemplateInfo>;

    constructor(
        public dialogRef: MatDialogRef<QuestionsBankComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        private store: Store<AppState>
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionTemplateListSelector)
        ).subscribe(questionTemplateList => {
            this.questionTemplateList = questionTemplateList;
        });
        this.store.dispatch(new quizConstructorActions.GetQuestionTemplateList());
    }

    addQuestionTemplate() {
        this.store.dispatch(new quizConstructorActions.SetSelectedQuestion(null, true));
    }

    public getCorrectAnswers(answers: Array<AnswerInfo>) {
        return answers.reduce((acc, val) =>
            val.correct ? acc + val.value + ', ' : acc
        , '').slice(0, -2);
    }
}
