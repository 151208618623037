import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { audioEditorReducer } from './store/audio-editor.reducer';
import { AudioEditorEffects } from './store/audio-editor.effects';
import { AudioEditorService } from './audio-editor.service';
import { AudioEditorComponent } from './audio-editor.component';
import { WaveControlComponent } from './audio-player/wave-control/wave-control.component';
import { AudioPlayerComponent } from './audio-player/audio-player/audio-player.component';
import { WaveLineComponent } from './audio-player/wave-line/wave-line.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    declarations: [
        AudioEditorComponent,
        WaveLineComponent,
        AudioPlayerComponent,
        WaveControlComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        StoreModule.forFeature('audioEditorFeature', {
            audioEditor: audioEditorReducer
        }),
        EffectsModule.forFeature([
            AudioEditorEffects
        ]),
    ],
    providers: [
        AudioEditorService
    ],
    exports: [
        AudioEditorComponent,
        AudioPlayerComponent
    ]
})
export class AudioEditorModule {}
