<div class="player"
     [ngClass]="isPlaying ? 'player--playing' : ''">

    <app-wave-control #scrubberRef
                      [audioShape]="audioShape || null"
                      [color]="color"
                      [duration]="this.audioInterface ? this.audioInterface.duration : DEFAULT_AUDIO_DURATION"
                      [audioInterface]="audioInterface"
                      [interactionHandler]="handleScrubberInteraction"
                      [visualization]="visualization">
    </app-wave-control>
    <div
         class="container"
         [ngClass]="{'player__controls-container--hidden': shouldHideControls}">
        <div class="player__controls"
             [ngClass]="{'player__controls--is-small': isSmall}">
            <button mat-icon-button
                    [matTooltip]="isPlaying || isPlayDisabled ? 'Pause' : 'Play'"
                    class="player__controls__play"
                    [disabled]="isPlayDisabled"
                    (click)="handlePlayButtonClick()"
                    (keyup)="handlePlayButtonKeyUp($event)"
                    [tabindex]="shouldHideControls || !isFocusable ? -1 : 0">
                <mat-icon>{{ isPlaying || isPlayDisabled ? 'stop' : 'play_arrow' }}</mat-icon>
                <!--                    <mat-icon *ngIf="isPlaying || isPlayDisabled">stop</mat-icon>-->
            </button>
            <!--                {additionalButtons}-->
        </div>
    </div>
    <!--<mat-form-field appearance="fill">
        <mat-label>{{ "GENERATION.SECONDS" | translate }}</mat-label>
        <input matInput
               type="number"
               [(ngModel)]="cutStartTime"
               (change)="cutStartTimeChanged()">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>{{ "GENERATION.SECONDS" | translate }}</mat-label>
        <input matInput
               type="number"
               [(ngModel)]="cutEndTime"
               (change)="cutEndTimeChanged()">
    </mat-form-field>-->
</div>
