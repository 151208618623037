<div *ngIf="waveformCoordinates?.length" class="waveform waveform--is-recording">
    <svg height="100%"
         preserveAspectRatio="none"
         width="100%"
         viewBox="0 0 100 2">
<!--        <path #path1 class="track" data-path="{&quot;from&quot;:1,&quot;to&quot;:2}" d="M 150 260 L 250 360" />-->
        <path #waveformPath
              [attr.d]="getCurve()"
              style="stroke: #7A28A9"
              vector-effect="non-scaling-stroke"/>
    </svg>
    <svg #waveform
         height="100%"
         preserveAspectRatio="none"
         viewBox="0 0 100 2"
         class="waveform__svg-recording-progress"
         [ngStyle]="{'display': isRecording ? 'block' : 'none'}">
        <path #recordProgress
              [attr.d]="getStraightLine()"
              style="color: #7A28A9"
              vector-effect="non-scaling-stroke"/>
    </svg>
    <svg #playhead
         class="playhead playhead--has-transition"
         style="display: block"
         height="24"
         width="24">
        <circle cx="0" cy="0" r="12" style="stroke: #7A28A9"></circle>
    </svg>
</div>
