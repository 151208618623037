import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { signOut } from '@angular/fire/auth';
import { AuthService } from '../../auth/auth/auth.service';
import { GameUserInfo } from '../../../../proto/generated/gambo_pb';

@Component({
    selector: 'app-user-header',
    templateUrl: './user-header.component.html',
    styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent {
    @Input() public numberOfQuestions: number = null;
    @Input() public questionNumber: number = null;
    @Input() public user: GameUserInfo = null;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    public redirectMainPage() {
        this.router.navigate(['']);
    }

    public redirectMyQuizzes() {
        this.router.navigate(['/my-quizzes']);
    }

    private localSignOut() {
        localStorage.removeItem('sid');
        this.authService.authSubject.next(false);
        this.router.navigate(['']);
    }

    public signOut() {
        this.authService.signOut().subscribe((ans) => {
            console.log('Sign out result', ans);
            if (ans.result) {
                this.localSignOut();
            }
        });
    }
}
