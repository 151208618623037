import { OnDestroy, Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    template: ''
})
export abstract class UnsubscribeSubjectComponent implements OnDestroy {
    protected unsubscribeSubject: Subject<void> = new Subject();

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
