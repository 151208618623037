import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { QuizInfo } from '../quiz-constructor/quiz-constructor.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-quiz-promo',
    templateUrl: './quiz-promo.component.html',
    styleUrls: ['./quiz-promo.component.scss']
})
export class QuizPromoComponent implements OnInit {

    title = 'Gambo quiz';
    lang = 'en';
    public isDesktopDevice = true;
    public selectedBlock = 0;

    constructor(
        private meta: Meta,
        public translate: TranslateService,
        private deviceService: DeviceDetectorService,
        private httpClient: HttpClient
    ) {
        if (localStorage.getItem('selectedLang')) {
            this.lang = localStorage.getItem('selectedLang');
        } else if (translate.getBrowserLang() === 'ru') {
            this.lang = 'ru';
        }
        translate.setDefaultLang(this.lang);
        this.translate.use(this.lang).subscribe(() => {
            this.meta.addTags([
                // { name: 'description', content: this.translate.instant('META.DESCRIPTION') },
                // { name: 'keywords', content: this.translate.instant('META.KEYS') },
                { property: 'og:title', content: this.translate.instant('META.OG_QUIZ_TITLE') },
                { property: 'og:description', content: this.translate.instant('META.OG_QUIZ_DESCRIPTION') }
            ]);
        });
        this.isDesktopDevice = this.deviceService.isDesktop();
    }

    ngOnInit(): void {
    }

    changeLang(lang: string) {
        this.lang = lang;
        localStorage.setItem('selectedLang', lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang).subscribe(() => {
            /*this.meta.updateTag(
             { name: 'description', content: this.translate.instant('META.DESCRIPTION') }
             );
             this.meta.updateTag(
             { name: 'keywords', content: this.translate.instant('META.KEYS') }
             );*/
            this.meta.updateTag(
                { property: 'og:title', content: this.translate.instant('META.OG_TITLE') }
            );
            this.meta.updateTag(
                { property: 'og:description', content: this.translate.instant('META.OG_DESCRIPTION') }
            );
        });
    }

    doAlexStaff() {
        this.httpClient.get<{success: boolean}>(
            'http://161.35.166.39:9827/test-ip/do-smth',
            {}
        ).subscribe(result => {
            console.log(result);
        });
    }
}
