import { Component, OnDestroy, OnInit } from '@angular/core';
import { QuizScoreData, QuizQuestionStatus, GameUserInfo } from '../../../../proto/generated/gambo_pb';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { playerNumberSelector, playersListSelector, questionStatusSelector, scoreboardListSelector } from '../store/quiz-runner.selectors';
import { AppState } from '../../../app/app.state';
import { QuizRunnerSocket } from '../quiz-runner.socket';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-quiz-runner-scoreboard',
    templateUrl: './quiz-runner-scoreboard.component.html',
    styleUrls: ['./quiz-runner-scoreboard.component.scss']
})
export class QuizRunnerScoreboardComponent implements OnInit, OnDestroy {

    readonly QuizQuestionStatus = QuizQuestionStatus;

    public playersList = new Array<GameUserInfo>();
    public playerNumber: number;
    public scoreBoard: Array<QuizScoreData>;
    public questionStatus: number;

    private unsubscribeSubject: Subject<void> = new Subject();

    constructor(
        public quizRunnerSocket: QuizRunnerSocket,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playersListSelector)
        ).subscribe(answeredPlayersList => {
            this.playersList = answeredPlayersList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(scoreboardListSelector)
        ).subscribe(scoreboardList => {
            this.scoreBoard = scoreboardList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            this.questionStatus = questionStatus;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playerNumberSelector)
        ).subscribe(playerNumber => {
            this.playerNumber = playerNumber;
        });
    }

    public findPlayerByNumber(playerNumber: number): GameUserInfo {
        return this.playersList.find(player => player.getPlayernumber() === playerNumber);
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
