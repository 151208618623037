import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class PreviousRouteService {

    private previousUrl: string;
    private currentUrl: string;

    constructor(
        private location: Location,
        private router: Router
    ) {
        this.currentUrl = this.location.path();
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = this.location.path();
            }
        });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }
}
