import { Action } from '@ngrx/store';
import { QuizInfo } from '../../quiz-constructor/quiz-constructor.service';
import { ProcessingType } from '../audio-editor.service';
import { FileType } from './audio-editor.reducer';

export enum AudioEditorActionType {
    ChangeSelectedFile = '[AudioEditor] Change Selected File',
    SetInitialFile = '[AudioEditor] Set Initial File',
    SetCroppedFile = '[AudioEditor] Set Cropped File',
    SetReversedFile = '[AudioEditor] Set Reversed File',
    SendToProcessing = '[AudioEditor] Send To Processing',
    SendToProcessingSuccess = '[AudioEditor] Send To Processing Success',
    GetFileStatus = '[AudioEditor] Get File Status',
    GetFileStatusSuccess = '[AudioEditor] Get File Status Success',
    GetProcessedFile = '[AudioEditor] Get Processed File',
    GetProcessedFileSuccess = '[AudioEditor] Get Processed File Success'
}

export class ChangeSelectedFile implements Action {
    readonly type = AudioEditorActionType.ChangeSelectedFile;

    constructor(public fileType: FileType) { }
}

export class SetInitialFile implements Action {
    readonly type = AudioEditorActionType.SetInitialFile;

    constructor(public file: Blob) { }
}

export class SetCroppedFile implements Action {
    readonly type = AudioEditorActionType.SetCroppedFile;

    constructor(public file: Blob) { }
}

export class SetReversedFile implements Action {
    readonly type = AudioEditorActionType.SetReversedFile;

    constructor(public file: Blob) { }
}

export class SendToProcessing implements Action {
    readonly type = AudioEditorActionType.SendToProcessing;

    constructor(public processingType: ProcessingType) { }
}

export class SendToProcessingSuccess implements Action {
    readonly type = AudioEditorActionType.SendToProcessingSuccess;

    constructor(public filename: string) { }
}

export class GetFileStatus implements Action {
    readonly type = AudioEditorActionType.GetFileStatus;

    constructor(public filename: string) { }
}

export class GetFileStatusSuccess implements Action {
    readonly type = AudioEditorActionType.GetFileStatusSuccess;

    constructor(public data: { filename: string, status: string }) { }
}

export class GetProcessedFile implements Action {
    readonly type = AudioEditorActionType.GetProcessedFile;

    constructor(public filename: string) { }
}

export class GetProcessedFileSuccess implements Action {
    readonly type = AudioEditorActionType.GetProcessedFileSuccess;

    constructor(public processingType: ProcessingType, public file: Blob) { }
}

export type AudioEditorActions = ChangeSelectedFile
    | SetInitialFile
    | SetCroppedFile
    | SetReversedFile
    | SendToProcessing
    | SendToProcessingSuccess
    | GetFileStatus
    | GetFileStatusSuccess
    | GetProcessedFile
    | GetProcessedFileSuccess;
