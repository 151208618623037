<form [formGroup]="form"
      (ngSubmit)="onSubmit(form.value)"
      id="beta-access-form"
      class="form">
    <div class="choose-system">
        <mat-icon (click)="setSystem('Android')"
                  [ngStyle]="{ color: system === 'Android' ? '#3ddc84' : '#dddddd' }">
            android
        </mat-icon>
        <mat-icon svgIcon="apple"
                  (click)="setSystem('iOS')"
                  [ngStyle]="{ color: system === 'iOS' ? '#acacac' : '#dddddd' }">
        </mat-icon>
    </div>
    <mat-error *ngIf="!system && form.get('email').touched"
               class="os-error">
        {{ "BETA_ACCESS_FORM.REQUIRED_OS" | translate }}
    </mat-error>

    <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input (input)="onEmailChange()"
               matInput
               required
               email
               formControlName="email" />
        <mat-icon matSuffix>email</mat-icon>
        <mat-error *ngIf="form.get('email').hasError('required') && form.get('email').touched">
            {{ "BETA_ACCESS_FORM.REQUIRED_EMAIL" | translate }}
        </mat-error>
        <mat-error *ngIf="!form.get('email').hasError('required') && form.get('email').invalid && form.get('email').touched">
            {{ "BETA_ACCESS_FORM.INVALID" | translate }}
        </mat-error>
    </mat-form-field>
    <button id="beta-access-form__submit-button"
            type="submit"
            class="button">
        {{ "BETA_ACCESS_FORM.BUTTON" | translate }}
    </button>
    <div class="policy">
        {{ "BETA_ACCESS_FORM.POLICY_1" | translate }}
        <a routerLink="/privacy-policy"
           routerLinkActive="active">
            {{ "BETA_ACCESS_FORM.POLICY_2" | translate }}
        </a>
    </div>
</form>
<button style="color: transparent; background: transparent; border: none;" (click)="openShareWindow()">OPEN</button>
