<h1>{{ "NEWSLETTER_UNSUBSCRIBE_FORM.TITLE" | translate }}</h1>
<form [formGroup]="form"
      (ngSubmit)="onSubmit(form.value)">
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let reason of reasons" (click)="onReasonChange(reason)">
            {{ ("NEWSLETTER_UNSUBSCRIBE_FORM." + reason) | translate }}
        </mat-list-option>
    </mat-selection-list>
    <button [disabled]="selectedReason === ''"
            mat-raised-button
            color="primary"
            type="submit"
            class="button">
        {{ "NEWSLETTER_UNSUBSCRIBE_FORM.SUBMIT" | translate }}
    </button>
</form>
<p *ngIf="successfullyUnsubscribed">{{ "NEWSLETTER_UNSUBSCRIBE_FORM.SUCCESSFULLY_UNSUBSCRIBED" | translate }}</p>
<p *ngIf="unsubscribeFailure">{{ "NEWSLETTER_UNSUBSCRIBE_FORM.UNSUBSCRIBE_FAILURE" | translate }}</p>
