<!--<button mat-raised-button
        (click)="backToQuizList()">
    BACK
</button>-->
<div *ngIf="selectedQuiz">
    <div *ngFor="let quizQuestionInfo of selectedQuiz.questions"
         (click)="editQuestion(quizQuestionInfo)"
         [ngClass]="{ 'selected-quiz-question': quizQuestionInfo?.id === selectedQuestion?.id }"
         class="quiz-question">
<!--        <mat-card-header>-->
<!--            <mat-card-title>{{ quizQuestionInfo.id }}</mat-card-title>-->
<!--        </mat-card-header>-->
        <div>
            <div>Тип вопроса: {{ quizQuestionInfo.type }}</div>
            <div>Вопрос: {{ quizQuestionInfo.value }}</div>
            <div>Тип ответа: {{ quizQuestionInfo.answerType }}</div>
            <div>Ответ: {{ quizQuestionInfo.correctAnswer }}</div>
        </div>
        <button mat-mini-fab
                (click)="removeQuestion(quizQuestionInfo.id)"
                color="accent"
                class="quiz-question-remove-button">
            <mat-icon>delete</mat-icon>
        </button>
        <!--<mat-card-actions>
            &lt;!&ndash;        ADD ACTIONS&ndash;&gt;
            <button mat-raised-button
                    (click)="editQuestion(quizQuestionInfo)">
                EDIT
    &lt;!&ndash;            {{ "FRIENDS.APPLY" | translate }}&ndash;&gt;
            </button>
            <button mat-raised-button
                    (click)="removeQuestion(quizQuestionInfo.id)">
                REMOVE
                &lt;!&ndash;            {{ "FRIENDS.APPLY" | translate }}&ndash;&gt;
            </button>
        </mat-card-actions>-->
    </div>
    <div (click)="createQuizQuestion()"
         class="quiz-question">
        <mat-icon class="mat-icon-rounded">add_circle</mat-icon>
    </div>
</div>
