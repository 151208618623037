<form [formGroup]="questionFormGroup"
      class="question-form">
    <mat-form-field appearance="outline"
                    class="question">
        <mat-label>{{ 'GENERATION.TEXT_QUESTION' | translate }}</mat-label>
        <input id="textQuestion"
               matInput
               formControlName="textQuestionControl"
               (change)="onTextQuestionChange($event.target)">
        <mat-error *ngIf="selectedQuestion.type === questionTypes.TEXT
                          && questionFormGroup.get('textQuestionControl').hasError('required')
                          && questionFormGroup.get('textQuestionControl').touched">
            {{ 'ERROR.QUESTION_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>
    <div appDragAndDropZone
         #dragAndDropZone
         (fileDropEvent)="onDropFiles($event)"
         class="drag-and-drop-zone">
        <mat-icon class="material-symbols-rounded drag-and-drop-zone-icon">upload_file</mat-icon>
        <div class="text-center">
            {{ 'GENERATION.DRAG_AND_DROP_IMPORT' | translate }}
        </div>
        <button mat-raised-button (click)="openFileSelector()">
            Выбрать файл
            <input #fileSelector
                   type="file"
                   [accept]="'.bmp,.jpeg,.jpg,.png,.webp,.mp4,.mp3,.wav,.mpeg'"
                   (change)="onFileSelect($event)"
                   style="display: none">
        </button>
        <!--<input type="file"
               class="file-uploading-button"
               [accept]="'.jpg,.png,.mp4,.mp3,.wav'"
               (change)="onFileSelect($event)">-->
    </div>
    <div *ngIf="selectedQuestion.type === questionTypes.IMAGE
                || selectedQuestion.type === questionTypes.VIDEO
                || selectedQuestion.type === questionTypes.AUDIO">
        <!--<div class="file-input-wrapper">
            <label for="file">File selector</label>
            <br>
            <input formControlName="file"
                   id="file"
                   type="file"
                   class="form-control"
                   [accept]="selectedQuestion.type === questionTypes.IMAGE ? '.jpg,.png' :
                   selectedQuestion.type === questionTypes.VIDEO ? '.mp4' : '.mp3,.wav'"
                   (change)="onFileChange($event)">
            <div *ngIf="questionFormGroup.controls.file.touched && questionFormGroup.controls.file.invalid" class="alert alert-danger">
                <div *ngIf="questionFormGroup.controls.file.errors.required">File is required.</div>
            </div>
        </div>-->

        <img [src]="fileSrc" *ngIf="fileSrc && selectedQuestion.type === questionTypes.IMAGE" style="height: 300px; width:500px">
        <video [src]="fileSrc" *ngIf="fileSrc && selectedQuestion.type === questionTypes.VIDEO" height="200" controls></video>
        <!--<audio *ngIf="fileSrc && selectedQuestion.type === questionTypes.AUDIO"
               controls
        [id]="audioSource">
            <source #audioSource
                    [src]="domSanitizer.bypassSecurityTrustResourceUrl(fileSrc)">
        </audio>-->
        <app-audio-editor *ngIf="file && selectedQuestion.type === questionTypes.AUDIO"
                          [activePlayerKey]="'questionEditor'"
                          (sourceAudioFileSetterChange)="updateQuestionFile($event)">
        </app-audio-editor>
        <br/>
        <button mat-raised-button color="accent" *ngIf="file" (click)="clearFile()">CLEAR FILE</button>
    </div>
    <!--<div *ngIf="selectedQuestionType === questionTypes.VIDEO">
        <label for="file">File</label>
        <input formControlName="file"
               id="videoFile"
               type="file"
               class="form-control"
               (change)="onFileChange($event)">
        <div *ngIf="questionFormGroup.controls.file.touched && questionFormGroup.controls.file.invalid" class="alert alert-danger">
            <div *ngIf="questionFormGroup.controls.file.errors.required">File is required.</div>
        </div>

        <video [src]="videoSrc" *ngIf="videoSrc" height="200" controls></video>
    </div>-->
</form>
