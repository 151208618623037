<div mat-dialog-content
     class="dialog-content-wrapper">
    <div class="header">{{ 'USER.NICKNAME' | translate }}</div>
    <mat-form-field appearance="fill"
                    class="dialog-input">
        <mat-label>{{ 'USER.NICKNAME' | translate }}</mat-label>
        <input matInput
               minlength="1"
               maxlength="64"
               [(ngModel)]="data">
    </mat-form-field>
</div>
<div mat-dialog-actions
     class="dialog-actions">
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="data"
            cdkFocusInitial>
        {{ 'COMMON.SAVE' | translate }}
    </button>
    <button mat-raised-button
            (click)="onNoClick()">
        {{ 'COMMON.CANCEL' | translate }}
    </button>
</div>
