import { Component, Input } from '@angular/core';
import { PlayerInfo } from '../../../../proto/generated/room_pb';

@Component({
  selector: 'app-room-manager-user-list',
  templateUrl: './room-manager-user-list.component.html',
  styleUrls: ['./room-manager-user-list.component.scss']
})
export class RoomManagerUserListComponent {

  @Input()
  public players = new Array<PlayerInfo>();

  isOpen = false;

}
