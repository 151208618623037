import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketConnect } from '../../libs/websocket/WebSocketConnect';
import { Store } from '@ngrx/store';
import { AppState } from '../../app/app.state';
import { environment } from '../../environments/environment';
import {
    GeneralMessage,
    MessageType,
    PingMessage,
    QuizAction,
    QuizMoveMessageRequest, QuizMoveResponseType, QuizMoveResponseTypeMap,
    QuizQuestion, QuizQuestionAnswerType, QuizQuestionContentType, QuizQuestionStatus, QuizStartGameMessageRequest
} from '../../../proto/generated/gambo_pb';
import * as quizRunnerActions from './store/quiz-runner.actions';
import { FileManagerService } from '../../libs/file-manager/file-manager.service';
import { Utils } from '../../libs/utils';

@Injectable()
export class QuizRunnerSocket implements OnDestroy {

    private ws: WebSocketConnect;
    public isHost = false;
    public isPlayableHost = false;
    public isPlayer = true;
    public isMobile = Utils.isMobile();
    public everyoneWithMedia = true;

    constructor(
        private store: Store<AppState>,
        private fileManagerService: FileManagerService
    ) {
        // this.auth = getAuth(this.firebaseApp);
        console.log('GAME SOCKET CONSTRUCTOR');
        this.isHost = localStorage.getItem('isHost') === 'true';
        this.isPlayableHost = !!localStorage.getItem('playableHostId');
        this.ws = new WebSocketConnect(environment.wsGameURL, null, 'arraybuffer', true);
        this.ws.init();
        // this.streamReader = new StreamReader(this.ws);
        this.ws.onconnect = () => this.connectionHandler();
        this.ws.onreconnect = () => this.ws.enableHeartbeat('game');
        this.ws.onmessage = (msg) => this.messageHandler(msg);
        this.ws.onclose = () => this.connectionCloseHandler();
        this.ws.enableHeartbeat('game');
    }

    public connectionHandler() {
        console.log('Connected to the remote');
        this.startGameRequest();
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_PING);
        const pingMsg = new PingMessage();
        // pingMsg.setHash('numberOfMessage');
        msg.setPingmessage(pingMsg);
        this.ws.send(msg.serializeBinary());
        // this.ws.send('message string');
    }

    public messageHandler(msg: any) {
        const response = GeneralMessage.deserializeBinary(msg);
        let messageType = 'UNKNOWN';
        for (const typesKey in MessageType) {
            if (MessageType[typesKey] === response.getMessagetype()) {
                messageType = typesKey;
                break;
            }
        }
        /*MESSAGE_TYPE_QUIZ_START_GAME_REQUEST: 80;
         MESSAGE_TYPE_QUIZ_MOVE_REQUEST: 81;
         MESSAGE_TYPE_QUIZ_CURRENT_STATE_REQUEST: 83;
         MESSAGE_TYPE_QUIZ_RESTART_REQUEST: 85;
         MESSAGE_TYPE_QUIZ_FINISH_REQUEST: 87;*/
        let questionMediaURL: string;
        switch (response.getMessagetype()) {
            case MessageType.MESSAGE_TYPE_QUIZ_MOVE_RESPONSE:
                const quizMoveResponse = Object.freeze(response.getQuizmovemessageresponse());
                console.log('===>>> GAMBO LOG RESPONSE TYPE: ', quizMoveResponse?.getResponsetype());
                console.log('===>>> GAMBO LOG PLAYER ANSWER: ', quizMoveResponse?.getPlayeranswer());
                console.log('===>>> GAMBO LOG SCOREBOARD: ', quizMoveResponse?.getScoreboardList());
                console.log('===>>> GAMBO LOG QUESTION TIMEOUT: ', quizMoveResponse?.getQuestion()?.getTimeout());
                console.log('===>>> GAMBO LOG QUESTION: ', quizMoveResponse?.getQuestion()?.getValue());
                this.store.dispatch(new quizRunnerActions.SetQuizMoveResponse(quizMoveResponse));

                if (
                    quizMoveResponse.getResponsetype() === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_STARTED_QUESTION
                ) {
                    this.loadMedia(questionMediaURL);
                    questionMediaURL = quizMoveResponse?.getQuestion()?.getMedia();
                } else if (
                    quizMoveResponse.getResponsetype() === QuizMoveResponseType.QUIZ_MOVE_RESPONSE_TYPE_FINISHED_QUESTION_HOST
                    && quizMoveResponse?.getQuestion()?.getAnswerdescriptiontype() !== QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_TEXT
                ) {
                    questionMediaURL = quizMoveResponse?.getQuestion()?.getAnswerdescriptionmedia();
                    this.loadMedia(questionMediaURL);
                }
                break;
            case MessageType.MESSAGE_TYPE_QUIZ_CURRENT_STATE_RESPONSE:
                console.log('===>>> GAMBO LOG QUIZ CURRENT STATE: ',
                    response.getQuizcurrentstatemessageresponse().getQuestion()?.getTimeout(),
                    response.getQuizcurrentstatemessageresponse().getQuestion()?.getValue());
                const quizCurrentStateResponse = Object.freeze(response.getQuizcurrentstatemessageresponse());
                this.store.dispatch(new quizRunnerActions.SetCurrentStateResponse(quizCurrentStateResponse));
                if (
                    quizCurrentStateResponse.getQuestionstatus() === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                    || quizCurrentStateResponse.getQuestionstatus() === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED
                ) {
                    questionMediaURL = quizCurrentStateResponse?.getQuestion()?.getMedia();
                    this.loadMedia(questionMediaURL);
                } else if (quizCurrentStateResponse.getQuestionstatus() === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED) {
                    questionMediaURL = quizCurrentStateResponse?.getQuestion()?.getAnswerdescriptionmedia();
                    this.loadMedia(questionMediaURL);
                }
                questionMediaURL = quizCurrentStateResponse?.getQuestion()?.getMedia();
                if (questionMediaURL) {
                    this.fileManagerService.downloadFileBlob(questionMediaURL).then(file => {
                        this.store.dispatch(new quizRunnerActions.SetQuestionFileSrc(file));
                    });
                }
                break;
            case MessageType.MESSAGE_TYPE_QUIZ_RESTART_RESPONSE:
                break;
            case MessageType.MESSAGE_TYPE_QUIZ_FINISH_RESPONSE:
                break;
            case MessageType.MESSAGE_TYPE_ERROR_SERVER:
                console.log(response.getErrorservermessage().getErrormessage());
                break;
        }

        console.log('Handle message with type: ', messageType);

    }

    public loadMedia(questionMediaURL: string) {
        if (questionMediaURL && this.isHost) {
            this.fileManagerService.downloadFileBlob(questionMediaURL).then(file => {
                this.store.dispatch(new quizRunnerActions.SetQuestionFileSrc(file));
            });
        }
    }

    public answerRequest(questionNumber: number, answerType: number, answerNumbers: string) {
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_QUIZ_MOVE_REQUEST);
        const quizMoveMessageRequest = new QuizMoveMessageRequest();
        quizMoveMessageRequest.setRoomid(localStorage.getItem('roomId'));
        if (answerType === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED) {
            quizMoveMessageRequest.setAction(QuizAction.QUIZ_ACTION_BLOCK_BUTTON);
        } else {
            quizMoveMessageRequest.setAction(QuizAction.QUIZ_ACTION_ANSWER_QUESTION);
        }
        quizMoveMessageRequest.setQuestionnumber(questionNumber);
        quizMoveMessageRequest.setAnswer(answerNumbers);
        msg.setQuizmovemessagerequest(quizMoveMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public hostVerificationRequest(questionNumber: number, accept: boolean) {
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_QUIZ_MOVE_REQUEST);
        const quizMoveMessageRequest = new QuizMoveMessageRequest();
        quizMoveMessageRequest.setRoomid(localStorage.getItem('roomId'));
        quizMoveMessageRequest.setAction(QuizAction.QUIZ_ACTION_UNBLOCK_BUTTON);
        quizMoveMessageRequest.setQuestionnumber(questionNumber);
        quizMoveMessageRequest.setCorrectanswer(accept);
        msg.setQuizmovemessagerequest(quizMoveMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public startGameRequest() {
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_QUIZ_START_GAME_REQUEST);
        const quizStartGameMessageRequest = new QuizStartGameMessageRequest();
        console.log(localStorage.getItem('roomId'));
        console.log(Number(localStorage.getItem('roomId')));
        quizStartGameMessageRequest.setRoomid(localStorage.getItem('roomId'));
        msg.setQuizstartgamemessagerequest(quizStartGameMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public startQuestionRequest() {
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_QUIZ_MOVE_REQUEST);
        const quizMoveMessageRequest = new QuizMoveMessageRequest();
        quizMoveMessageRequest.setAction(QuizAction.QUIZ_ACTION_START_QUESTION);
        quizMoveMessageRequest.setRoomid(localStorage.getItem('roomId'));
        // quizMoveMessageRequest.setQuestionnumber(0);
        msg.setQuizmovemessagerequest(quizMoveMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public finishQuestionRequest() {
        const msg = this.createGeneralMessage();
        msg.setMessagetype(MessageType.MESSAGE_TYPE_QUIZ_MOVE_REQUEST);
        const quizMoveMessageRequest = new QuizMoveMessageRequest();
        quizMoveMessageRequest.setAction(QuizAction.QUIZ_ACTION_FINISH_QUESTION);
        quizMoveMessageRequest.setRoomid(localStorage.getItem('roomId'));
        msg.setQuizmovemessagerequest(quizMoveMessageRequest);
        this.ws.send(msg.serializeBinary());
    }

    public connectionCloseHandler() {
        console.log('Connection closed');
    }

    private createGeneralMessage(): GeneralMessage {
        const msg = new GeneralMessage();
        msg.setCookie(localStorage.getItem('sid'));
        if (localStorage.getItem('playableHostId') && localStorage.getItem('isHost') !== 'true') {
            msg.setConnectionid(localStorage.getItem('playableHostId'));
        }
        msg.setAppversion(environment.appVersion);
        return msg;
    }

    ngOnDestroy() {
        this.ws.close();
    }
}
