<div *ngIf="quizQuestion">
    <div *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE"
         [ngClass]="quizRunnerSocket.isMobile ? 'answer-wrapper-mobile' : 'answer-wrapper'">
        <div *ngFor="let answer of quizQuestion.getAnswersList(); let i = index"
             class="answer-button-wrapper no-highlight">
            <div (click)="answerClickHandler(answer.getNumber().toString())"
                 [ngClass]="{
                     answered: playerAnswer.includes(i.toString()) && quizRunnerSocket.isPlayer,
                     clickable: !isAnswered() && quizRunnerSocket.isPlayer,
                     correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect(),
                     wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())
                 }"
                 class="answer-button">
                <svg *ngIf="questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED || questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && !playerAnswer.includes(i.toString())"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle cx="43" cy="56" r="8" stroke="#A7B7B9" stroke-width="2"/>
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#B6BFC4"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#B6BFC4"/>
                </svg>
                <svg *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect()"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#00C4AE"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#00C4AE"/>
                    <circle cx="43" cy="56" r="9" fill="#00C4AE"/>
                </svg>
                <svg *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#FF4081"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#FF4081"/>
                    <circle cx="43" cy="56" r="9" fill="#FF4081"/>
                </svg>
<!--                {{ quizRunnerSocket.isHost ? (i + 1) + '. ' + answer.getValue() : (("GENERATION.ANSWER" | translate) + ' ' + (i + 1)) }}-->
                {{ (i + 1) + '. ' + answer.getValue() }}
            </div>
        </div>
    </div>
    <div *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI"
         [ngClass]="quizRunnerSocket.isMobile ? 'answer-wrapper-mobile' : 'answer-wrapper'">
        <div *ngFor="let answer of quizQuestion.getAnswersList(); let i = index"
             (click)="setCorrectAnswer(answer.getNumber())"
             class="answer-button-wrapper no-highlight">
            <div appearance="fill"
                 [ngClass]="{
                     answered: multiAnswersList[i] || playerAnswer.includes(i.toString()) && quizRunnerSocket.isPlayer,
                     clickable: !isAnswered() && quizRunnerSocket.isPlayer,
                     correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect(),
                     wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())
                 }"
                 class="answer-button">
                <!--<button *ngIf="!quizRunnerSocket.isHost && !isAnswered() && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                        matSuffix
                        mat-icon-button
                        aria-label="Check">
                    <mat-icon [ngClass]="multiAnswersList[i] ? 'green-icon' : 'gray-icon'">check_box</mat-icon>
                </button>fcc842-->
                <svg *ngIf="questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED || questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && !playerAnswer.includes(i.toString())"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle cx="43" cy="56" r="8" stroke="#A7B7B9" stroke-width="2"/>
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#B6BFC4"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#B6BFC4"/>
                </svg>
                <svg *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect()"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#00C4AE"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#00C4AE"/>
                    <circle cx="43" cy="56" r="9" fill="#00C4AE"/>
                </svg>
                <svg *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())"
                     xmlns="http://www.w3.org/2000/svg" width="107" height="118" viewBox="0 0 107 118" fill="none">
                    <circle opacity="0.1" cx="43" cy="59" r="64" fill="#FF4081"/>
                    <circle opacity="0.2" cx="42.5" cy="56.5" r="27.5" fill="#FF4081"/>
                    <circle cx="43" cy="56" r="9" fill="#FF4081"/>
                </svg>
<!--                <mat-label>{{ quizRunnerSocket.isHost ? (i + 1) + '. ' + answer.getValue() : (("GENERATION.ANSWER" | translate) + ' ' + (i + 1)) }}</mat-label>-->
                <mat-label>{{ (i + 1) + '. ' + answer.getValue() }}</mat-label>
                <!--                    <mat-label>{{ "GENERATION.ANSWER" | translate }} {{ i + 1 }}</mat-label>-->
                <!--                    <div *ngIf="quizRunnerSocket.isHost">{{ answer.getValue() }}</div>-->
            </div>
        </div>
        <button *ngIf="quizRunnerSocket.isPlayer && !isAnswered() && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                (click)="answerClickHandler(customOrMultiAnswer)"
                class="multi-answer-button">
            Ответить
            <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
        </button>
    </div>
    <!--        <div [ngClass]="{-->
    <!--            answered: isAnswered(),-->
    <!--            correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && ,-->
    <!--            wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())-->
    <!--        }">-->
    <mat-form-field *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_CUSTOM
                           && quizRunnerSocket.isPlayer
                           && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                    appearance="fill"
                    class="custom-answer">
        <mat-label>{{ "GENERATION.ANSWER" | translate }}</mat-label>
        <input matInput
               [disabled]="isAnswered()"
               (change)="changeCustomAnswer($event.target)">
        <button matSuffix
                mat-icon-button
                aria-label="Send"
                [disabled]="isAnswered()"
                (click)="answerClickHandler(customOrMultiAnswer)">
            <mat-icon class="mat-icon-rounded">send</mat-icon>
        </button>
    </mat-form-field>


    <!-- First answer button for answer -->
    <div *ngIf="(quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_SPEED
                || quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED)
                && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                && quizRunnerSocket.isPlayer"
         class="first-answer-button">
        <div (click)="answerClickHandler('')"
             class="neumorphic">
            <mat-icon class="mat-icon-rounded">touch_app</mat-icon>
        </div>
    </div>
    <!--<div *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && quizQuestion.getAnswerdescriptionvalue().length > 0"
         class="answer-text">
        {{ quizQuestion.getAnswerdescriptionvalue() }}
    </div>-->
</div>


<!--
<div *ngIf="quizQuestion">
    <div *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_ONE"
         class="answer-wrapper">
        <div *ngFor="let answer of quizQuestion.getAnswersList(); let i = index"
             class="answer-button-wrapper">
            <div (click)="answerClickHandler(answer.getNumber().toString())"
                 [ngClass]="{
                     answered: playerAnswer.includes(i.toString()) && !quizRunnerSocket.isHost,
                     clickable: !isAnswered() && !quizRunnerSocket.isHost,
                     correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect(),
                     wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())
                 }"
                 class="answer-button">
                {{ quizRunnerSocket.isHost ? (i + 1) + '. ' + answer.getValue() : (("GENERATION.ANSWER" | translate) + ' ' + (i + 1)) }}
            </div>
        </div>
    </div>
    <div *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_MULTI"
         class="answer-wrapper">
        <div *ngFor="let answer of quizQuestion.getAnswersList(); let i = index"
             (click)="setCorrectAnswer(answer.getNumber())"
             class="answer-button-wrapper">
            <div appearance="fill"
                 [ngClass]="{
                     answered: playerAnswer.includes(i.toString()) && !quizRunnerSocket.isHost,
                     clickable: !isAnswered() && !quizRunnerSocket.isHost,
                     correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && answer.getCorrect(),
                     wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())
                 }"
                 class="answer-button">
                <button *ngIf="!quizRunnerSocket.isHost && !isAnswered() && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                        matSuffix
                        mat-icon-button
                        aria-label="Check">
                    <mat-icon [ngClass]="multiAnswersList[i] ? 'green-icon' : 'gray-icon'">check_box</mat-icon>
                </button>
                <mat-label>{{ quizRunnerSocket.isHost ? (i + 1) + '. ' + answer.getValue() : (("GENERATION.ANSWER" | translate) + ' ' + (i + 1)) }}</mat-label>
&lt;!&ndash;                    <mat-label>{{ "GENERATION.ANSWER" | translate }} {{ i + 1 }}</mat-label>&ndash;&gt;
&lt;!&ndash;                    <div *ngIf="quizRunnerSocket.isHost">{{ answer.getValue() }}</div>&ndash;&gt;
            </div>
        </div>
        <button *ngIf="!quizRunnerSocket.isHost && !isAnswered() && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                color="primary"
                (click)="answerClickHandler(customOrMultiAnswer)"
                mat-raised-button
                class="multi-answer-button">
            Ответить
        </button>
    </div>
&lt;!&ndash;        <div [ngClass]="{&ndash;&gt;
&lt;!&ndash;            answered: isAnswered(),&ndash;&gt;
&lt;!&ndash;            correct: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && ,&ndash;&gt;
&lt;!&ndash;            wrong: questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && !answer.getCorrect() && playerAnswer.includes(i.toString())&ndash;&gt;
&lt;!&ndash;        }">&ndash;&gt;
    <mat-form-field *ngIf="quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_CUSTOM
                           && !quizRunnerSocket.isHost
                           && questionStatus !== QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED"
                    appearance="fill"
                    class="custom-answer">
        <mat-label>{{ "GENERATION.ANSWER" | translate }}</mat-label>
        <input matInput
               [disabled]="isAnswered()"
               (change)="changeCustomAnswer($event.target)">
        <button matSuffix
                mat-icon-button
                aria-label="Send"
                [disabled]="isAnswered()"
                (click)="answerClickHandler(customOrMultiAnswer)">
            <mat-icon class="mat-icon-rounded">send</mat-icon>
        </button>
    </mat-form-field>


    &lt;!&ndash; First answer button for answer &ndash;&gt;
    <div *ngIf="(quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_SPEED
                || quizQuestion.getAnswertype() === QuizQuestionAnswerType.QUIZ_QUESTION_ANSWER_TYPE_HOSTED)
                && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                && !quizRunnerSocket.isHost"
         class="first-answer-button">
        <div (click)="answerClickHandler('')"
             class="neumorphic">
            <mat-icon class="mat-icon-rounded">touch_app</mat-icon>
        </div>
    </div>
    &lt;!&ndash;<div *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && quizQuestion.getAnswerdescriptionvalue().length > 0"
         class="answer-text">
        {{ quizQuestion.getAnswerdescriptionvalue() }}
    </div>&ndash;&gt;
    &lt;!&ndash; First answered player and accepting/rejecting buttons &ndash;&gt;
    <div *ngIf="quizRunnerSocket.isHost && questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED"
         class="first-answered-player-wrapper">
        <button mat-fab
                color="primary"
                (click)="hostVerificationClickHandler(true)">
            <mat-icon class="mat-icon-rounded">check</mat-icon>
        </button>
        <div *ngIf="firstAnsweredPlayer"
                class="player-information">
            <img [src]="firstAnsweredPlayer.getAvatarurl()"
                 class="avatar-image">
            <div [innerHTML]="firstAnsweredPlayer.getNickname()"></div>
        </div>
        <button mat-fab
                (click)="hostVerificationClickHandler(false)">
            <mat-icon class="mat-icon-rounded">close</mat-icon>
        </button>
    </div>
</div>
-->
