<form [formGroup]="answerDescriptionFormGroup">
    <div class="question-type">
        <div [ngClass]="{ 'selected': selectedQuestion.answerDescriptionType === questionTypes.TEXT }"
             (click)="changeSelectedAnswerDescriptionType(questionTypes.TEXT)">
            <mat-icon class="mat-icon-rounded">edit_note</mat-icon>
            <div>{{ 'GENERATION.QUESTION_TYPE_TEXT' | translate }}</div>
        </div>
        <div [ngClass]="{ 'selected': selectedQuestion.answerDescriptionType === questionTypes.IMAGE }"
             (click)="changeSelectedAnswerDescriptionType(questionTypes.IMAGE)">
            <mat-icon class="mat-icon-rounded">image</mat-icon>
            <div>{{ 'GENERATION.QUESTION_TYPE_IMAGE' | translate }}</div>
        </div>
        <div [ngClass]="{ 'selected': selectedQuestion.answerDescriptionType === questionTypes.VIDEO }"
             (click)="changeSelectedAnswerDescriptionType(questionTypes.VIDEO)">
            <mat-icon class="mat-icon-rounded">smart_display</mat-icon>
            <div>{{ 'GENERATION.QUESTION_TYPE_VIDEO' | translate }}</div>
        </div>
        <div [ngClass]="{ 'selected': selectedQuestion.answerDescriptionType === questionTypes.AUDIO }"
             (click)="changeSelectedAnswerDescriptionType(questionTypes.AUDIO)">
            <mat-icon class="mat-icon-rounded">music_note</mat-icon>
            <div>{{ 'GENERATION.QUESTION_TYPE_AUDIO' | translate }}</div>
        </div>
    </div>
    <!--<mat-form-field appearance="fill">
        <mat-label>{{ "GENERATION.ANSWER_DESCRIPTION_TYPE" | translate }}</mat-label>
        <mat-select [value]="selectedQuestion.answerDescriptionType"
                    (valueChange)="changeSelectedAnswerDescriptionType($event)">
            <mat-option [value]="QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_TEXT">{{ "GENERATION.QUESTION_TYPE_TEXT" | translate }}</mat-option>
            <mat-option [value]="QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_IMAGE">
                {{ "GENERATION.QUESTION_TYPE_IMAGE" | translate }}
            </mat-option>
            <mat-option [value]="QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_VIDEO">
                {{ "GENERATION.QUESTION_TYPE_VIDEO" | translate }}
            </mat-option>
            <mat-option [value]="QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO">
                {{ "GENERATION.QUESTION_TYPE_AUDIO" | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>-->
    <mat-form-field appearance="fill"
                    class="question">
        <mat-label>{{ "GENERATION.ANSWER_DESCRIPTION_TEXT" | translate }}</mat-label>
        <input id="answerDescriptionTextInput"
               matInput
               formControlName="answerDescriptionValueControl"
               (change)="answerDescriptionValueChangeHandler($event.target)">
    </mat-form-field>
    <div *ngIf="selectedQuestion.answerDescriptionType === questionTypes.IMAGE
                || selectedQuestion.answerDescriptionType === questionTypes.VIDEO
                || selectedQuestion.answerDescriptionType === questionTypes.AUDIO">
        <div class="form-group">
            <label for="file">{{ "GENERATION.ANSWER_DESCRIPTION_FILE" | translate }}</label>
            <br>
            <input formControlName="file"
                   id="file"
                   type="file"
                   class="form-control"
                   [accept]="selectedQuestion.answerDescriptionType === questionTypes.IMAGE ? '.jpg,.png' :
                   selectedQuestion.answerDescriptionType === questionTypes.VIDEO ? '.mp4' : '.mp3,.wav'"
                   (change)="onFileChange($event)">
            <div *ngIf="answerDescriptionFormGroup.controls.file.touched && answerDescriptionFormGroup.controls.file.invalid" class="alert alert-danger">
                <div *ngIf="answerDescriptionFormGroup.controls.file.errors.required">File is required.</div>
            </div>
        </div>

        <button *ngIf="selectedQuestion.answerDescriptionType === questionTypes.AUDIO && initialAudioFile"
                mat-raised-button
                (click)="setInitialAudioFile()">
            Use initial file from question
        </button>

        <button *ngIf="selectedQuestion.answerDescriptionType === questionTypes.AUDIO && croppedAudioFile"
                mat-raised-button
                (click)="setCroppedAudioFile()">
            Use cropped file from question
        </button>

        <img [src]="fileSrc" *ngIf="fileSrc && selectedQuestion.answerDescriptionType === questionTypes.IMAGE" style="height: 300px; width:500px">
        <video [src]="fileSrc" *ngIf="fileSrc && selectedQuestion.answerDescriptionType === questionTypes.VIDEO" height="200" controls></video>
        <app-audio-player *ngIf="audioBuffers && audioShape && selectedQuestion.answerDescriptionType === questionTypes.AUDIO"
                          [audioBuffers]="audioBuffers"
                          [audioShape]="audioShape"
                          [shouldRewind]="true"
                          [loop]="true"
                          [autoPlay]="false"
                          [playerKey]="'answerDescription'">
        </app-audio-player>
        <!--<app-audio-editor
                *ngIf="file && selectedQuestion.answerDescriptionType === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO"
                [sourceAudioFileSetter]="file"
                [activePlayerKey]="'answerDescriptionEditor'"
                (sourceAudioFileSetterChange)="updateAnswerDescriptionFile($event)">
        </app-audio-editor>-->
        <br/>
        <button mat-raised-button color="accent" *ngIf="file" (click)="clearFile()">CLEAR FILE</button>
    </div>
</form>
