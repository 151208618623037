import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import * as auth from 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat';
// import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// import { getAuth, linkWithPopup } from '@angular/fire/auth';
import { getAuth, linkWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
// import firebase from 'firebase/compat/app';
// import auth = firebase.auth;
// import { auth } from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public authSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public authErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public checkAuthSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public nickname: string;
    private userData: any;
    private token: string;

    constructor(
        public angularFireAuth: AngularFireAuth,
        private httpClient: HttpClient
    ) {
        // console.log('CHECK SESSION');
        this.checkSession();
        this.authSubject.subscribe(isAuth => {
            if (isAuth) {
                this.checkAdminAccess();
            }
        });
        /*angularFireAuth.authState.subscribe(user => {
            console.log('USER 1', user);
            if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                this.checkSession();
            } else {
                this.checkAuthSubject.next(false);
                localStorage.setItem('user', null);
            }
        });*/
    }

    public authWithGoogle() {
        const provider = this.createGoogleProvider();
        this.authLogin(provider);
    }

    public authWithFacebook() {
        const provider = new FacebookAuthProvider();
        provider.addScope('public_profile');
        provider.addScope('email');
        this.authLogin(provider);
    }

    private createGoogleProvider() {
        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        return provider;
    }

    public linkWithGoogle(nicknameToSet: string) {
        const provider = this.createGoogleProvider();
        this.postAnonRegister(provider, nicknameToSet);
    }

    public authAnon() {
        this.angularFireAuth.signInAnonymously()
            .then(result => {
                console.log('Anon auth uid: ' + result.user.uid);
                result.user.getIdToken()
                    .then(token => {
                        this.httpClient.post<{ cookie: string }>(
                            environment.URLPrefix + '/auth/register',
                            {
                                nickname: this.nickname
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }
                        ).subscribe(ans => {
                            console.log('AUTH ANON RESPONSE', ans);
                            if (ans.cookie) {
                                localStorage.setItem('sid', ans.cookie);
                                this.authSubject.next(true);
                            } else {
                                console.log('Sign in anon response does not contain sid');
                                this.authSubject.error('Sign in anon response does not contain sid');
                            }
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                this.authSubject.error('Sign in anon error');
            });
    }

    private postAnonRegister(provider: firebase.auth.AuthProvider, nicknameToSet: string) {
        const auth = getAuth();
        linkWithPopup(auth.currentUser, provider)
            .then(result => {
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                const user = result.user;
                console.log('Link uid: ' + user.uid);
                user.getIdToken()
                    .then(token => {
                        this.httpClient.post<{ cookie: string }>(
                            environment.URLPrefix + '/auth/linkAccount',
                            {
                                nickname: nicknameToSet
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }
                        ).subscribe(ans => {
                            console.log('LINK ACCOUNT RESPONSE', ans);
                            if (ans.cookie) {
                                localStorage.setItem('sid', ans.cookie);
                                console.log('Link account cookie was set');
                                this.authSubject.next(true);
                            } else {
                                localStorage.removeItem('sid');
                                console.log('Link account response does not contain sid');
                                this.authSubject.error('Link account response does not contain sid');
                            }
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                this.authSubject.error('Link account error');
            });
        /*
        firebase.auth().currentUser.linkWithPopup(provider)
            .then(result => {
                result.user.getIdToken()
                    .then(token => {
                        this.httpClient.post<{ cookie: string }>(
                            environment.URLPrefix + '/auth/link',
                            {
                                nickname: this.nickname
                            },
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }
                        ).subscribe(ans => {
                            console.log('LINK RESPONSE', ans);
                            if (ans.cookie) {
                                localStorage.setItem('sid', ans.cookie);
                                this.authSubject.next(true);
                            } else {
                                console.log('Link response does not contain sid');
                                this.authSubject.error('Link response does not contain sid');
                            }
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                this.authSubject.error('Link with popup error');
            });
         */
    }

    private authLogin(provider: firebase.auth.AuthProvider) {
        this.angularFireAuth.signInWithPopup(provider)
            .then(result => {
                result.user.getIdToken()
                    .then(token => {
                        this.token = token;
                        this.httpClient.post<{ cookie: string }>(
                            environment.URLPrefix + '/auth/login',
                            {},
                            {
                                headers: {
                                    Authorization: 'Bearer ' + token
                                }
                            }
                        ).subscribe(
                            ans => {
                                // console.log('AUTH RESULT ', ans);
                                console.log('AUTH RESPONSE', ans);
                                if (ans.cookie) {
                                    localStorage.setItem('sid', ans.cookie);
                                    this.authSubject.next(true);
                                } else {
                                    console.log('Sign in response not contains sid');
                                    this.authSubject.error('Sign in response not contains sid');
                                }
                            },
                            error => {
                                this.authErrorSubject.next(error.error.errorCode);
                            }
                        );
                    });
            })
            .catch(error => {
                console.log(error);
                this.authSubject.error('Sign in with popup error');
            });
    }

    public finishRegistration(nickname: string) {
        if (!this.token) {
            return;
        }
        this.httpClient.post<{ cookie: string }>(
            environment.URLPrefix + '/auth/register',
            {
                nickname
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }
        ).subscribe(
            ans => {
                // console.log('AUTH RESULT ', ans);
                console.log('AUTH RESPONSE', ans);
                if (ans.cookie) {
                    localStorage.setItem('sid', ans.cookie);
                    this.authSubject.next(true);
                } else {
                    console.log('Sign in response not contains sid');
                    this.authSubject.error('Sign in response not contains sid');
                }
            },
            error => {
                this.authErrorSubject.next(error.error.errorCode);
            }
        );
    }

    public signOut(): Observable<{ result: boolean }> {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/auth/logout',
            {}
        );
    }

    private checkSession() {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/auth/checkCookie',
            {}
        ).subscribe(
            ans => {
                console.log(ans);
                if (ans.result) {
                    this.authSubject.next(true);
                } else {
                    this.checkAuthSubject.next(false);
                    // this.authSubject.error('Check session answer error');
                    console.log('Check session answer error');
                }
            },
            error => {
                this.checkAuthSubject.next(false);
                // this.authSubject.error('Check session error');
                console.log('Check session error', error);
            }
        );
    }

    private checkAdminAccess() {
        return this.httpClient.post<{result: boolean}>(
            environment.URLPrefix + '/auth/checkCookie',
            {},
            {
                params: {
                    admin: true
                }
            }
        ).subscribe(
            ans => {
                console.log('CHECK MODERATOR:', ans);
                if (ans.result) {
                    this.isAdminSubject.next(true);
                } else {
                    console.log('Check moderator answer error');
                }
                this.checkAuthSubject.next(false);
            },
            error => {
                console.log('Check moderator error', error);
            }
        );
    }
}
