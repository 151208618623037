import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { QuizInfo, QuizQuestionInfo } from '../quiz-constructor.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/app.state';
import { selectedQuizSelector } from '../store/quiz-constructor.selectors';
import * as quizConstructorActions from '../store/quiz-constructor.actions';

@Component({
    selector: 'app-quiz-editor',
    templateUrl: './quiz-editor.component.html',
    styleUrls: ['./quiz-editor.component.scss']
})
export class QuizEditorComponent implements OnInit, OnDestroy {

    private selectedQuiz$: Observable<QuizInfo>;
    private quizInfoS: Subscription;
    public selectedQuiz: QuizInfo;
    public selectedQuestion: QuizQuestionInfo;

    constructor(
        private router: Router,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        const shareLinkStartIndex = this.router.url.indexOf('?');
        if (shareLinkStartIndex !== -1) {
            const shareLink = this.router.url.slice(shareLinkStartIndex + 1);
            this.store.dispatch(new quizConstructorActions.GetSharedQuiz(shareLink));
        }

        this.selectedQuiz$ = this.store.pipe(
            select(selectedQuizSelector)
        );
        this.quizInfoS = this.selectedQuiz$.subscribe(selectedQuiz => {
            this.selectedQuiz = selectedQuiz;
            if (this.selectedQuiz.questions.length) {
                this.editQuestion(this.selectedQuiz.questions[0]);
            }
        });
    }

    createQuizQuestion() {
        // this.router.navigate(['/question-generation']);
        this.selectedQuestion = null;
        this.store.dispatch(new quizConstructorActions.SetSelectedQuestion(null));
    }

    ngOnDestroy() {
        this.quizInfoS.unsubscribe();
    }

    editQuestion(quizQuestionInfo: QuizQuestionInfo) {
        this.selectedQuestion = quizQuestionInfo;
        this.store.dispatch(new quizConstructorActions.SetSelectedQuestion(quizQuestionInfo));
        /*this.router.navigate(['/question-generation'], {
            state: { quizQuestionInfo: JSON.stringify(quizQuestionInfo) }
        });*/
    }

    removeQuestion(questionId: string) {
        this.store.dispatch(new quizConstructorActions.DeleteQuestion(questionId));
    }

    backToQuizList() {
        this.router.navigate(['quiz-list']);
    }
}
