<div class="overlay" *ngIf="loading">
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="header">{{ "GENERATION.CREATING_QUESTION" | translate }}</div>

<div class="constructor">
    <app-quiz-editor *ngIf="!questionTemplate"></app-quiz-editor>
    <div class="question-constructor">
        <!--<div class="block-header">{{ "GENERATION.QUESTION_TYPE" | translate }}</div>-->
        <div class="block-header">{{ "GENERATION.QUESTION_SETTINGS" | translate }}</div>
        <!--<div class="question-type">
            <div [ngClass]="{ 'selected': selectedQuestion.type === questionTypes.TEXT }"
                 (click)="changeSelectedQuestionType(questionTypes.TEXT)">
                <mat-icon class="mat-icon-rounded">edit_note</mat-icon>
                <div>{{ 'GENERATION.QUESTION_TYPE_TEXT' | translate }}</div>
            </div>
            <div [ngClass]="{ 'selected': selectedQuestion.type === questionTypes.IMAGE }"
                 (click)="changeSelectedQuestionType(questionTypes.IMAGE)">
                <mat-icon class="mat-icon-rounded">image</mat-icon>
                <div>{{ 'GENERATION.QUESTION_TYPE_IMAGE' | translate }}</div>
            </div>
            <div [ngClass]="{ 'selected': selectedQuestion.type === questionTypes.VIDEO }"
                 (click)="changeSelectedQuestionType(questionTypes.VIDEO)">
                <mat-icon class="mat-icon-rounded">smart_display</mat-icon>
                <div>{{ 'GENERATION.QUESTION_TYPE_VIDEO' | translate }}</div>
            </div>
            <div [ngClass]="{ 'selected': selectedQuestion.type === questionTypes.AUDIO }"
                 (click)="changeSelectedQuestionType(questionTypes.AUDIO)">
                <mat-icon class="mat-icon-rounded">music_note</mat-icon>
                <div>{{ 'GENERATION.QUESTION_TYPE_AUDIO' | translate }}</div>
            </div>
        </div>-->

        <!--<div class="block-header">{{ "GENERATION.QUESTION_SETTINGS" | translate }}</div>-->
        <app-question-constructor (fileChanged)="fileChangedHandler($event)"></app-question-constructor>

        <!--<div class="block-header">{{ "GENERATION.ANSWER_TYPE" | translate }}</div>-->
        <div class="block-header">{{ "GENERATION.ANSWER_SETTINGS" | translate }}</div>
        <div class="question-type">
            <div [ngClass]="{ 'selected': selectedQuestion.answerType === answerTypes.ONE }"
                 (click)="changeSelectedAnswerType(answerTypes.ONE)">
                <mat-icon class="mat-icon-rounded">done</mat-icon>
                <div>{{ 'GENERATION.ONE_ANSWER' | translate }}</div>
            </div>
            <div [ngClass]="{ 'selected': selectedQuestion.answerType === answerTypes.MULTI }"
                 (click)="changeSelectedAnswerType(answerTypes.MULTI)">
                <mat-icon class="mat-icon-rounded">done_all</mat-icon>
                <div>{{ 'GENERATION.MULTIPLE_ANSWER' | translate }}</div>
            </div>
            <!--<div [ngClass]="{ 'selected': selectedQuestion.answerType === answerTypes.SPEED }"
                 (click)="changeSelectedAnswerType(answerTypes.SPEED)">
                <mat-icon class="mat-icon-rounded">smart_display</mat-icon>
                <div>{{ 'GENERATION.WHO_IS_FIRST' | translate }}</div>
            </div>-->
            <div [ngClass]="{ 'selected': selectedQuestion.answerType === answerTypes.HOSTED || selectedQuestion.answerType === answerTypes.SPEED }"
                 (click)="changeSelectedAnswerType(answerTypes.HOSTED)">
                <mat-icon class="mat-icon-rounded">speed</mat-icon>
                <div>{{ 'GENERATION.HOSTED' | translate }}</div>
            </div>
            <div [ngClass]="{ 'selected': selectedQuestion.answerType === answerTypes.CUSTOM }"
                 (click)="changeSelectedAnswerType(answerTypes.CUSTOM)">
                <mat-icon class="mat-icon-rounded">edit</mat-icon>
                <div>{{ 'GENERATION.CUSTOM_INPUT' | translate }}</div>
            </div>
        </div>

        <!--<div class="block-header">{{ "GENERATION.ANSWER_SETTINGS" | translate }}</div>-->
        <app-answer-constructor></app-answer-constructor>

        <div class="block-header">{{ "GENERATION.SHOWING_ANSWER" | translate }}</div>
        <app-answer-description-constructor (fileChanged)="answerDescriptionFileChangedHandler($event)"></app-answer-description-constructor>

        <button mat-raised-button
                (click)="saveQuestion()"
                color="primary">
            Save
        </button>
    </div>
</div>
<!--<mat-vertical-stepper [linear]="true" *ngIf="selectedQuestion">
    &lt;!&ndash;<mat-step>
        <form>
            <ng-template matStepLabel style="'background-color: black;'">{{ "GENERATION.QUESTION_TYPE" | translate }}</ng-template>
            &lt;!&ndash;<div class="buttons">
                <div (click)="changeSelectedType(questionTypes.TEXT)">Text</div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.IMAGE)">
                    Image
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.VIDEO)">
                    Video
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedType(questionTypes.AUDIO)">
                    Audio
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
            </div>&ndash;&gt;
            <mat-form-field appearance="fill">
                <mat-label>{{ "GENERATION.QUESTION_TYPE" | translate }}</mat-label>
                <mat-select [value]="selectedQuestion.type"
                            (valueChange)="changeSelectedQuestionType($event)">
                    <mat-option [value]="questionTypes.TEXT">{{ "GENERATION.QUESTION_TYPE_TEXT" | translate }}</mat-option>
                    <mat-option [value]="questionTypes.IMAGE" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_IMAGE" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                    <mat-option [value]="questionTypes.VIDEO" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_VIDEO" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                    <mat-option [value]="questionTypes.AUDIO" [disabled]="!premium">
                        {{ "GENERATION.QUESTION_TYPE_AUDIO" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>&ndash;&gt;
    &lt;!&ndash;<mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.QUESTION_SETTINGS" | translate }}</ng-template>
            <app-question-constructor (fileChanged)="fileChangedHandler($event)"></app-question-constructor>
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>&ndash;&gt;
    &lt;!&ndash;<mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.ANSWER_TYPE" | translate }}</ng-template>
            <mat-form-field appearance="fill">
                <mat-label>{{ "GENERATION.ANSWER_TYPE" | translate }}</mat-label>
                <mat-select [value]="selectedQuestion.answerType"
                            (valueChange)="changeSelectedAnswerType($event)">
                    <mat-option [value]="answerTypes.ONE">{{ "GENERATION.ONE_ANSWER" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.MULTI">{{ "GENERATION.MULTIPLE_ANSWER" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.SPEED">{{ "GENERATION.WHO_IS_FIRST" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.HOSTED">{{ "GENERATION.HOSTED" | translate }}</mat-option>
                    <mat-option [value]="answerTypes.CUSTOM" [disabled]="!premium">
                        {{ "GENERATION.CUSTOM_INPUT" | translate }}
                        <mat-icon *ngIf="!premium">loyalty</mat-icon>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            &lt;!&ndash;<div class="buttons">
                <div (click)="changeSelectedAnswerType(answerTypes.ONE)">One correct</div>
                <div (click)="changeSelectedAnswerType(answerTypes.MULTI)">Multi</div>
                <div (click)="changeSelectedAnswerType(answerTypes.SPEED)">Speed answer</div>
                <div [ngClass]="premium ? '' : 'disabled'"
                     (click)="changeSelectedAnswerType(answerTypes.CUSTOM)">
                    Custom input
                    <mat-icon *ngIf="!premium">loyalty</mat-icon>
                </div>
            </div>&ndash;&gt;
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>&ndash;&gt;
    &lt;!&ndash;<mat-step>
        <form>
            <ng-template matStepLabel>{{ "GENERATION.ANSWER_SETTINGS" | translate }}</ng-template>
            <app-answer-constructor></app-answer-constructor>
            <div>
                <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
                <button mat-button matStepperNext>{{ "GENERATION.NEXT" | translate }}</button>
            </div>
        </form>
    </mat-step>&ndash;&gt;
    &lt;!&ndash;<mat-step>
        <ng-template matStepLabel>{{ "GENERATION.SHOWING_ANSWER" | translate }}</ng-template>
        <app-answer-description-constructor (fileChanged)="answerDescriptionFileChangedHandler($event)"></app-answer-description-constructor>
        <button (click)="saveQuestion()">Save</button>

        <div>
            <button mat-button matStepperPrevious>{{ "GENERATION.BACK" | translate }}</button>
            &lt;!&ndash;            <button mat-button (click)="stepper.reset()">Reset</button>&ndash;&gt;
        </div>
    </mat-step>&ndash;&gt;
</mat-vertical-stepper>-->

<!--
<div class="header">Creating question</div>
<div class="buttons">
    <div (click)="changeSelectedType(questionTypes.TEXT)">Text</div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.IMAGE)">
        Image
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.VIDEO)">
        Video
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
    <div [ngClass]="premium ? '' : 'disabled'"
         (click)="changeSelectedType(questionTypes.AUDIO)">
        Audio
        <mat-icon *ngIf="!premium">loyalty</mat-icon>
    </div>
</div>
<app-answer-constructor *ngIf="selectedQuestionType === questionTypes.TEXT"></app-answer-constructor>
-->
<!--<input formControlName="file"
       id="file"
       type="file"
       class="form-control"
       [accept]="selectedQuestion.type === questionTypes.IMAGE ? '.jpg,.png' :
                   selectedQuestion.type === questionTypes.VIDEO ? '.mp4' : '.mp3,.wav'"
       (change)="onFileChange($event)">
<div *ngIf="audioBuffers"
     class="track-line">
    <app-audio-player [audioBuffers]="audioBuffers"
                      [audioShape]="audioShape"
                      [(activePlayerKey)]="activePlayerKey"
                      [shouldRewind]="false"
    [loop]="true">
    </app-audio-player>
    &lt;!&ndash;        <app-wave-line [waveformCoordinates]="waveformCoordinates"></app-wave-line>&ndash;&gt;
</div>-->
