import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
    selector: 'app-beta-access-share',
    templateUrl: './beta-access-share.component.html',
    styleUrls: ['./beta-access-share.component.scss']
})
export class BetaAccessShareComponent {

    constructor(
        public dialogRef: MatDialogRef<BetaAccessShareComponent>,
        private gtmService: GoogleTagManagerService
    ) { }

    closeDialog() {
        this.dialogRef.close();
    }

    sendShareTag(social: string) {
        const gtmTag = {
            event: 'button-click',
            data: social + '-share-button'
        };
        this.gtmService.pushTag(gtmTag);
    }
}
