import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { QuizInfo } from '../quiz-constructor/quiz-constructor.service';
import { QuizzesService } from './quizzes.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-quizzes',
    templateUrl: './quizzes.component.html',
    styleUrls: ['./quizzes.component.scss']
})
export class QuizzesComponent implements OnInit {

    public allQuizzes: Observable<Array<QuizInfo>>;

    constructor(
        private quizzesService: QuizzesService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.allQuizzes = this.quizzesService.getAllQuizzes();
    }

    runQuiz(quizId: string) {
        localStorage.setItem('game', 'quiz');
        localStorage.setItem('quizId', quizId);
        this.router.navigate(['/room-manager'], { queryParams: { action: 'run' } });
    }
}
