// export const SAMPLE_LENGTH = 40;

export function convertAudioBufferToShape(
    buffer: Float32Array,
    isExtractedAmplitude = false
) {
    const SAMPLE_LENGTH = 40;
    const sampleSize = Math.floor(buffer.length / SAMPLE_LENGTH);

    const shape = new Array(SAMPLE_LENGTH).fill(0).map((_, index) => {
        const sample = buffer.slice(index * sampleSize, (index + 1) * sampleSize);
        const meanSquares = sample
            .map(num => Math.pow(num, 2))
            .reduce((mean, num) => mean + num, 0);

        return Math.sqrt(meanSquares);
    });

    if (isExtractedAmplitude) {
        // Extracted amplitude will have steep drops at the beginning and
        // end of the buffer. For a prettier shape, we replace those areas
        // with average values.
        const averageValue = shape.reduce((a, b) => a + b) / shape.length;
        const replaceLength = Math.round(SAMPLE_LENGTH * .05);
        const replaceValues = new Array(replaceLength).fill(averageValue);
        shape.splice(0, replaceLength, ...replaceValues);
        shape.splice(-replaceLength, replaceLength, ...replaceValues);
    }

    const domain = [Math.max(...shape), Math.min(...shape)];
    const range = [-1, 1];

    return shape.map(value => {
        const percent = (value - domain[0]) / (domain[1] - domain[0]);
        let normalizedValue = percent * (range[1] - range[0]) + range[0];
        // If the shape was generated from the extracted audio amplitude,
        // the values are inverted compared to those pulled from the
        // .getChannelData() method.
        if (isExtractedAmplitude) {
            normalizedValue = 0 - normalizedValue;
        }
        return normalizedValue;
    });
}
