import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QuizRunnerService } from '../quiz-runner.service';
import * as actions from './quiz-runner.actions';
import { filter, map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { FileManagerService } from '../../../libs/file-manager/file-manager.service';

@Injectable()
export class QuizRunnerEffects {

    constructor(
        private actions$: Actions,
        private quizRunnerService: QuizRunnerService,
        private fileManagerService: FileManagerService
    ) { }

    getFileSrcForQuestion$ = createEffect(() => this.actions$.pipe(
        ofType(actions.QuizRunnerActionType.SetQuestion),
        filter((action: actions.SetQuestion) => this.quizRunnerService.isMediaQuestion(action.quizQuestion.getType())),
        mergeMap((action: actions.SetQuestion) => from(this.fileManagerService.downloadFileBlob(action.quizQuestion.getMedia()))),
        map((file: Blob) => new actions.SetQuestionFileSrc(file))
    ));

    // getAllQuizzes$ = createEffect(() => this.actions$.pipe(
    // ofType(actions.QuizConstructorActionType.GetAllQuizzes),
    // mergeMap(() => from(this.quizConstructorService.getAllQuizzes())),
    // map((data: Array<QuizInfo>) => new actions.GetAllQuizzesSuccess(data))
    // ));
}
