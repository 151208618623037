import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { convertAudioBufferToShape } from '../../libs/player/audio-buffert-shape-converter';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app/app.state';
import * as audioEditorActions from './store/audio-editor.actions';
import { UnsubscribeSubjectComponent } from '../../libs/unsubscribe-subject-component';
import { filter, takeUntil } from 'rxjs/operators';
import { selectedFileSelector } from './store/audio-editor.selectors';

@Component({
    selector: 'app-audio-editor',
    templateUrl: './audio-editor.component.html',
    styleUrls: ['./audio-editor.component.scss']
})
export class AudioEditorComponent extends UnsubscribeSubjectComponent implements OnInit {

    @ViewChild('shortAudio', {static: true})
    public shortAudioRef: ElementRef;

    @Input()
    public activePlayerKey = '';
    @Input()
    public set sourceAudioFileSetter(file: Blob) {
        console.log('New file loaded to audio editor');
        /*if (this.sourceAudioBuffer) {
            return;
        }*/
        this.processing = true;
        this.sourceAudioFile = file;
        // Convert blob into ArrayBuffer
        new Response(file).arrayBuffer().then(arrayBuffer => {
            const audioContext = new AudioContext();
            // Convert ArrayBuffer into AudioBuffer
            audioContext.decodeAudioData(arrayBuffer).then(audioBuffer => {
                this.sourceAudioBuffer = audioBuffer;
                this.audioLength = this.sourceAudioBuffer.duration;
                this.startCutValue = 0;
                this.endCutValue = this.audioLength;

                this.audioBuffers = new Array<Float32Array>(this.sourceAudioBuffer.numberOfChannels);
                for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
                    this.audioBuffers[i] = this.sourceAudioBuffer.getChannelData(i);
                }

                this.audioShape = convertAudioBufferToShape(audioBuffer.getChannelData(0), true);
                const yOffset = 1;
                this.waveformCoordinates = this.audioShape.map((y, index) => {
                    // Shift the y value 50% so that a 0 position is in the middle.
                    return [(100 / (this.audioShape.length - 1)) * index, y + yOffset];
                });
                this.sourceAudioFileSetterChange.next(file);
                this.processing = false;
                this.cdr.detectChanges();
            });
        });
    }
    @Output() public sourceAudioFileSetterChange: EventEmitter<Blob> = new EventEmitter<Blob>();

    public processing = false;
    public sourceAudioFile: Blob;
    public sourceAudioBuffer: AudioBuffer;
    public audioLength: number;
    public startCutValue: number;
    public endCutValue: number;
    // public shortAudioFile: Blob;
    // public shortAudioFileSrc: string;
    private reader = new FileReader();

    public audioBuffers: Array<Float32Array>;
    public audioShape: number[];
    public waveformCoordinates: number[][];

    public loading = false;

    constructor(
        private httpClient: HttpClient,
        public domSanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private store: Store<AppState>
    ) {
        super();
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(selectedFileSelector),
            filter(file => file !== null)
        ).subscribe(file => {
            console.log(file);
            this.sourceAudioFileSetter = file;
        });
    }

    public cropFile() {
        this.processing = true;
        this.crop();
    }

    generateRandomCoordinates = () => {
        // const segmentWidth = Math.round(100 / SAMPLE_LENGTH);
        const segmentWidth = Math.round(100 / 40);
        const paths = [];
        // for (let i = 0; i < SAMPLE_LENGTH; i++) {
        for (let i = 0; i < 40; i++) {
            paths.push([segmentWidth * i, this.generateRandomYCoordinate()]);
        }

        return paths;
    }

    generateRandomYCoordinate = () => Math.random() / 4 + 0.75;

    public crop() {
        // const worker = new Worker('audio-editor.js');

        // Compute start and end values in seconds
        const computedStart = this.sourceAudioBuffer.length * this.startCutValue / this.sourceAudioBuffer.duration;
        const computedEnd = this.sourceAudioBuffer.length * this.endCutValue / this.sourceAudioBuffer.duration;

        console.log(this.startCutValue);
        console.log(this.endCutValue);
        // Create a new buffer
        const audioContext = new AudioContext();
        const newBuffer = audioContext.createBuffer(
            this.sourceAudioBuffer.numberOfChannels,
            computedEnd - computedStart,
            this.sourceAudioBuffer.sampleRate
        );

        // Copy from old buffer to new with the right slice.
        // At this point, the audio has been cut
        for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
            newBuffer.copyToChannel(this.sourceAudioBuffer.getChannelData(i).slice(computedStart, computedEnd), i);
        }

        const leftSource = newBuffer.getChannelData(0);
        const rightSource = newBuffer.getChannelData(1);

        const worker = new Worker(new URL('./audio-editor.worker', import .meta.url));
        // const worker = new Worker('../audio-editor/audio-editor.worker', { type: 'module' });
        worker.postMessage({
            cmd: 'cut',
            config: {
                /*startCutValue: this.startCutValue,
                endCutValue: this.endCutValue,*/
                sampleRate: newBuffer.sampleRate,
                leftSource,
                rightSource
            }
        });
        worker.onmessage = (messageEvent) => {
            if (messageEvent.data.cmd === 'end') {
                console.log('CUT MESSAGE');
                this.reader = new FileReader();
                this.reader.onload = () => {
                    // this.shortAudioFileSrc = this.reader.result as string;
                    this.processing = false;
                };
                this.reader.readAsDataURL(messageEvent.data.file);
                this.store.dispatch(new audioEditorActions.SetCroppedFile(messageEvent.data.file));
                // this.sourceAudioFileSetter = messageEvent.data.file;
            }
        };
    }

    processToVoice() {
        this.processing = true;
        this.store.dispatch(new audioEditorActions.SendToProcessing('transfer'));
    }

    processToMelody() {
        this.processing = true;
        this.store.dispatch(new audioEditorActions.SendToProcessing('accompaniment'));
    }

    processToMelodyWithVoice() {
        this.processing = true;
        this.store.dispatch(new audioEditorActions.SendToProcessing('combine'));
    }

    resetFileChanges() {
        this.processing = true;
        this.store.dispatch(new audioEditorActions.ChangeSelectedFile('initial'));
    }

    reverseAudio() {
        // Create a new buffer
        const audioContext = new AudioContext();
        const newBuffer = audioContext.createBuffer(
            this.sourceAudioBuffer.numberOfChannels,
            this.sourceAudioBuffer.length,
            this.sourceAudioBuffer.sampleRate
        );

        for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
            newBuffer.copyToChannel(this.sourceAudioBuffer.getChannelData(i), i);
        }

        const leftSource = newBuffer.getChannelData(0);
        const rightSource = newBuffer.getChannelData(1);

        leftSource.reverse();
        rightSource.reverse();

        const worker = new Worker(new URL('./audio-editor.worker', import .meta.url));
        // const worker = new Worker('../audio-editor/audio-editor.worker', { type: 'module' });
        worker.postMessage({
            cmd: 'cut',
            config: {
                sampleRate: newBuffer.sampleRate,
                leftSource,
                rightSource
            }
        });
        worker.onmessage = (messageEvent) => {
            if (messageEvent.data.cmd === 'end') {
                console.log('REVERSE MESSAGE');
                this.reader = new FileReader();
                this.reader.onload = () => {
                    // this.shortAudioFileSrc = this.reader.result as string;
                    this.processing = false;
                };
                this.reader.readAsDataURL(messageEvent.data.file);
                this.store.dispatch(new audioEditorActions.SetReversedFile(messageEvent.data.file));
                // this.sourceAudioFileSetter = messageEvent.data.file;
            }
        };
    }

    /*onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            // this.fileChanged.next(true);
            this.myFile = file as Blob;
            /!*reader.readAsDataURL(file);
             reader.onload = () => {
             this.myFileSrc = reader.result as string;
             };*!/

            this.cut(this.myFile, 20, 30, cutBlob => {
                /!*console.log('My blob has been cut!');
                 // console.log(cutBlob);
                 this.myFileShort = cutBlob;

                 reader.readAsDataURL(this.myFileShort);
                 reader.onload = () => {
                 this.myFileSrcShort = reader.result as string;
                 };*!/
            }).then(value => console.log('Yes'));

            /!*const [file] = event.target.files;
             // this.store.dispatch(new quizConstructorActions.SetQuestionFileBlob(file as Blob));
             this.myFile = file as Blob;
             const form = new FormData();
             form.set('song', file as Blob);

             this.httpClient.post<UserList>(
             'https://gambo.app/alex/test/upload-file',
             form
             ).subscribe(ans => {
             console.log(ans);
             });*!/
        }
    }*/

    /*private async cut(src, start, end, callback, bitrate = 192) {
        if (!src)
         throw 'Invalid parameters!';

         if (start > end)
         throw 'Start is bigger than end!';
         else if (start < 0 || end < 0)
         throw 'Start or end is negative, cannot process';

         this.start = start;
         this.end = end;
         this.callback = callback;
         this.bitrate = bitrate;
         this.start = 31.5;
         this.end = 46.5;

        // Convert blob into ArrayBuffer
        const buffer = await new Response(src).arrayBuffer();
        const audioContext = new AudioContext();

        // Convert ArrayBuffer into AudioBuffer
        audioContext.decodeAudioData(buffer).then((decodedData) => this.computeData(decodedData));
    }*/

    /*private computeData(decodedData: AudioBuffer) {
        // Compute start and end values in secondes
        const computedStart = decodedData.length * this.start / decodedData.duration;
        const computedEnd = decodedData.length * this.end / decodedData.duration;

        // Create a new buffer
        const newBuffer = this.audioContext.createBuffer(decodedData.numberOfChannels, computedEnd - computedStart, decodedData.sampleRate);

        // Copy from old buffer to new with the right slice.
        // At this point, the audio has been cut
        for (let i = 0; i < decodedData.numberOfChannels; i++) {
            newBuffer.copyToChannel(decodedData.getChannelData(i).slice(computedStart, computedEnd), i);
        }

        const channels = 2; // 1 for mono or 2 for stereo
        const sampleRate = newBuffer.sampleRate; // 44100; 44.1khz (normal mp3 samplerate) WARNING sample rate note same as in description!
        const kbps = 128; // encode 128kbps mp3
        const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
        const mp3Data = [];

        // const samples = new Int16Array(newBuffer.getChannelData(0)); // one second of silence (get your data from the source you have)
        const leftSource = newBuffer.getChannelData(0);
        const rightSource = newBuffer.getChannelData(1);

        // Converting to Int16Array, but it doesn't necessary
        /!*const left = new Int16Array(leftSource.length);
         const right = new Int16Array(rightSource.length);*!/

        for (let i = 0; i < leftSource.length; i++) {
            leftSource[i] = this.convert(leftSource[i]);
            rightSource[i] = this.convert(rightSource[i]);
        }
        // leftSource.reverse();
        // rightSource.reverse();
        const sampleBlockSize = 1152; // can be anything but make it a multiple of 576 to make encoders life easier

        let mp3buf;
        for (let i = 0; i < leftSource.length; i += sampleBlockSize) {
            const leftChunk = leftSource.subarray(i, i + sampleBlockSize);
            const rightChunk = rightSource.subarray(i, i + sampleBlockSize);
            // const sampleChunk = samples.subarray(i, i + sampleBlockSize);
            mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
            if (mp3buf.length > 0) {
                mp3Data.push(mp3buf);
            }
        }
        mp3buf = mp3encoder.flush(); // finish writing mp3

        if (mp3buf.length > 0) {
            mp3Data.push(new Int8Array(mp3buf));
        }

        this.myFileShort = new Blob(mp3Data, {type: 'audio/mp3'});
        const reader = new FileReader();
        reader.readAsDataURL(this.myFileShort);
        reader.onload = () => {
            this.myFileSrcShort = reader.result as string;
        };

        const form = new FormData();
        form.set('song', this.myFileShort);

        this.httpClient.post<{ name: string }>(
            'https://gambo.app/alex/test/process',
            form
        ).subscribe(ans => {
            this.filename = ans.name;
            console.log('FILENAME', this.filename);
        });
    }*/

    public convert(n) {
        const v = n < 0 ? n * 32768 : n * 32767;       // convert in range [-32768, 32767]
        return Math.max(-32768, Math.min(32768, v)); // clamp
    }
}
