<div #waveformContainer
     class="scrubber"
     [ngClass]="{'scrubber--clickable': !isWaveIdle}"
     (mousedown)="handleMouseDown($event)"
     (mouseleave)="handleMouseLeave($event)"
     (mousemove)="handleMouseMove($event)"
     (mouseup)="handleMouseUp($event)"
     (touchstart)="handleMouseDown($event)"
     (touchmove)="handleMouseMove($event)"
     (touchend)="handleMouseUp($event)">
    <app-wave-line *ngIf="waveformCoordinates && visualization === 'line'"
                   [audioInterface]="audioInterface"
                   [animDuration]="isWaveIdle ? ANIM_TIMEOUT_INTERVAL : WAVE_TRANSITION_DURATION"
                   [color]="color"
                   [duration]="duration"
                   [isRecording]="false"
                   [isWaveIdle]="isWaveIdle"
                   [recordingTimeElapsed]="0"
                   [waveformCoordinates]="waveformCoordinates">
    </app-wave-line>
    <!--<app-wave-bars *ngIf="visualization === 'bars'"
            [audioInterface]="audioInterface"
            [color]="color"
            [duration]="duration"
            [waveformCoordinates]="waveformCoordinates">-->
</div>
