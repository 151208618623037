import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AudioEditorState } from './audio-editor.reducer';

export const audioEditorFeatureSelector = createFeatureSelector<{ audioEditor: AudioEditorState }>('audioEditorFeature');

export const selectedFileSelector = createSelector(audioEditorFeatureSelector, ({ audioEditor }) => audioEditor.selectedFile);
export const initialFileSelector = createSelector(audioEditorFeatureSelector, ({ audioEditor }) => audioEditor.initialFile);
export const croppedFileSelector = createSelector(audioEditorFeatureSelector, ({ audioEditor }) => audioEditor.croppedFile);
export const processedVoiceFileSelector = createSelector(audioEditorFeatureSelector, ({ audioEditor }) => audioEditor.processedVoiceFile);
export const processedMelodyFileSelector = createSelector(audioEditorFeatureSelector, ({ audioEditor }) => audioEditor.processedMelodyFile);
