import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizMainLandingComponent } from './quiz-main-landing/quiz-main-landing.component';
import { BetaAccessFormModule } from '../beta-access-form/beta-access-form.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [QuizMainLandingComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        RouterModule,
        BrowserAnimationsModule,
        MatSliderModule,
        MatIconModule,
        MatButtonModule,
        BetaAccessFormModule,
        MatSelectModule
    ]
})
export class QuizMainLandingModule {
}
