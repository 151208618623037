<div *ngFor="let questionTemplate of questionTemplateList"
     class="quiz-question">
    <div>
<!--        <div>Тип вопроса: {{ templateQuestion.type }}</div>-->
        <div>Вопрос: {{ questionTemplate.value }}</div>
<!--        <div>Тип ответа: {{ templateQuestion.answerType }}</div>-->
        <div>Ответ: {{ questionTemplate.correctAnswer ? questionTemplate.correctAnswer : getCorrectAnswers(questionTemplate.answers) }}</div>
    </div>
</div>
<div (click)="addQuestionTemplate()"
     class="quiz-question">
    <mat-icon class="mat-icon-rounded">add_circle</mat-icon>
</div>
