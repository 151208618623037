import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionType, QuizConstructorService, QuizQuestionInfo } from '../../quiz-constructor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from '../../../../app/app.state';
import { select, Store } from '@ngrx/store';
import * as quizConstructorActions from '../../store/quiz-constructor.actions';
import * as audioEditorActions from '../../../audio-editor/store/audio-editor.actions';
import { Observable, Subscription } from 'rxjs';
import {
    selectedQuestionAnswerDescriptionMediaSelector,
    selectedQuestionMediaSelector,
    selectedQuestionSelector
} from '../../store/quiz-constructor.selectors';
import { filter, first, takeUntil } from 'rxjs/operators';
import { FileManagerService } from '../../../../libs/file-manager/file-manager.service';
import { UnsubscribeSubjectComponent } from '../../../../libs/unsubscribe-subject-component';
import { QuizQuestionContentType, QuizQuestionContentTypeMap } from '../../../../../proto/generated/gambo_pb';
import { convertAudioBufferToShape } from '../../../../libs/player/audio-buffert-shape-converter';
import { croppedFileSelector, initialFileSelector } from '../../../audio-editor/store/audio-editor.selectors';

@Component({
    selector: 'app-answer-description-constructor',
    templateUrl: './answer-description-constructor.component.html',
    styleUrls: ['./answer-description-constructor.component.scss']
})
export class AnswerDescriptionConstructorComponent extends UnsubscribeSubjectComponent implements OnInit {

    readonly QuizQuestionContentType = QuizQuestionContentType;
    public selectedQuestion: QuizQuestionInfo;

    public answerDescriptionFormGroup: FormGroup;
    public questionTypes = QuestionType;

    public initialAudioFile: Blob;
    public croppedAudioFile: Blob;

    // private audioPlayer = new Audio();

    file: Blob;
    fileSrc: string;
    @Output() fileChanged = new EventEmitter();
    public sourceAudioBuffer: AudioBuffer;
    public audioBuffers: Array<Float32Array>;
    public audioShape: number[];

    /*private audioSourceElement: ElementRef<HTMLAudioElement>;
    @ViewChild('audioSource') set content(audioSourceRef: ElementRef) {
        if (audioSourceRef) {
            this.audioSourceElement = audioSourceRef;
        }
    }*/

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        public domSanitizer: DomSanitizer,
        private fileManagerService: FileManagerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(selectedQuestionSelector)
        ).subscribe(selectedQuestion => {
            this.selectedQuestion = selectedQuestion;
            console.log(this.selectedQuestion);
        });

        this.store.pipe(
            select(selectedQuestionAnswerDescriptionMediaSelector),
            first()
        ).subscribe(fileUrl => {
            this.fileManagerService.downloadFileBlob(fileUrl).then(result => {
                this.file = result;
                this.updateAnswerDescriptionFile(this.file, false);

                if (this.selectedQuestion.type === this.questionTypes.IMAGE || this.selectedQuestion.type === this.questionTypes.VIDEO) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.file);

                    reader.onload = () => {
                        this.fileSrc = reader.result as string;
                    };
                }
            }).catch((error) => {
                console.log('Error while downloading picture', error);
                console.log('Error code for downloading picture: ' + error.code);
            });
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(initialFileSelector)
        ).subscribe(initialAudioFile => {
            this.initialAudioFile = initialAudioFile;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(croppedFileSelector)
        ).subscribe(croppedAudioFile => {
            this.croppedAudioFile = croppedAudioFile;
        });

        this.answerDescriptionFormGroup = this.formBuilder.group({
            answerDescriptionValueControl: new FormControl(this.selectedQuestion.answerDescriptionValue),
            file: new FormControl('', [Validators.required]),
            // fileSource: new FormControl('', [Validators.required])
        });
    }

    changeSelectedAnswerDescriptionType(answerDescriptionType: QuestionType) {
        this.store.dispatch(new quizConstructorActions.SetAnswerDescriptionType(answerDescriptionType));
    }

    clearFile() {
        this.file = null;
        this.store.dispatch(new quizConstructorActions.SetAnswerDescriptionFileBlob(null));
        this.answerDescriptionFormGroup.patchValue({
            fileSource: null
        });
    }

    onFileChange(event) {
        // const reader = new FileReader();
        console.log('ANSWER DESCRIPTION FILE CHANGE');

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.updateAnswerDescriptionFile(file);

            if (this.selectedQuestion.type === this.questionTypes.IMAGE || this.selectedQuestion.type === this.questionTypes.VIDEO) {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    this.fileSrc = reader.result as string;
                    /*this.questionFormGroup.patchValue({
                     fileSource: reader.result
                     });*/
                };
            }
        }
    }

    public updateAnswerDescriptionFile(file: Blob, fileChanged = true) {
        console.log('DEBUG Got new file for question', file);
        this.store.dispatch(new quizConstructorActions.SetAnswerDescriptionFileBlob(file as Blob));
        this.file = file;
        // Convert blob into ArrayBuffer
        new Response(file).arrayBuffer().then(arrayBuffer => {
            const audioContext = new AudioContext();
            // Convert ArrayBuffer into AudioBuffer
            audioContext.decodeAudioData(arrayBuffer).then(audioBuffer => {
                this.sourceAudioBuffer = audioBuffer;
                this.audioBuffers = new Array<Float32Array>(this.sourceAudioBuffer.numberOfChannels);
                for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
                    this.audioBuffers[i] = this.sourceAudioBuffer.getChannelData(i);
                }

                this.audioShape = convertAudioBufferToShape(audioBuffer.getChannelData(0), true);
            });
        });
        if (fileChanged) {
            this.fileChanged.next(true);
        }
    }

    answerDescriptionValueChangeHandler(answerDescriptionValueTarget: EventTarget) {
        this.store.dispatch(new quizConstructorActions.SetAnswerDescriptionValue((answerDescriptionValueTarget as HTMLInputElement).value));
    }

    setInitialAudioFile() {
        if (this.initialAudioFile) {
            this.updateAnswerDescriptionFile(this.initialAudioFile);
        }
    }

    setCroppedAudioFile() {
        if (this.croppedAudioFile) {
            this.updateAnswerDescriptionFile(this.croppedAudioFile);
        }
    }
}
