import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.scss']
})
export class ProgressComponent {
    @Input() public mini = false;
    @Input() public numberOfQuestions: number = null;
    @Input() public questionNumber: number = null;


    public arrayByNumber(index: number) {
        return Array(Math.round(index)).fill(0).map((x, i) => i);
    }
}
