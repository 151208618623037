import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { line, curveNatural } from 'd3-shape';
import { MAX_RECORD_TIME, SAMPLE_LENGTH } from '../../../../libs/player/constants';
import { easeCubicInOut, easeQuad } from 'd3-ease';
import { select } from 'd3-selection';
import * as d3selection from 'd3-selection';
import 'd3-transition';

const STRAIGHT_LINE = (() => {
    const paths = [];
    const segmentWidth = Math.round(100 / SAMPLE_LENGTH);
    for (let i = 0; i < SAMPLE_LENGTH; i++) {
        paths.push([segmentWidth * i, 1]);
    }

    return paths;
})();

@Component({
    selector: 'app-wave-line',
    templateUrl: './wave-line.component.html',
    styleUrls: ['./wave-line.component.scss']
})
export class WaveLineComponent implements OnInit, OnChanges, AfterViewInit {

    private readonly DEFAULT_PLAYHEAD_COORDS = [0, 1];
    private readonly MIN_RECORD_TIME = 5;
    private readonly MAX_RECORD_TIME = 15000;
    private readonly WAVE_TRANSITION_DURATION = 1000;

    @Input() public audioInterface;
    @Input() public animDuration;
    @Input() public color;
    @Input() public duration;
    @Input() public isRecording;
    @Input() public isWaveIdle;
    @Input() public recordingTimeElapsed;
    @Input() public waveformCoordinates: number[][];
    // public waveformCoordinates = new Array<number>();
    private SAMPLE_LENGTH = 40;

    @ViewChild('waveformPath', { static: false })
    public waveformPathRef: ElementRef<SVGPathElement>;

    @ViewChild('waveform', { static: false })
    public waveformRef: ElementRef<SVGSVGElement>;

    @ViewChild('playhead', { static: false })
    public playheadRef: ElementRef<SVGSVGElement>;

    @ViewChild('recordProgress', { static: false })
    private recordProgressRef: ElementRef<SVGPathElement>;

    private animationFrameID: number;

    public playheadCoordinates: number[];
    public recordProgressWidth: number;
    public waveformCoordinatesState: number[][];

    constructor() {
        // this.waveformCoordinates = this.generateRandomCoordinates();
    }

    ngOnInit() {
        const animateFrame = () => {
            let playheadCoordinates = this.DEFAULT_PLAYHEAD_COORDS;
            if (!this.isWaveIdle || this.isRecording) {
                playheadCoordinates = this.getPlayheadCoordinates();
            }
            if (this.playheadRef.nativeElement) {
                this.playheadRef.nativeElement.style.left = `${playheadCoordinates[0]}%`;
                this.playheadRef.nativeElement.style.top = `${playheadCoordinates[1] * 50}%`;
            }
            if (this.waveformRef.nativeElement) {
                this.waveformRef.nativeElement.style.width = `${playheadCoordinates[0]}%`;
            }
            this.animationFrameID = requestAnimationFrame(animateFrame);
        };
        this.animationFrameID = requestAnimationFrame(animateFrame);
    }

    ngAfterViewInit() {
        /*let playheadCoordinates = this.DEFAULT_PLAYHEAD_COORDS;
        playheadCoordinates = this.getPlayheadCoordinates();
        if (this.playheadRef.nativeElement) {
            this.playheadRef.nativeElement.style.left = `${playheadCoordinates[0]}%`;
            this.playheadRef.nativeElement.style.top = `${playheadCoordinates[1] * 50}%`;
        }
        if (this.waveformRef.current) {
            this.waveformRef.current.style.width = `${playheadCoordinates[0]}%`;
        }*/
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.waveformPathRef) {
            const didWaveIdleChange = changes?.isWaveIdle?.previousValue !== this.isWaveIdle;

            if (
                changes.waveformCoordinates?.previousValue !== this.waveformCoordinates &&
                !this.isRecording
            ) {
                console.log('DEBUG');
                select(this.waveformPathRef.nativeElement)
                    .transition()
                    .duration(didWaveIdleChange ? this.WAVE_TRANSITION_DURATION : this.animDuration)
                    .ease(didWaveIdleChange ? easeCubicInOut : easeQuad)
                    .attr('d', line().curve(curveNatural)(this.waveformCoordinates))
                    .on('end', () => {
                        this.waveformCoordinatesState = this.waveformCoordinates;
                    });
            }

            if (this.isRecording && !changes.isRecording?.previousValue) {
                select(this.waveformPathRef)
                    .transition()
                    .duration(this.WAVE_TRANSITION_DURATION)
                    .ease(easeCubicInOut)
                    .attr('d', line().curve(curveNatural)(STRAIGHT_LINE))
                    .on('end', () => {
                        this.waveformCoordinatesState = STRAIGHT_LINE;
                    });
            }
        }
        console.log('~~~~~~~~~~~~ CHANGES');
        console.log(this.audioInterface);
        console.log(this.duration);
        console.log(this.waveformCoordinates);
    }

    public getCurve(): string {
        return line().curve(curveNatural)(this.waveformCoordinates);
    }

    public getStraightLine() {
        const paths = [];
        const segmentWidth = Math.round(100 / this.SAMPLE_LENGTH);
        for (let i = 0; i < this.SAMPLE_LENGTH; i++) {
            paths.push([segmentWidth * i, 1]);
        }

        return line()(paths);
    }

    generateRandomCoordinates(): Array<number> {
        const segmentWidth = Math.round(100 / this.SAMPLE_LENGTH);
        const paths = [];
        for (let i = 0; i < this.SAMPLE_LENGTH; i++) {
            paths.push([segmentWidth * i, this.generateRandomYCoordinate()]);
        }

        return paths;
    }

    generateRandomYCoordinate = () => Math.random() / 4 + 0.75;

    private getPlayheadCoordinates() {
        /*const {
            audioInterface,
            duration,
            isRecording,
            recordingTimeElapsed
        } = this.props;*/
        const waveformRef = this.isRecording
            ? this.recordProgressRef
            : this.waveformPathRef;
        const waveformElement: SVGPathElement = waveformRef.nativeElement;

        if (!waveformElement) {
            return this.DEFAULT_PLAYHEAD_COORDS;
        }

        const waveformLength = waveformElement.getTotalLength();
        const playheadPositionIncrement = this.isRecording
            ? waveformLength / MAX_RECORD_TIME
            : waveformLength / this.duration;
        // const playheadPositionIncrement = waveformLength / this.MAX_RECORD_TIME;

        const playheadTime = this.isRecording
            ? this.recordingTimeElapsed
            : (this.audioInterface ? this.audioInterface.currentTime : 1) / 1000;

        const point = !waveformElement
            ? {x: 0, y: 0}
            : waveformElement.getPointAtLength(
                playheadTime * playheadPositionIncrement
            );

        // set width of overlay path to show progress during recording
        if (this.isRecording) {
            const progress = Math.round(
                ((this.recordingTimeElapsed + 1) / MAX_RECORD_TIME) * 100
            );
            return [progress, point.y];
        } else {
            return [point.x, point.y];
        }
    }
}
