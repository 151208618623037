<div class="header">
    <button mat-raised-button
            (click)="openFriendsDialog()">
        {{ "FRIENDS.FRIENDS" | translate }}
    </button>
</div>
<div class="games-wrapper">
    <app-game *ngFor="let shortGameInfo of shortGameInfoList"
              [shortGameInfo]="shortGameInfo">
    </app-game>
</div>
