import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BetaAccessLandingModule } from '../modules/beta-access-landing/beta-access-landing.module';
import { PrivacyPolicyQuizModule } from '../modules/privacy-policy-quiz/privacy-policy-quiz.module';
import { NewsletterUnsubscribeModule } from '../modules/newsletter-unsubscribe/newsletter-unsubscribe.module';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthInterceptor } from '../modules/auth/auth/auth.interceptor';
import { AuthModule } from '../modules/auth/auth.module';
import { GamesModule } from '../modules/games/games.module';
import { MaterialAllInOneModule } from '../libs/material-all-in-one/material-all-in-one.module';
import { FriendsModule } from '../modules/friends/friends.module';
import { I18NModule } from '../libs/translate/i18n.module';
import { QuizConstructorModule } from '../modules/quiz-constructor/quiz-constructor.module';
import { RoomManagerModule } from '../modules/room-manager/room-manager.module';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { QuizConstructorEffects } from '../modules/quiz-constructor/store/quiz-constructor.effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { quizConstructorReducer, QuizConstructorState } from '../modules/quiz-constructor/store/quiz-constructor.reducer';
import { quizRunnerReducer, QuizRunnerState } from '../modules/quiz-runner/store/quiz-runner.reducer';
import { QuizRunnerEffects } from '../modules/quiz-runner/store/quiz-runner.effects';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { QuizRunnerModule } from '../modules/quiz-runner/quiz-runner.module';
import { FileManagerService } from '../libs/file-manager/file-manager.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QuizPromoModule } from '../modules/quiz-promo/quiz-promo.module';
import { QuizzesModule } from '../modules/quizzes/quizzes.module';
import { AuthService } from '../modules/auth/auth/auth.service';
import { PreviousRouteService } from './previous-route.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { audioEditorReducer, AudioEditorState } from '../modules/audio-editor/store/audio-editor.reducer';
import { AudioEditorEffects } from '../modules/audio-editor/store/audio-editor.effects';
import { QuizMainLandingModule } from '../modules/quiz-main-landing/quiz-main-landing.module';
import { AiPartygamesModule } from '../modules/ai-partygames/ai-partygames.module';

const firebaseConfig = {
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    projectId: environment.projectId,
    storageBucket: environment.storageBucket,
    messagingSenderId: environment.messagingSenderId,
    appId: environment.appId,
    measurementId: environment.measurementId
};

export interface State {
    quizConstructorState: QuizConstructorState;
    quizRunnerState: QuizRunnerState;
    audioEditorState: AudioEditorState;
}
export const reducers: ActionReducerMap<State> = {
    quizConstructorState: quizConstructorReducer,
    quizRunnerState: quizRunnerReducer,
    audioEditorState: audioEditorReducer
};
const reducerKeys = ['quizConstructorState', 'quizRunnerState', 'audioEditorState'];
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: reducerKeys})(reducer);
}
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        console.log('state', state);
        console.log('action', action);

        return reducer(state, action);
    };
}
export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        MaterialAllInOneModule,
        AppRoutingModule,
        HttpClientModule,
        I18NModule.forRoot(),
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([
            QuizConstructorEffects,
            QuizRunnerEffects,
            AudioEditorEffects
        ]),
        /*TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),*/
        BetaAccessLandingModule,
        QuizMainLandingModule,
        PrivacyPolicyQuizModule,
        NewsletterUnsubscribeModule,
        AngularFireModule.initializeApp(firebaseConfig),
        /*provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),*/
        AngularFirestoreModule,
        AngularFireAuthModule,
        provideFirebaseApp(() => initializeApp(firebaseConfig)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        AuthModule,
        GamesModule,
        FriendsModule,
        QuizPromoModule,
        AiPartygamesModule,
        QuizzesModule,
        QuizConstructorModule,
        RoomManagerModule,
        QuizRunnerModule,
        StoreDevtoolsModule.instrument({
            name: 'Gambo',
            /*logOnly: environment.production*/
        }),
        BrowserAnimationsModule
    ],
    providers: [
        // TODO I commented it because it cause freeze
        { provide: 'googleTagManagerId', useValue: 'GTM-59NLN42' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        PreviousRouteService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private previousRouteService: PreviousRouteService) {
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './i18n/', '.json');
}
