import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { moderatingQuizListSelector } from '../../store/quiz-constructor.selectors';
import { Observable } from 'rxjs';
import { QuizInfo, QuizStatus } from '../../quiz-constructor.service';
import { AppState } from '../../../../app/app.state';
import * as quizConstructorActions from '../../store/quiz-constructor.actions';

@Component({
    selector: 'app-admin-quiz-list',
    templateUrl: './admin-quiz-list.component.html',
    styleUrls: ['./admin-quiz-list.component.scss']
})
export class AdminQuizListComponent implements OnInit {

    readonly QuizStatus = QuizStatus;

    public quizList$: Observable<Array<QuizInfo>>;
    public rejectionReason = '';

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit(): void {
        this.quizList$ = this.store.pipe(
            select(moderatingQuizListSelector)
        );

        this.store.dispatch(new quizConstructorActions.GetAllModeratingQuizzes());
    }

    public openQuiz(quizInfo: QuizInfo) {
        // TODO: open for review!
        this.store.dispatch(new quizConstructorActions.SetSelectedQuiz(quizInfo));
    }

    submitReview(quizInfo: QuizInfo, status: QuizStatus) {
        this.store.dispatch(new quizConstructorActions.ReviewQuiz({
            ...quizInfo,
            status,
            rejectionReason: this.rejectionReason
        }));
    }
}
