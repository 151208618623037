import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuizConstructorState } from './quiz-constructor.reducer';

export const quizConstructorFeatureSelector = createFeatureSelector<{ quizConstructor: QuizConstructorState }>('quizConstructorFeature');

export const quizListSelector = createSelector(quizConstructorFeatureSelector, ({ quizConstructor }) => quizConstructor.quizList);
export const userRoomsSelector = createSelector(quizConstructorFeatureSelector, ({ quizConstructor }) => quizConstructor.userRooms);
export const moderatingQuizListSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.moderatingQuizList
);
export const selectedQuizSelector = createSelector(quizConstructorFeatureSelector, ({ quizConstructor }) => quizConstructor.selectedQuiz);
export const selectedQuestionSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.selectedQuestion
);
export const selectedQuestionMediaSelector = createSelector(selectedQuestionSelector, selectedQuestion => selectedQuestion.media);
export const selectedQuestionFileBlobSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.selectedQuestionFileBlob
);
export const selectedQuestionTextSelector = createSelector(
    selectedQuestionSelector,
    selectedQuestion => selectedQuestion.value
);

export const selectedQuestionAnswerDescriptionTypeSelector = createSelector(
    selectedQuestionSelector,
    selectedQuestion => selectedQuestion.answerDescriptionType
);
export const selectedQuestionAnswerDescriptionMediaSelector = createSelector(
    selectedQuestionSelector,
        selectedQuestion => selectedQuestion.answerDescriptionMedia
);
export const selectedQuestionAnswerDescriptionFileBlobSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.selectedQuestionAnswerDescriptionFileBlob
);
export const selectedQuestionAnswerDescriptionTextSelector = createSelector(
    selectedQuestionSelector,
    selectedQuestion => selectedQuestion.answerDescriptionValue
);

export const selectedQuizIdSelector = createSelector(selectedQuizSelector, selectedQuiz => selectedQuiz ? selectedQuiz.id : '');

export const selectedQuizFilesUploadingStatusSelector = createSelector(quizConstructorFeatureSelector, ({ quizConstructor }) => {
    return {
        selectedQuestionFileUploadStatus: quizConstructor.selectedQuestionFileUploadStatus,
        selectedQuestionAnswerDescriptionFileUploadStatus: quizConstructor.selectedQuestionAnswerDescriptionFileUploadStatus
    };
});

export const questionTemplateSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.questionTemplate
);

export const questionTemplateListSelector = createSelector(
    quizConstructorFeatureSelector,
    ({ quizConstructor }) => quizConstructor.questionTemplateList
);

/*export const deviceAutoConnectSelector = createSelector(
  quizConstructorFeatureSelector,
  (
    state: { device: QuizConstructorState },
    props: { serialNumber: string, ip: string, port: number }) => (
      state.device.devices
        .find(
          device => device.ip === props.ip
            && device.port === props.port
            && device.serialNumber === props.serialNumber
        )
    )
);

export const devicesLoadedSelector = createSelector(
  quizConstructorFeatureSelector,
  ({ device }) => device.devicesLoaded
)

export const deviceParkon3kPagesSelector = createSelector(
  quizConstructorFeatureSelector,
  ({ device }) => device.showParkon3KPages
)

export const hideQuickButtonsSelector = createSelector(
  quizConstructorFeatureSelector,
  ({ device }) => device.hideQuickButtons
)

export const deviceInfoSelector = createSelector(
  quizConstructorFeatureSelector,
  ({ device }) => device.deviceInfo
)

export const userSelector = createSelector(
  quizConstructorFeatureSelector,
  ({ device }) => device?.authorizedDevice?.user
)*/
