import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionType, QuizQuestionInfo } from '../../quiz-constructor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppState } from '../../../../app/app.state';
import { select, Store } from '@ngrx/store';
import * as quizConstructorActions from '../../store/quiz-constructor.actions';
import * as audioEditorActions from '../../../audio-editor/store/audio-editor.actions';
import { Observable, Subscription } from 'rxjs';
import { selectedQuestionMediaSelector, selectedQuestionSelector } from '../../store/quiz-constructor.selectors';
import { FileManagerService } from '../../../../libs/file-manager/file-manager.service';

@Component({
    selector: 'app-question-constructor',
    templateUrl: './question-constructor.component.html',
    styleUrls: ['./question-constructor.component.scss']
})
export class QuestionConstructorComponent implements OnInit {

    private selectedQuestion$: Observable<QuizQuestionInfo>;
    private selectedQuestionS: Subscription;
    public selectedQuestion: QuizQuestionInfo;

    public questionFormGroup: FormGroup;
    public questionTypes = QuestionType;
    public availableImageTypes = ['image/bmp', 'image/jpeg', 'image/png', 'image/webp'];
    public availableVideoTypes = ['video/mp3', 'video/mp4'];
    public availableAudioTypes = ['audio/mp4', 'audio/mpeg', 'audio/wav'];

    // private audioPlayer = new Audio();

    file: Blob;
    fileSrc: string;
    @Output() fileChanged = new EventEmitter();

    @ViewChild('fileSelector', {static: true})
    public fileSelectorRef: ElementRef;

    /*private audioSourceElement: ElementRef<HTMLAudioElement>;
     @ViewChild('audioSource') set content(audioSourceRef: ElementRef) {
     if (audioSourceRef) {
     this.audioSourceElement = audioSourceRef;
     }
     }*/

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        public domSanitizer: DomSanitizer,
        private fileManagerService: FileManagerService
    ) {
    }

    ngOnInit(): void {
        this.selectedQuestion$ = this.store.pipe(
            select(selectedQuestionSelector)
        );
        this.selectedQuestionS = this.selectedQuestion$.subscribe(selectedQuestion => {
            this.selectedQuestion = selectedQuestion;
        });
        this.store.pipe(
            select(selectedQuestionMediaSelector),
            // first()
        ).subscribe(fileUrl => {
            console.log('Download question audio');
            this.fileManagerService.downloadFileBlob(fileUrl).then(result => {
                this.file = result;
                this.updateQuestionFile(this.file, false);
                if (this.selectedQuestion.type === this.questionTypes.AUDIO) {
                    this.store.dispatch(new audioEditorActions.SetInitialFile(this.file));
                }

                if (this.selectedQuestion.type === this.questionTypes.IMAGE || this.selectedQuestion.type === this.questionTypes.VIDEO) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.file);

                    reader.onload = () => {
                        this.fileSrc = reader.result as string;
                    };
                }
            }).catch((error) => {
                console.log('Error while downloading picture', error);
                console.log('Error code for downloading picture: ' + error.code);
            });
        });

        this.questionFormGroup = this.formBuilder.group({
            textQuestionControl: new FormControl(
                this.selectedQuestion.value,
                [Validators.min(1), Validators.max(1000)]
            ),
            file: new FormControl('', [Validators.required])
            // fileSource: new FormControl('', [Validators.required])
        });
    }

    clearFile() {
        this.file = null;
        this.store.dispatch(new quizConstructorActions.SetQuestionFileBlob(null));
        this.questionFormGroup.patchValue({
            fileSource: null
        });
    }

    public openFileSelector() {
        this.fileSelectorRef.nativeElement.click();
        // document.querySelector('input').click()
    }

    onDropFiles(allFiles: File[]): void {
        const filesAmount = allFiles.length;
        for (let i = 0; i < filesAmount; i++) {
            const file = allFiles[i];
            if ([...this.availableImageTypes, ...this.availableVideoTypes, ...this.availableAudioTypes].includes(file.type)) {
                this.onFileChange(file);
            }
        }
    }

    onFileSelect(event) {
        // const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.onFileChange(file);
        }
    }

    onFileChange(file: File) {
        // const reader = new FileReader();
        console.log('FILE CHANGE');

        if (this.availableAudioTypes.includes(file.type)) {
            this.store.dispatch(new quizConstructorActions.SetQuestionType(QuestionType.AUDIO));
        } else if (this.availableImageTypes.includes(file.type)) {
            this.store.dispatch(new quizConstructorActions.SetQuestionType(QuestionType.IMAGE));
        } else if (this.availableVideoTypes.includes(file.type)) {
            this.store.dispatch(new quizConstructorActions.SetQuestionType(QuestionType.VIDEO));
        }

        this.store.dispatch(new audioEditorActions.SetInitialFile(file));
        this.updateQuestionFile(file);

        if (this.selectedQuestion.type === this.questionTypes.IMAGE || this.selectedQuestion.type === this.questionTypes.VIDEO) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.fileSrc = reader.result as string;
            };
        }
    }

    public updateQuestionFile(file: Blob, fileChanged = true) {
        console.log('DEBUG Got new file for question', file);
        this.store.dispatch(new quizConstructorActions.SetQuestionFileBlob(file as Blob));
        this.file = file;
        if (fileChanged) {
            this.fileChanged.next(true);
        }
    }

    onTextQuestionChange(textQuestionTarget: EventTarget) {
        this.store.dispatch(new quizConstructorActions.SetQuestionText((textQuestionTarget as HTMLInputElement).value));
    }

    changeSelectedQuestionType(questionType: QuestionType) {
        this.store.dispatch(new quizConstructorActions.SetQuestionType(questionType));
        /*if (!this.premium && questionType !== QuestionType.TEXT) {
         this.snackBar.open(
         'Buy subscription',
         '',
         {
         duration: 3000
         }
         );
         } else {
         this.store.dispatch(new quizConstructorActions.SetQuestionType(questionType));
         }*/
    }
}
