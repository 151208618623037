import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, combineLatest } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateLoaderFactoryType } from './translate-loader-factory';

export class MultiTranslateLoader extends TranslateLoader {
    private loaders: { [lang: string]: { [module: string]: Observable<any> } } = {};
    private modules: string[] = [];
    private currentLoader: { [modulePath: string]: TranslateLoader } = {};

    constructor(
        config: string[],
        private httpLoaderFactory: TranslateLoaderFactoryType
    ) {
        super();
        this.addModules(config);
    }

    private addModules(config: string[]) {
        if (config && config.length) {
            config.forEach(element => {
                this.addModule(element);
            });
        }
    }

    public addModule(path: string) {
        if (path !== '') {
            this.modules.push(path);
        }
    }

    public getTranslation(lang: string): Observable<any> {
        return combineLatest(this.getLoaders(lang))
            .pipe(
                map(
                    translates => translates.reduce(
                        (acc, val) => ({...acc, ...val}),
                        {}
                    )
                )
            );
    }

    private getLoaders(lang: string): Observable<object>[] {
        const loaders = this.loaders[lang] || {};
        this.loaders[lang] = loaders;
        for (const modulePath of this.modules) {
            if (typeof loaders[modulePath] === 'undefined') {
                if (this.currentLoader[modulePath] === undefined) {
                    this.currentLoader[modulePath] = this.httpLoaderFactory(modulePath);
                }
                const loader = this.currentLoader[modulePath];
                loaders[modulePath] = loader.getTranslation(lang);
            }
        }

        return Object.values(loaders);
    }
}
