<app-user-header></app-user-header>
<div class="room-manager-wrapper">
    <div *ngIf="additionalRoomInfo?.questionsCount"
         class="quiz-info-wrapper">
        <div class="questions-number">
            {{ 'Вопросов ' + additionalRoomInfo?.questionsCount }}
        </div>
        <div class="quiz-name">
            {{ additionalRoomInfo?.quizName }}
        </div>
        <div class="quiz-description">
            {{ additionalRoomInfo?.quizDescription }}
        </div>
    </div>
    <app-room-manager-user-list [players]="players"></app-room-manager-user-list>
    <div *ngIf="roomId.length > 0">
        <qrcode [qrdata]="'https://ai-partygames.com/room-manager?data=' + getQrData()"
                [width]="256"
                [errorCorrectionLevel]="'M'">
        </qrcode>
    </div>
</div>
<button mat-button
        [disabled]="players.length < 2 || !isHost"
        (click)="startButtonHandler()"
        class="start-game-button">
    <mat-icon class="mat-icon-rounded">play_circle</mat-icon>
    {{ ('QUIZ.ROOM_MANAGER.' + isHost ? 'START_GAME' : 'WAITING_START') | translate }}
</button>
<!--<div class="room-manager-wrapper">
    <div *ngIf="roomId.length > 0">
        <qrcode [qrdata]="'https://ai-partygames.com/room-manager?action=join&id=' + roomId"
                [width]="256"
                [errorCorrectionLevel]="'M'">
        </qrcode>
    </div>
    <div *ngIf="players">
        <mat-card *ngFor="let player of players"
                  class="user-card">
            <div class="player-information">
                <img [src]="player.getAvatarurl()"
                     class="avatar-image">
                <div>{{ player.getNickname() }}</div>
            </div>
            &lt;!&ndash;<mat-card-header>
                <mat-card-title>{{ player.getNickname() }}</mat-card-title>
            </mat-card-header>&ndash;&gt;
    &lt;!&ndash;        <mat-card-subtitle>{{ player.get }}</mat-card-subtitle>&ndash;&gt;
    &lt;!&ndash;        <mat-card-content>{{ player.description }}</mat-card-content>&ndash;&gt;
        </mat-card>
    </div>
    <button mat-raised-button
            color="primary"
            *ngIf="isHost"
            (click)="startButtonHandler()">
        START
    </button>
</div>-->
