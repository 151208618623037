import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AudioInterface } from '../../../../libs/player/audio-interface';
import { MIX_ADJUSTMENT_DEFAULT, DEFAULT_AUDIO_DURATION } from '../../../../libs/player/constants';
import { WaveControlComponent } from '../wave-control/wave-control.component';
import { Observable } from 'rxjs';
import { UnsubscribeSubjectComponent } from '../../../../libs/unsubscribe-subject-component';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent extends UnsubscribeSubjectComponent implements OnInit, OnChanges, OnDestroy {

    readonly DEFAULT_AUDIO_DURATION = DEFAULT_AUDIO_DURATION;

    @Input() public activePlayerKey: string;
    @Output() public activePlayerKeyChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() public audioBuffers: Float32Array[];
    @Input() public audioShape: number[];
    @Input() public autoPlay = false;
    @Input() public color?: string;
    @Input() public isFocusable = true;
    @Input() public isSmall = false;
    @Input() public loop = false;
    @Input() public mix = MIX_ADJUSTMENT_DEFAULT;
    @Input() public onEndedCallback: () => void;
    @Input() public playerKey: string;
    @Input() public shouldHideControls?: boolean;
    @Input() public shouldPauseAudio?: boolean;
    @Input() public shouldRewind = true;
    @Input() public visualization: 'bars' | 'line' = 'line';
    @Input() public stopEvent: Observable<void>;

    @ViewChild('waveform', {static: true})
    public scrubberRef: WaveControlComponent;

    public hasNotStarted = true;
    public isPlayDisabled = false;
    public isPlaying = false;
    public isPausedByUser = false;
    public wasPlaying = false;
    public outputIsProcessing = false;

    audioInterface: AudioInterface;
    requestAnimFrameId?: number;

    public cutStartTime = 0;
    public cutEndTime = 1000;

    constructor() {
        super();
    }

    ngOnInit() {
        document.addEventListener('visibilitychange', this.onTabVisibilityChange);
        document.addEventListener('keyup', this.onDocumentKeyUp);

        console.log('DEBUG: CREATING NEW AUDIO INTERFACE');
        if (!this.onEndedCallback) {
            this.onEndedCallback = () => {};
        }
        this.audioInterface = new AudioInterface({
            audioBuffers: this.audioBuffers,
            mix: this.mix,
            playCallback: () => {
                this.activePlayerKeyChange.next(this.playerKey);
                this.audioInterface.updateMix(this.mix);
                this.isPlaying = true;
                this.hasNotStarted = false;
                this.isPausedByUser = false;
            },
            stopCallback: () => this.isPlaying = false,
            onEndedCallback: () => {
                if (this.loop) {
                    // Although AudioContext has a `loop` attribute, we don’t set
                    // it because there is no event emitted when the audio loops.
                    // Instead, we listen for the `onended` event and then play
                    // from the beginning.
                    if (this.isPlaying) {
                        this.audioInterface.play(0);
                    }
                    this.audioInterface.clearTimer();
                } else {
                    // TODO Why does it calls on pause
                    console.log('End callback');
                    this.audioInterface.clearTimer(false);
                    this.isPlaying = false;
                }
                this.onEndedCallback();
            }
        });

        this.stopEvent.pipe(
            takeUntil(this.unsubscribeSubject)
        ).subscribe(() => {
            if (this.audioInterface) {
                this.audioInterface.stop();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // Multiple Players may be mounted, so we compare their keys to the
        // activePlayerKey, and pause any audio that doesn’t match.
        if (
            this.isPlaying &&
            this.activePlayerKey &&
            this.activePlayerKey !== this.playerKey
        ) {
            this.audioInterface.stop();
            return;
        }
        if (this.shouldPauseAudio && !changes.shouldPauseAudio?.previousValue) {
            this.audioInterface.stop();
            return;
        }
        if (!this.isPausedByUser && !this.shouldPauseAudio && changes.shouldPauseAudio?.previousValue) {
            this.audioInterface.play(this.audioInterface.currentTime);
            return;
        }
        if (
            this.audioInterface
            && changes.audioBuffers?.currentValue !== changes.audioBuffers?.previousValue
        ) {
            this.audioInterface.stop();
            this.audioInterface.reset(this.audioBuffers, this.mix, true);
            this.audioInterface.currentTime = this.shouldRewind
                ? 0
                : this.audioInterface.currentTime;

            if (this.autoPlay && (this.hasNotStarted || this.isPlaying)) {
                this.audioInterface.play(this.audioInterface.currentTime);
            }
            return;
        }
        if (changes.mix?.previousValue && this.mix !== changes.mix?.previousValue) {
            this.audioInterface.updateMix(this.mix);
        }
    }

    ngOnDestroy() {
        document.removeEventListener(
            'visibilitychange',
            this.onTabVisibilityChange
        );
        document.removeEventListener('keyup', this.onDocumentKeyUp);

        if (this.audioInterface) {
            this.audioInterface.close();
        }
    }

    public handlePlayButtonClick() {
        console.log('DEBUG: HANDLE PLAY BUTTON');
        if (!this.isPlaying) {
            console.log('DEBUG: START PLAYING');
            this.audioInterface.play(this.audioInterface.currentTime);
        } else {
            console.log('DEBUG: STOP PLAYING');
            this.audioInterface.stop();
            this.isPausedByUser = true;
        }
    }

    public handlePlayButtonKeyUp(event: KeyboardEvent) {
        // The play button by default is reacting to the spacebar, so
        // disable it here and handle it with a listener on the document.
        event.preventDefault();
        return false;
    }

    public handleScrubberInteraction = (
        scrubberWidth: number,
        offsetX: number,
        shouldPlay: boolean
    ) => {
        const currentTime =
            1000 *
            Math.min(
                this.audioInterface.duration,
                this.cutEndTime,
                Math.max(0, this.cutStartTime, (offsetX * this.audioInterface.duration) / scrubberWidth)
            );

        this.audioInterface.currentTime = currentTime;
        if (shouldPlay) {
            if (!this.isPausedByUser && !this.hasNotStarted) {
                this.audioInterface.play(currentTime);
            }
            this.setIsPlayDisabled(false);
        } else {
            if (this.isPlaying) {
                this.audioInterface.stop();
            }
            this.setIsPlayDisabled(true);
        }
    }

    public onDocumentKeyUp(event: KeyboardEvent) {
        if (
            event.code === 'Space' &&
            this.activePlayerKey === this.playerKey
        ) {
            this.handlePlayButtonClick();
        }
    }

    public onTabVisibilityChange(e) {
        const isHidden = e.currentTarget.hidden;

        if (isHidden) {
            this.wasPlaying = this.isPlaying;
            if (this.audioInterface) {
                this.audioInterface.stop();
            }
        } else {
            if (this.wasPlaying && this.audioInterface) {
                this.audioInterface.play(this.audioInterface.currentTime);
            }
        }
    }

    public setIsPlayDisabled(isPlayDisabled: boolean) {
        this.isPlayDisabled = isPlayDisabled;
    }

    cutStartTimeChanged() {
        this.audioInterface.cutStartTime = this.cutStartTime * 1000;
    }

    cutEndTimeChanged() {
        this.audioInterface.duration = this.cutEndTime * 1000;
    }
}
