<div class="menu"
     [ngStyle]="{'position': isDesktopDevice ? 'fixed' : 'absolute'}">
    <div class="menu-button-wrapper">
        <img src="assets/images/menu/Main.png"
             (click)="scrollToElement(slide_1)"
             [ngClass]="{'menu-button-selected': selectedSlide === 1}"
             alt="Main"
             class="menu-button">
    </div>
    <!--    <div class="menu-button">-->
    <!--        <div (click)="scrollToElement(slide_1, 1)"-->
    <!--             [ngClass]="{'elem-selected': selectedSlide === 1}"-->
    <!--             class="elem">-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="menu-button-wrapper">
        <img src="assets/images/menu/About.png"
             (click)="scrollToElement(slide_2)"
             [ngClass]="{'menu-button-selected': selectedSlide === 2}"
             alt="About"
             class="menu-button">
    </div>
<!--    Disable Tilt for mobile-->
    <div *ngIf="isDesktopDevice"
         aTilt
         (click)="openGambo()"
         class="logo">
        <div style="transform: translateZ(20px)">
            <img src="assets/images/LogoFront.png"
                 alt="LogoFront">
        </div>
    </div>
    <div *ngIf="!isDesktopDevice"
         (click)="openGambo()"
         class="logo">
        <div style="transform: translateZ(20px)">
            <img src="assets/images/LogoFront.png"
                 alt="LogoFront">
        </div>
    </div>
    <div class="menu-button-wrapper">
        <img src="assets/images/menu/Benefits.png"
             (click)="scrollToElement(slide_3)"
             [ngClass]="{'menu-button-selected': selectedSlide === 3}"
             alt="Benefits"
             class="menu-button">
    </div>
    <div class="menu-button-wrapper">
        <img src="assets/images/menu/Contact.png"
             (click)="scrollToElement(slide_4)"
             [ngClass]="{'menu-button-selected': selectedSlide === 4}"
             alt="Contact"
             class="menu-button">
    </div>
</div>

<mat-select [value]="lang"
            (valueChange)="changeLang($event)"
            class="lang-select">
    <mat-option value="en">EN</mat-option>
    <mat-option value="ru">RU</mat-option>
</mat-select>

<div #slide_1 class="container slide_1">
    <div class="text-wrapper">
        <div class="header">
            {{ "QUIZ_MAIN_PAGE.SLIDE_1.HEADER" | translate }}
        </div>
        <div class="text">
            {{ "QUIZ_MAIN_PAGE.SLIDE_1.TEXT" | translate }}
        </div>
        <button (click)="scrollToElement(slide_4)"
                class="move_to_form_button">
            {{ "QUIZ_MAIN_PAGE.SLIDE_1.BUTTON" | translate }}
        </button>
    </div>
    <div class="scene">
        <canvas #canvas
                style="pointer-events: none;">
        </canvas>
    </div>
</div>

<div #slide_2 id="slide_2" class="container slide_2">
    <div id="background-wrapper" class="background-wrapper">
        <div data-depth="0.2" class="big-hexagons"></div>
        <div data-depth="0.6" class="small-hexagons"></div>
    </div>
    <div class="information-wrapper">
        <div class="header">{{ "QUIZ_MAIN_PAGE.SLIDE_2.HEADER" | translate }}</div>
        <div class="text">{{ "QUIZ_MAIN_PAGE.SLIDE_2.TEXT_1" | translate }}</div>
        <div class="text">{{ "QUIZ_MAIN_PAGE.SLIDE_2.TEXT_2" | translate }}</div>
        <div class="text">{{ "QUIZ_MAIN_PAGE.SLIDE_2.TEXT_3" | translate }}</div>
        <div class="text">{{ "QUIZ_MAIN_PAGE.SLIDE_2.TEXT_4" | translate }}</div>
        <button (click)="scrollToElement(slide_4)"
                class="button">
            {{ "QUIZ_MAIN_PAGE.SLIDE_2.BUTTON" | translate }}
        </button>
    </div>
</div>

<div #slide_3 class="container slide_3">
    <div class="slide_3_info_wrapper">
        <div [ngClass]="pageWidth > 991 && pageHeight > 860 ? 'desktop-slide-3-header' : 'mobile-slide-3-header'"
             class="rellax"
             data-rellax-speed="-2">
            {{ "QUIZ_MAIN_PAGE.SLIDE_3.HEADER" | translate }}
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="4"
             [ngClass]="{ 'desktop-hexagon-1': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="eco"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_1" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="9"
             [ngClass]="{ 'desktop-hexagon-2': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="remote"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_2" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="0"
             [ngClass]="{ 'desktop-hexagon-3': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="boxes"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_3" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="2"
             [ngClass]="{ 'desktop-hexagon-4': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="fast"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_4" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="2"
             [ngClass]="{ 'desktop-hexagon-5': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="easy"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_5" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="10"
             [ngClass]="{ 'desktop-hexagon-6': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="worldwide"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_6" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="5"
             [ngClass]="{ 'desktop-hexagon-7': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="unlimited"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_7" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="3"
             [ngClass]="{ 'desktop-hexagon-8': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="unbreakable"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_8" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="10"
             [ngClass]="{ 'desktop-hexagon-9': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="suitcase"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_9" | translate }}</div>
        </div>

        <div class="hexagon rellax"
             data-rellax-speed="9"
             [ngClass]="{ 'desktop-hexagon-10': pageWidth > 991 && pageHeight > 860 }">
            <img src="assets/images/Benefits.svg"
                 alt="BenefitBorder">
            <mat-icon svgIcon="pause"></mat-icon>
            <div class="hexagon-text">{{ "QUIZ_MAIN_PAGE.SLIDE_3.HEXAGON_10" | translate }}</div>
        </div>
    </div>
</div>

<!--<div #slide_3 class="container background_3">
    <div class="third-page-plank">
        <div class="font-size-80">Стоимость</div>
        <div class="light-blue">небольшой коллекции</div>
        <div>картонных настольных игр</div>
        <div class="light-blue">равна стоимости</div>
        <div class="font-size-80">40 лет подписки в Gambo</div>
    </div>
</div>-->

<div #slide_4 class="container slide_4">
    <div class="form-wrapper">
        <div class="header">{{ "QUIZ_MAIN_PAGE.SLIDE_4.HEADER" | translate }}</div>
        <app-beta-access-form></app-beta-access-form>
    </div>
    <div (click)="openInstagram()"
         class="hand">
        <img src="assets/images/background/Landing_4.1.png"
             alt="SocialHand"/>
        <button (click)="openInstagram()"
                class="social subscribe-button">
            {{ "QUIZ_MAIN_PAGE.SLIDE_4.BUTTON" | translate }}
        </button>
        <div class="text">{{ "QUIZ_MAIN_PAGE.SLIDE_4.SOCIAL_TEXT" | translate }}</div>
        <!--<div class="social">
            <mat-icon *ngIf="translate.getDefaultLang() === 'ru'" svgIcon="vk"></mat-icon>
            <mat-icon svgIcon="instagram"></mat-icon>
            <mat-icon svgIcon="facebook"></mat-icon>
        </div>-->
    </div>
    <img src="assets/icons/instagram.svg"
         (click)="openInstagram()"
         class="instagram-small"
         alt="Instagram">
    <div class="footer">
        <div (click)="openGambo()">Gambo</div>
        <a href="mailto:info@ai-partygames.com"
           class="mail">
            info@ai-partygames.com
        </a>
        <a [routerLink]="['/privacy-policy']"
           routerLinkActive="active">
            {{ "QUIZ_MAIN_PAGE.SLIDE_4.POLICY" | translate }}
        </a>
    </div>
</div>
<!--<div #rendererContainer></div>-->
<!--<canvas #canvas (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)">-->
<!--</canvas>-->
