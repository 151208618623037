import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { AuthService } from './auth/auth.service';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { CreateNicknameDialogComponent } from './create-nickname-dialog/create-nickname-dialog.component';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild()
    ],
    declarations: [
        AuthComponent,
        CreateNicknameDialogComponent
    ],
    exports: [
        AuthComponent
    ],
    /*providers: [
        AuthService
    ]*/
})
export class AuthModule {
}
