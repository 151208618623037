import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-nickname-dialog',
  templateUrl: './create-nickname-dialog.component.html',
  styleUrls: ['./create-nickname-dialog.component.scss']
})
export class CreateNicknameDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<CreateNicknameDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: string,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
