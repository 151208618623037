<div *ngIf="quizQuestion"
     class="quiz-question">
    <div class="question-text">
        <div>{{ quizQuestion.getValue() }}</div>
        <div *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_FINISHED && quizQuestion.getAnswerdescriptionvalue().length > 0"
             class="answer-description-text">
            {{ quizQuestion.getAnswerdescriptionvalue() }}
        </div>
    </div>
    <div *ngIf="quizRunnerSocket.isHost || quizRunnerSocket.everyoneWithMedia"
         class="media-wrapper"
         [ngClass]="{ 'audio-class': quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO }">
        <img *ngIf="questionFileSrc && quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_IMAGE"
             [src]="questionFileSrc"
             class="question-image">
        <video *ngIf="questionFileSrc && quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_VIDEO"
               [src]="questionFileSrc"
               [autoplay]="true"
               width="600"
               controls
               type="video/mp4">
        </video>
        <div class="track-line"
             *ngIf="quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO
             && audioBuffers
             && audioShape">
            <app-audio-player [audioBuffers]="audioBuffers"
                              [audioShape]="audioShape"
                              [shouldRewind]="true"
                              [loop]="false"
                              [autoPlay]="false"
                              [playerKey]="'runner'"
                              [stopEvent]="stopMediaSubject">
            </app-audio-player>
        </div>
        <!--<audio *ngIf="questionFileSrc && quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO"
               [autoplay]="true"
               controls>
            <source [src]="domSanitizer.bypassSecurityTrustResourceUrl(questionFileSrc)">
        </audio>-->
    </div>
</div>
