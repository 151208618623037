import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GameUserInfo, QuizQuestion, QuizQuestionAnswerType, QuizQuestionStatus } from '../../../../proto/generated/gambo_pb';
import { QuizRunnerService } from '../quiz-runner.service';
import { AnswerType } from '../../quiz-constructor/quiz-constructor.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
    firstAnsweredPlayersSelector,
    playerAnswerSelector,
    questionSelector,
    questionStatusSelector
} from '../store/quiz-runner.selectors';
import { AppState } from '../../../app/app.state';
import { Subject } from 'rxjs';
import { QuizRunnerSocket } from '../quiz-runner.socket';
// import { QuizRunnerSocket } from '../quiz-runner.socket';

@Component({
    selector: 'app-quiz-runner-answer',
    templateUrl: './quiz-runner-answer.component.html',
    styleUrls: ['./quiz-runner-answer.component.scss']
})
export class QuizRunnerAnswerComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void> = new Subject();

    readonly QuizQuestionStatus = QuizQuestionStatus;
    readonly QuizQuestionAnswerType = QuizQuestionAnswerType;

    public quizQuestion: QuizQuestion;
    public customOrMultiAnswer = '';
    public multiAnswersList = new Array<boolean>();
    public playerAnswer: string;
    public questionStatus: number;

    constructor(
        public quizRunnerService: QuizRunnerService,
        private store: Store<AppState>,
        public quizRunnerSocket: QuizRunnerSocket
    ) {
    }

    ngOnInit(): void {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionSelector)
        ).subscribe(question => {
            this.quizQuestion = question;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playerAnswerSelector)
        ).subscribe(playerAnswer => {
            this.playerAnswer = playerAnswer;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            this.questionStatus = questionStatus;
        });
    }

    answerClickHandler(answer: string) {
        if (this.isAnswered() || !this.quizRunnerSocket.isPlayer) {
            return;
        }
        this.quizRunnerSocket.answerRequest(this.quizQuestion.getNumber(), this.quizQuestion.getAnswertype(), answer);
    }

    changeCustomAnswer(customInputEventTarget: EventTarget) {
        if (this.isAnswered() || !this.quizRunnerSocket.isPlayer) {
            return;
        }
        this.customOrMultiAnswer = (customInputEventTarget as HTMLInputElement).value;
    }

    setCorrectAnswer(i: number) {
        if (this.isAnswered() || !this.quizRunnerSocket.isPlayer) {
            return;
        }
        this.multiAnswersList[i] = !this.multiAnswersList[i];
        this.customOrMultiAnswer = this.multiAnswersList.reduce(
            (accum, current, index, array) => accum + (current ? index : '') + ((current && index < array.length - 1) ? ';' : ''),
            ''
        );
    }

    isAnswered() {
        return this.playerAnswer.length > 0;
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
