import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDragAndDropZone]',
  host: {
    '[style.background-color]': '"rgb(240, 240, 240)"',
    '[style.padding]': '"20px"',
    '[style.border-radius]': '"10px"',
    '[style.text-align]': '"center"'
  }
})
export class DragAndDropZoneDirective {

  @Output() fileDropEvent = new EventEmitter<any>();

  @HostBinding('style.opacity') private opacity = '0.8';
  @HostBinding('style.border') private border = '2px dotted #3F51B5';

  @HostListener('dragover', ['$event'])
  public onDragOver(evt): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '1';
    this.border = '4px dotted #3F51B5';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '0.8';
    this.border = '2px dotted #3F51B5';
  }

  @HostListener('drop', ['$event'])
  public ondrop(evt): any {
    evt.preventDefault();
    evt.stopPropagation();
    this.opacity = '0.8';
    this.border = '2px dotted #3F51B5';
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropEvent.emit(files);
    }
  }
}
