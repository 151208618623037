import { IMessageStream } from './IMessageStream';
import { Subscription, timer, from, of, Subject } from 'rxjs';
import { StreamFreezeReader } from './StreamFreezeReader';
import { switchMap, takeUntil } from 'rxjs/operators';
import { RoomGeneralMessage, RoomMessageType, RoomPingMessage } from '../../../proto/generated/room_pb';
import { environment } from '../../environments/environment';
import { GeneralMessage, MessageType, PingMessage } from '../../../proto/generated/gambo_pb';

type ConnectionStatus = 'connected' | 'connecting' | 'closed';

export class WebSocketConnect implements IMessageStream {
    private socket: WebSocket;
    private connecting = false;
    private opened = false;
    private closed = true;
    private forceclose = false;
    private messages: Array<string | ArrayBufferLike | Blob | ArrayBufferView> = new Array();
    private reconnectable: boolean;
    private url: string;
    private protocol: string | Array<string>;
    private binaryType: 'blob' | 'arraybuffer';
    private ponmessage: Array<(message: any) => void> = [];
    private ponreconnect: Array<() => void> = [];
    private ponconnect: Array<() => void> = [];
    private ponerror: Array<(event: ErrorEvent | CloseEvent) => void> = [];
    private ponclose: Array<(event: CloseEvent) => void> = [];
    private heartbeat: Subscription;
    public isStoppedTimer: Subject<boolean> = new Subject<boolean>();
    // private firstError = 1;
    // private tim;
    // private handl;
    // private firstTry = 10;

    private hearbeatParams: {
        pingMessage: string;
        pingAnswer: string;
        sec: number;
        firstRunDelaySec: number;
        pingAnswerUint8: Uint8Array;
        streamReader?: StreamFreezeReader,
    } = null;

    public get hasHearbeats(): boolean {
        return this.hearbeatParams != null;
    }

    public set onmessage(val: (message: any) => void) {
        if (val) {
            this.ponmessage.push(val);
        }
    }
    public set onreconnect(val: () => void) {
        if (val) {
            this.ponreconnect.push(val);
        }
    }
    public set onconnect(val: () => void) {
        if (val) {
            this.ponconnect.push(val);
        }
    }
    public set onerror(val: () => void) {
        if (val) {
            this.ponerror.push(val);
        }
    }
    public set onclose(val: () => void) {
        if (val) {
            this.ponclose.push(val);
        }
    }

    constructor(
        url: string,
        protocol: string | Array<string>,
        binaryType: 'blob' | 'arraybuffer' = null,
        reconnect: boolean = false,
        onconnect?: () => void,
        onmessage?: () => void,
        onreconnect?: () => void,
    ) {
        this.url = url;
        this.protocol = protocol;
        this.binaryType = binaryType;
        this.onreconnect = onreconnect;
        this.onmessage = onmessage;
        this.onconnect = onconnect;
        this.reconnectable = reconnect;
        // this.handl = () => this.close();
        // globalThis.addEventListener('beforeunload', this.handl);
    }

    public init(reconnect: boolean = false, forceReconnect: boolean = false): WebSocketConnect {
        if (this.connecting || this.forceclose) {
            return;
        }
        if (!forceReconnect) {
            if ((reconnect && !this.reconnectable)) {
                return;
            }
        }
        try {
            this.socket = this.protocol
                ? new WebSocket(this.url, this.protocol)
                : new WebSocket(this.url);
        } catch (e) {
            this.onerrorh(e);
            this.init(true);
            return;
        }
        this.connecting = true;
        this.closed = true;
        this.forceclose = false;
        if (this.socket) {
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onerror = null;
            this.socket.onmessage = null;
        }

        if (reconnect) {
            if (this.ponreconnect) {
                this.ponreconnect.forEach((func, index) => func());
            }
        }
        if (this.binaryType) {
            this.socket.binaryType = this.binaryType;
        }
        this.socket.onopen = this.onopenh.bind(this);
        this.socket.onclose = this.oncloseh.bind(this);
        this.socket.onerror = this.onerrorh.bind(this);
        this.socket.onmessage = this.onmessageh.bind(this);
        // if (this.firstTry--) {
        //     this.tim = setTimeout(
        //         () => {
        //             console.log('Try reconnect by timeout');
        //             this.tim = null;
        //             this.socket.close();
        //             this.aftererrorh();
        //             this.init(true, true);
        //         },
        //         5000
        //     );
        // }
        return this;
    }

    private onopenh(ev: Event) {
        // this.firstError = 1;
        // if (this.tim) {
        //     clearTimeout(this.tim);
        //     this.tim = null;
        // }
        this.opened = true;
        this.connecting = false;
        if (this.ponconnect) {
            this.ponconnect.forEach((func, index) => func.call(null));
        }
        let message: string | ArrayBufferLike | Blob | ArrayBufferView;
        // tslint:disable-next-line:no-conditional-assignment
        while ((message = this.messages.shift())) {
            this.send(message);
        }
    }

    public getConnectingStatus(): ConnectionStatus {
        if (this.opened) {
            return 'connected';
        } else if (this.connecting) {
            return 'connecting';
        }
        return 'closed';
    }

    public getURL(): string {
        return this.url;
    }

    private onmessageh(ev: MessageEvent) {
        if (this.ponmessage) {
            const data = ev.data;
            let toSend = [data];
            if (this.hearbeatParams && !this.hearbeatParams.streamReader) {
                if (typeof data === 'string') {
                    if (data.substr(0, this.hearbeatParams.pingAnswer.length) === this.hearbeatParams.pingAnswer) {
                        return;
                    }
                } else if (data instanceof ArrayBuffer) {
                    const pongAnswer = this.hearbeatParams.pingAnswerUint8;
                    let indexOfHeart = -1;
                    const dataUint8 = new Uint8Array(data);

                    MAINLOOP:
                    for (let i = 0; i < dataUint8.byteLength - pongAnswer.byteLength + 1; i++) {
                        if (
                            dataUint8[i] === pongAnswer[0]
                        ) {
                            for (let j = 1; j < pongAnswer.byteLength; j++) {
                                if (dataUint8[i + j] !== pongAnswer[j]) {
                                    continue MAINLOOP;
                                }
                            }
                            indexOfHeart = i;
                            break;
                        }
                    }

                    if (indexOfHeart > -1) {
                        toSend = [
                            data.slice(0, indexOfHeart),
                            data.slice(indexOfHeart + pongAnswer.byteLength + 1)
                        ];
                        toSend = toSend.filter(el => el.byteLength > 0);
                    }
                }
            }
            toSend.forEach(
                el => this.ponmessage.forEach((func, index) => func(el))
            );
        }
    }

    private aftererrorh() {
        if (this.connecting && !this.forceclose) {
            this.connecting = false;
        }
    }

    private onerrorh(ev: ErrorEvent | CloseEvent) {
        console.log('Error socket', this.url);
        this.opened = false;
        if (!this.forceclose) {
            if (!ev || ev.target !== this.socket) {
                return;
            }
            this.aftererrorh();
            if (this.ponerror) {
                this.ponerror.forEach((func, index) => func(ev));
            }
        }
    }

    private oncloseh(ev: CloseEvent) {
        console.log('Close socket', this.url);
        this.opened = false;
        if (this.heartbeat) {
            this.heartbeat.unsubscribe();
        }
        if (ev.code === 1000 || ev.code === 1006) {
            if (!this.forceclose) {

                this.init(true);

                // setTimeout(() => this.init(true), this.firstError-- ? 1 : 10000);
            }
            if (this.ponclose) {
                this.ponclose.forEach((func, index) => func(ev));
            }
        } else {
            console.log(ev.code);
            console.log(ev.reason);
            this.onerrorh(ev);
        }
    }

    public close() {
        this.disableHeartbeat();
        this.forceclose = true;
        if (this.socket) {
            // this.socket.send('EXIT');
            this.socket.close(1000);
            console.log('EXIT WAS SENT', this.url);
        }

        this.ponmessage = null;
        this.ponreconnect = null;
        this.ponconnect = null;
        this.ponerror = null;
        this.ponclose = null;
    }

    public send(message: string | ArrayBufferLike | Blob | ArrayBufferView) {
        if (this.connecting) {
            this.messages.push(message);
            return;
        }
        if (this.socket) {
            this.socket.send(message);
        }
    }

    public enableHeartbeat(
        type: 'room' | 'game',
        pingMessage: string = 'PING',
        pingAnswer: string = 'PONG',
        sec: number = 5,
        firstRunDelaySec: number = 5,
        streamReader: StreamFreezeReader = null,
    ) {
        const encoder = new TextEncoder();
        const pingAnswerUint8 = encoder.encode(pingAnswer);

        /*this.hearbeatParams = {
            pingMessage,
            pingAnswer,
            sec,
            firstRunDelaySec,
            pingAnswerUint8,
            streamReader
        };*/
        const msg = this.createPingMessage(type);

        this.isStoppedTimer.next(true);
        this.heartbeat = timer(firstRunDelaySec * 1000, sec * 1000)
            .pipe(
                takeUntil(this.isStoppedTimer.asObservable()),
                switchMap(val => {
                    if (streamReader) {
                        return from(streamReader.waitForFreeSocket(pingMessage));
                    }
                    return of(val);
                }),
                switchMap(
                    val => {
                        if (this.getConnectingStatus() === 'connected') {
                            this.send(msg.serializeBinary());
                            /*if (streamReader) {
                                return from(streamReader.asyncReadLine());
                            }*/
                        }
                        return of(val);
                    }
                )
            )
            .subscribe(() => { });
        return this;
    }

    public restartHeartbeat() {
        if (this.hearbeatParams) {
            if (this.heartbeat && !this.heartbeat.closed) {
                return this;
            }
            this.enableHeartbeat(
                'room',
                this.hearbeatParams.pingMessage,
                this.hearbeatParams.pingAnswer,
                this.hearbeatParams.sec,
                this.hearbeatParams.firstRunDelaySec,
                this.hearbeatParams.streamReader
            );
        }
        return this;
    }

    public disableHeartbeat(removeParams: boolean = true) {
        if (this.heartbeat) {
            this.heartbeat.unsubscribe();
        }

        if (removeParams) {
            this.hearbeatParams = null;
        }

        return this;
    }

    public createPingMessage(type: 'room' | 'game'): RoomGeneralMessage {
        let msg;
        if (type === 'room') {
            msg = new RoomGeneralMessage();
            msg.setMessagetype(RoomMessageType.ROOM_MESSAGE_TYPE_PING);
            const pingMsg = new RoomPingMessage();
            msg.setRoompingmessage(pingMsg);
        } else {
            msg = new GeneralMessage();
            msg.setMessagetype(MessageType.MESSAGE_TYPE_PING);
            const pingMsg = new PingMessage();
            msg.setPingmessage(pingMsg);
        }
        msg.setCookie(localStorage.getItem('sid'));
        if (localStorage.getItem('playableHostId') && localStorage.getItem('isHost') !== 'true') {
            msg.setConnectionid(localStorage.getItem('playableHostId'));
        }
        msg.setAppversion(environment.appVersion);
        return msg;
    }
}
