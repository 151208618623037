import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizTimerComponent } from './quiz-timer/quiz-timer.component';
import { QuizRunnerService } from './quiz-runner.service';
import { QuizRunnerQuestionComponent } from './quiz-runner-question/quiz-runner-question.component';
import { QuizRunnerAnswerComponent } from './quiz-runner-answer/quiz-runner-answer.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';
import { StoreModule } from '@ngrx/store';
import { quizRunnerReducer } from './store/quiz-runner.reducer';
import { EffectsModule } from '@ngrx/effects';
import { QuizRunnerEffects } from './store/quiz-runner.effects';
import { QuizRunnerComponent } from './quiz-runner/quiz-runner.component';
import { FileManagerService } from '../../libs/file-manager/file-manager.service';
import { QuizRunnerAnsweredPlayersComponent } from './quiz-runner-answered-players/quiz-runner-answered-players.component';
import { QuizRunnerScoreboardComponent } from './quiz-runner-scoreboard/quiz-runner-scoreboard.component';
import { QuizRunnerPlayersComponent } from './quiz-runner-players/quiz-runner-players.component';
import { AudioEditorModule } from '../audio-editor/audio-editor.module';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { HeaderModule } from '../header/header.module';

@NgModule({
    declarations: [
        QuizRunnerComponent,
        QuizTimerComponent,
        QuizRunnerQuestionComponent,
        QuizRunnerAnswerComponent,
        QuizRunnerAnsweredPlayersComponent,
        QuizRunnerScoreboardComponent,
        QuizRunnerPlayersComponent,
        StatusBarComponent
    ],
    /*exports: [
        QuizTimerComponent,
        QuizRunnerAnswerComponent,
        QuizRunnerQuestionComponent
    ],*/
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        StoreModule.forFeature('quizRunnerFeature', {
            quizRunner: quizRunnerReducer
        }),
        EffectsModule.forFeature([
            QuizRunnerEffects
        ]),
        AudioEditorModule,
        HeaderModule
    ],
    providers: [
        QuizRunnerService
    ]
})
export class QuizRunnerModule { }
