import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-topic-selector-dialog',
    templateUrl: './topic-selector-dialog.component.html',
    styleUrls: ['./topic-selector-dialog.component.scss']
})
export class TopicSelectorDialogComponent {

    public topics = new Array<string>();
    // public topics = ['Science', 'Math', 'Songs'];
    public selectedTopic = '';
    public customTopic = '';

    constructor(
        public dialogRef: MatDialogRef<TopicSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string,
        private httpClient: HttpClient
    ) {
        this.httpClient.get<{topics: Array<string>}>(
            environment.URLPrefix + '/quiz/topics',
            {}
        ).subscribe(result => {
            if (result.topics) {
                this.topics = result.topics;
                this.data = result.topics[0];
            }
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    changeTopicHandler(selectedTopic: string) {
        this.selectedTopic = selectedTopic;
        if (selectedTopic !== 'custom') {
            this.data = selectedTopic;
        } else {
            this.data = this.customTopic;
        }
    }

    changeCustomTopicHandler(eventTarget: EventTarget) {
        this.customTopic = (eventTarget as HTMLInputElement).value;
        this.data = this.customTopic;
    }
}
