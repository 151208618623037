import { TranslateLoaderWithLangAutodetect } from './translate-loader-with-lang-autodetect';
import { HttpClient } from '@angular/common/http';
import { I18NDefaultLangService } from './i18n-default-lang.service';
import { TranslateLoader } from '@ngx-translate/core';

export type TranslateLoaderFactoryType = (prefix: string, suffix?: string) => TranslateLoader;

export const TranslateLoaderFactory = (http: HttpClient) => (
    (prefix: string, suffix?: string) => (
        new TranslateLoaderWithLangAutodetect(I18NDefaultLangService.GetDefaultLanguage(), http, prefix, suffix)
    )
);
