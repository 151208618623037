import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizPromoComponent } from './quiz-promo.component';
// import { AngularTiltModule } from 'angular-tilt';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    declarations: [
        QuizPromoComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        // AngularTiltModule
    ]
})
export class QuizPromoModule {}
