import { NgModule } from '@angular/core';
import { GamesComponent } from './games/games.component';
import { GameComponent } from './game/game.component';
import { GamesService } from './games/games.service';
import { CommonModule } from '@angular/common';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild()
    ],
    declarations: [
        GamesComponent,
        GameComponent
    ],
    providers: [
        GamesService
    ]
})
export class GamesModule {
    constructor() {
    }
}
