import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
    TranslateModule,
    TranslateLoader,
    TranslateModuleConfig,
    MissingTranslationHandler
} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { MultiTranslateLoader } from './multi-translate-loader';
import { TranslateLoaderFactory, TranslateLoaderFactoryType } from './translate-loader-factory';
import { I18NDefaultLangService } from './i18n-default-lang.service';
import { MissingTranslationService } from './missing-tranlsation-service';

export const I18N_INJECT_CONFIG_TOKEN = 'i18n_inject_config_token';
export const I18N_INJECT_HTTP_LOADER_FOR_MULTI_LOADER = 'i18n_inject_http_for_multi_loader';

@NgModule({
    imports: [
        BrowserModule,
        TranslateModule.forRoot(
            I18NModule.GetLoaderConfig()
        )
    ],
    providers: [],
    exports: [
        TranslateModule
    ]
})
export class I18NModule {

    constructor() {
        /*let lang = 'en';
        if (localStorage.getItem('selectedLang')) {
            lang = localStorage.getItem('selectedLang');
        } else if (I18NDefaultLangService.GetDefaultLanguage()) {
            this.lang = 'ru';
        }
        translate.setDefaultLang(this.lang);*/
    }

    static forRoot(): ModuleWithProviders<I18NModule> {
        return {
            ngModule: I18NModule,
            providers: [
                {
                    provide: I18N_INJECT_CONFIG_TOKEN,
                    useValue: '../i18n/',
                    multi: true
                },
                I18NDefaultLangService,
                {
                    provide: I18N_INJECT_HTTP_LOADER_FOR_MULTI_LOADER,
                    deps: [HttpClient],
                    useFactory: TranslateLoaderFactory
                }
            ]
        };
    }

    static forRootOnlyChildLang(): ModuleWithProviders<I18NModule> {
        return {
            ngModule: I18NModule,
            providers: [
                I18NDefaultLangService,
                {
                    provide: I18N_INJECT_HTTP_LOADER_FOR_MULTI_LOADER,
                    deps: [HttpClient],
                    useFactory: TranslateLoaderFactory
                }
            ]
        };
    }

    static forChild(): ModuleWithProviders<I18NModule> {
        return {
            ngModule: I18NModule,
            providers: [
                {
                    provide: I18N_INJECT_CONFIG_TOKEN,
                    useValue: '../../i18n/',
                    multi: true
                }
            ]
        };
    }

    public static GetLoaderConfig(): TranslateModuleConfig {
        return {
            loader: {
                provide: TranslateLoader,
                deps: [I18N_INJECT_CONFIG_TOKEN, I18N_INJECT_HTTP_LOADER_FOR_MULTI_LOADER],
                useFactory: (
                    conf: string[],
                    factory: TranslateLoaderFactoryType
                ) => new MultiTranslateLoader(
                    conf,
                    factory
                )
            },
            defaultLanguage: I18NDefaultLangService.GetDefaultLanguage(),
            useDefaultLang: true,
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MissingTranslationService}
        };
    }

    public static GetLangProviderForPath(...args: string[]) {
        const path = `./${args.filter(v => v !== '').join('/')}/`;
        return {
            provide: I18N_INJECT_CONFIG_TOKEN,
            useValue: path,
            multi: true
        };
    }
}

