<div class="content">
    <h1>{{ "BETA_ACCESS_SHARE.HEADER" | translate }}</h1>
    <p>{{ "BETA_ACCESS_SHARE.DESCRIPTION" | translate }}</p>
    <div class="share-buttons-wrapper">
        <button (click)="sendShareTag('facebook')"
                mat-fab
                shareButton="facebook"
                #fbBtn="shareButton"
                [style.backgroundColor]="fbBtn?.color">
            <fa-icon *ngIf="fbBtn" [icon]="fbBtn.icon" size="lg"></fa-icon>
        </button>
        <button (click)="sendShareTag('twitter')"
                mat-fab
                shareButton="twitter"
                #twtBtn="shareButton"
                [style.backgroundColor]="twtBtn?.color">
            <fa-icon *ngIf="twtBtn" [icon]="twtBtn.icon" size="lg"></fa-icon>
        </button>
        <button (click)="sendShareTag('vk')"
                mat-fab
                shareButton="vk"
                #vkBtn="shareButton"
                [style.backgroundColor]="vkBtn?.color">
            <fa-icon *ngIf="vkBtn" [icon]="vkBtn.icon" size="lg"></fa-icon>
        </button>
        <button (click)="sendShareTag('telegram')"
                mat-fab
                shareButton="telegram"
                #tgBtn="shareButton"
                [style.backgroundColor]="tgBtn?.color">
            <fa-icon *ngIf="tgBtn" [icon]="tgBtn.icon" size="lg"></fa-icon>
        </button>
        <button (click)="sendShareTag('whatsapp')"
                mat-fab
                shareButton="whatsapp"
                #waBtn="shareButton"
                [style.backgroundColor]="waBtn?.color">
            <fa-icon *ngIf="waBtn" [icon]="waBtn.icon" size="lg"></fa-icon>
        </button>
    </div>
    <button mat-mini-fab
            (click)="closeDialog()"
            color="accent"
            class="close">
        <mat-icon>close</mat-icon>
    </button>
</div>
