<div class="service-name"
     (click)="redirectMainPage()">
    <span>Ai</span>Party
</div>
<app-progress [numberOfQuestions]="numberOfQuestions"
              [questionNumber]="questionNumber">
</app-progress>
<!--<div class="nickname">
    {{ nickname ?? '' }}
</div>-->
<!--<mat-icon [title]="'Menu'"
          [matMenuTriggerFor]="menu">
    menu
</mat-icon>-->
<img *ngIf="user"
     [src]="user.getAvatarurl()"
     [matMenuTriggerFor]="menu"
     class="avatar-image">
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="redirectMyQuizzes()">{{ 'QUIZ.QUIZ_RUNNER.MENU.GAMES' | translate }}</button>
<!--    <button mat-menu-item>{{ 'QUIZ.QUIZ_RUNNER.MENU.ACCOUNT' | translate }}</button>-->
    <button mat-menu-item (click)="signOut()">{{ 'QUIZ.QUIZ_RUNNER.MENU.SIGN_OUT' | translate }}</button>
</mat-menu>
