<div class="quiz-list-wrapper">
    <div class="header">{{ "QUIZ.MY_QUIZZES" | translate }}</div>
    <div *ngIf="userRooms.length">
        <div class="user-rooms-wrapper">
            <div *ngFor="let room of userRooms"
                 class="user-room"
                 (click)="open(room)">
                <div>{{ room.roomName }}</div>
                <div>{{ room.roomStatus }}</div>
                <button *ngIf="room.roomStatus !== 'finished'"
                        (click)="open(room)">
                    Return to game
                    <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
