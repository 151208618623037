<div class="wrapper">
    <div class="header-wrapper">
        <div class="service-name">
            <span>Ai</span>Party
        </div>
        <div class="account-wrapper">
            <div (click)="startGame()"
                 class="control-button title-play-button">
                Start game
                <mat-icon class="mat-icon-rounded">add_circle</mat-icon>
            </div>
            <img *ngIf="user"
                 [src]="user.avatarUrl"
                 [matMenuTriggerFor]="menu"
                 class="avatar-image">
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="redirectMyQuizzes()">{{ 'QUIZ.QUIZ_RUNNER.MENU.GAMES' | translate }}</button>
<!--                <button mat-menu-item>{{ 'QUIZ.QUIZ_RUNNER.MENU.ACCOUNT' | translate }}</button>-->
                <button mat-menu-item (click)="signOut()">{{ 'QUIZ.QUIZ_RUNNER.MENU.SIGN_OUT' | translate }}</button>
            </mat-menu>
        </div>
    </div>
    <div class="title-wrapper">
        <div class="text-wrapper">
            <div class="text">Сервис квизов для вечеринок</div>
            <div class="subtext">Квизы от нейросети! Запусти и играй с друзьями. Новый сервис с уникальными вопросами от нейросети!</div>
            <div (click)="startGame()"
                 class="control-button header-play-button">
                Start game
                <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
            </div>
        </div>
        <img src="assets/images/partygames/circle.svg" style="height: 100%; right: 0;">
        <!--<svg width="790" height="547" viewBox="0 0 790 547" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.2" cx="420.5" cy="318.5" r="420.5" fill="white"/>
        </svg>
        <svg width="679" height="547" viewBox="0 0 679 547" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.2" cx="339.5" cy="318.5" r="339.5" fill="white"/>
        </svg>-->
        <img src="assets/images/partygames/gameplay-2.svg" class="gameplay-2">
        <img src="assets/images/partygames/gameplay-1.svg" class="gameplay-1">
        <img src="assets/images/partygames/gameplay-3.svg" class="gameplay-3">
        <img src="assets/images/partygames/cup.svg" style="top: -80px; right: -250px;">
        <img src="assets/images/partygames/lightning.svg" style="top: -45px; left: 50%;">
        <img src="assets/images/partygames/gamepad.svg" style="left: -200px; bottom: -150px;">
    </div>
    <div class="info-wrapper">
        <div><img src="assets/images/partygames/ai-smartphone.png">Увлекательные квизы с уникальными вопросами, созданными нейросетью.</div>
        <div>Для друзей, которые любят весело проводить время и хотят испытать свои знания!<img src="assets/images/partygames/gift.png"></div>
        <div><img src="assets/images/partygames/rocket.png">Играй в неограниченное количество квизов на разные темы</div>
        <div>Запускай квиз на большом экране или только с мобильных устройств!<img src="assets/images/partygames/responsive.png"></div>
<!--        <div>🌟 Улучшайте свои знания и проводите время с удовольствием!</div>-->
<!--        <div>Не упустите возможность сделать ваш вечер незабываемым с нашим сервисом квизов от нейросети! 🚀</div>-->
    </div>
    <div class="how-it-works-wrapper">
        <div class="text">Как это работает?</div>
        <div class="subtext">Играй с друзьями в пару кликов</div>
        <img src="assets/images/partygames/wheel.svg" style="bottom: -150px; right: -200px;">
        <img src="assets/images/partygames/gameboy-cropped.svg" style="width: 450px; left: -250px; top: -250px;">
        <div class="steps">
            <div class="step">
                <div class="step-number">
                    01
                </div>
                <div class="step-text">
                    Создайте игру по кнопке на этой странице, выбрав тему вопросов и способ проведения игры
                </div>
            </div>
            <div class="step">
                <div class="step-number">
                    02
                </div>
                <div class="step-text">
                    Все участники сканируют QR-код, чтобы присоединиться
                </div>
            </div>
            <div class="step">
                <div class="step-number">
                    03
                </div>
                <div class="step-text">
                    Запускаете игру
                </div>
            </div>
        </div>
    </div>
    <div class="tariff-container">
        <div class="tariff-plan free">
            <h2>Бесплатный Тариф</h2>
            <p class="price">Бесплатно</p>
            <ul>
                <li>Игра с любого устройства</li>
                <li>Возможность показа на большом экране</li>
                <!--<li>Играйте в предустановленные темы</li>
                <li>Не более двух игр в день</li>-->
            </ul>
        </div>

        <div class="tariff-plan paid">
            <h2>Платная Подписка</h2>
            <p class="price">100 рублей/мес</p>
            <ul>
                <li>Игра с любого устройства</li>
                <li>Возможность показа на большом экране</li>
                <li>Генерация квизов на любую тему</li>
                <li>Неограниченное количество игр</li>
            </ul>
        </div>
    </div>
    <div class="footer-wrapper">
        <div class="service-name">
            <span>Ai</span>Party
            <br>
            <a routerLink="/privacy-policy"
               routerLinkActive="active"
               style="font-size: 16px;">
                Политика конфиденциальности
            </a>
        </div>
    </div>
    <!--<div class="play-wrapper">
        <button>Play</button>
    </div>-->
</div>
