import {
    AudioContext,
    IAudioBufferSourceNode,
    IGainNode
} from 'standardized-audio-context';
import { DEFAULT_AUDIO_DURATION, DEFAULT_SAMPLE_RATE } from './constants';

export class AudioInterface {
    audioBuffers: AudioBuffer[];
    audioContext: AudioContext;
    currentTime: number;
    duration: number;
    gainNodes?: Array<IGainNode<AudioContext>>;
    mix: number;
    mixLevels: number[];
    onEndedCallback: any;
    playCallback: any;
    stopCallback: any;
    requestAnimFrameId?: number;
    sourceNodes?: Array<IAudioBufferSourceNode<AudioContext>>;
    startTime?: number;
    cutStartTime?: number;

    constructor({
                    audioBuffers,
                    mix,
                    playCallback,
                    stopCallback,
                    onEndedCallback
                }) {
        this.audioBuffers = [];
        this.audioContext = new AudioContext({sampleRate: DEFAULT_SAMPLE_RATE});
        // We don’t use the audioContext.currentTime, because it is it doesn’t
        // reflect the current time of the playback, but rather the time since the
        // audioContext was created. Instead, set up our own timer that we update
        // after every paint.
        this.currentTime = 0;
        this.duration = DEFAULT_AUDIO_DURATION;
        this.gainNodes = [];
        this.onEndedCallback = onEndedCallback;
        this.playCallback = playCallback;
        this.stopCallback = stopCallback;
        this.sourceNodes = [];

        this.reset(audioBuffers, mix);
    }

    clearTimer = (rewind?: boolean) => {
        // Clear the startTime so that updateTimer can set it fresh.
        this.startTime = null;
        if (rewind) {
            this.currentTime = this.cutStartTime || 0;
        }
        // Pause the timer by cancelling the requestAnimationFrame.
        cancelAnimationFrame(this.requestAnimFrameId);
        this.requestAnimFrameId = null;
    }

    close = () => {
        this.stop();
        this.audioContext.close();
    }

    getLevels = (value: number) => {
        if (value === 100) {
            return [1, 0];
        }
        // Gain should be betwen 0 (muted) and 1. But because the gain
        // isn’t cumulative (ie, two nodes at 50% volume will produce a
        // mix at 50% volume, not 100%), the node with the higher mix
        // value should be set to 1, and the node with the lower value
        // should be set to the mix value normalized between 0 and 1.
        const mix = value / 50;
        const mixLevels = [1, value >= 50 ? 2 - mix : mix];
        if (value <= 50) {
            mixLevels.reverse();
        }
        return mixLevels;
    }

    play = (time: number) => {
        // Browsers may suspend the audioContext after it‘s creation, so
        // resume it here after user interaction.
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
        if (!time) {
            this.clearTimer(true);
        }
        for (const index of this.sourceNodes.keys()) {
            // The start method will throw an error if we havn’t called
            // .stop() on a playing node, and audioContext doesn’t provide
            //  a way to check, so we invoke try/catch to avoid extra logic.
            try {
                // Divide `time` by 1000, because audioContext tracks time in
                // seconds instead of milliseconds.
                if (this.sourceNodes[index]) {
                    this.sourceNodes[index].start(0, time / 1000);
                }
            } catch {
                this.clearTimer();
            }
        }
        this.startTimer();
        this.playCallback();
    }

    reset = (audioBuffers: Float32Array[], mix: number, isNew = false) => {
        // If we already have an AudioNode, we need to stop the playback and
        // then create a new AudioNode, because buffers can only be set once.
        if (this.sourceNodes.length) {
            this.sourceNodes[0].onended = null;
        }
        this.gainNodes = [];
        this.sourceNodes = [];
        if (!audioBuffers || !audioBuffers[0]) {
            return;
        }
        const mixLevels = this.getLevels(mix ? mix : this.mix);
        for (const index of audioBuffers.keys()) {
            // Create gain and source nodes, and store them in arrays.
            this.gainNodes[index] = this.audioContext.createGain();
            this.sourceNodes[index] = this.audioContext.createBufferSource();
            if (!this.audioBuffers[index] || isNew) {
                // creating new buffers
                this.audioBuffers[index] = null;
                this.audioBuffers[index] = this.audioContext.createBuffer(
                    1,
                    audioBuffers[index].length,
                    this.audioContext.sampleRate
                );
                this.audioBuffers[index].copyToChannel(audioBuffers[index], 0, 0);
            }
            // Create a buffer from the audioBuffer data.
            this.sourceNodes[index].buffer = this.audioBuffers[index];
            // Connect the source node to the gain node to control volume.
            this.sourceNodes[index].connect(this.gainNodes[index]);

            // Set the gain according to the mix.
            this.gainNodes[index].gain.value = mixLevels[index];
            // Connect the gain node to the destination (audio output).
            this.gainNodes[index].connect(this.audioContext.destination);
        }
        this.duration = this.audioBuffers[0].duration;
        this.sourceNodes[0].onended = _ => {
            this.reset(audioBuffers, mix);
            this.onEndedCallback();
        };
    }

    startTimer = () => {
        if (!this.requestAnimFrameId) {
            requestAnimationFrame(this.updateTimer);
        }
    }

    stop = () => {
        for (const index of this.sourceNodes.keys()) {
            // The stop method will throw an error if we havn’t called
            // .start() first, and audioContext doesn’t provide a way
            // to check, so we invoke try/catch to avoid any extra logic.
            try {
                this.sourceNodes[index].stop();
                this.clearTimer();
            } catch {
                this.clearTimer();
            }
        }
        this.stopCallback();
    }

    updateMix = (value: number) => {
        if (this.gainNodes.length !== 2) {
            return;
        }
        const mixLevels = this.getLevels(value);
        // this.gainNodes[0] is the transformation audio, and [1]
        // is the input audio.
        this.gainNodes[0].gain.value = mixLevels[0];
        this.gainNodes[1].gain.value = mixLevels[1];
    }

    updateTimer = (timestamp: number) => {
        this.startTime = this.startTime || timestamp - this.currentTime;
        const adjustedTimestamp = timestamp - this.startTime;
        this.currentTime = adjustedTimestamp;
        // Recursively call this function after every paint.
        this.requestAnimFrameId = requestAnimationFrame(this.updateTimer);
    }
}
