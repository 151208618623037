import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateService,
    TranslateLoader
} from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, of, empty, throwError } from 'rxjs';
import { take, catchError, shareReplay, refCount, tap, map, publishReplay } from 'rxjs/operators';
import { transition } from '@angular/animations';
import { mergeDeep } from './object-operations';

@Injectable()
export class MissingTranslationService implements MissingTranslationHandler {
    private hasBasicLoadedLang = false;
    private langDownloader: Observable<void>;

    constructor(private translateLoader: TranslateLoader) {
    }

    public handle(params: MissingTranslationHandlerParams): string | Observable<string> {
        const translateService = params.translateService;
        const lang = translateService.currentLang || translateService.defaultLang;

        if (
            lang !== 'ru'
            && lang !== 'en'
            && !this.hasBasicLoadedLang
        ) {
            return this.getLangDownloader(translateService, lang)
                .pipe(
                    map(
                        () => {
                            return translateService.instant(params.key, params.interpolateParams) || params.key;
                        }
                    ),
                    catchError(() => params.key)
                )
        }
        return params.key;
    }

    private getLangDownloader(translateService: TranslateService, lang: string): Observable<void> {
        return this.langDownloader
            || (
                this.langDownloader = this.translateLoader.getTranslation('en')
                    .pipe(
                        tap(() => this.hasBasicLoadedLang = true),
                        map(
                            translations => {
                                const oldTranslation = translateService.translations;
                                const merged = mergeDeep(translations, oldTranslation[lang]);
                                translateService.setTranslation(lang, merged, false);
                            }
                        ),
                        catchError((err) => {
                            this.hasBasicLoadedLang = true;
                            return throwError(err);
                        }),
                        publishReplay(1),
                        refCount()
                    )
            )
    }

}
