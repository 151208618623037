import { FriendsService } from './friends/friends.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FriendsComponent } from './friends/friends.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild()
    ],
    declarations: [
        FriendsComponent
    ],
    providers: [
        FriendsService
    ]
})
export class FriendsModule {
    constructor() {
    }
}
