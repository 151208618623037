import { Action } from '@ngrx/store';
import { QuizCurrentStateMessageResponse, QuizMoveMessageResponse, QuizQuestion } from '../../../../proto/generated/gambo_pb';

export enum QuizRunnerActionType {
    SetCurrentStateResponse = '[QuizRunner] Set Current State Response',
    SetQuizMoveResponse = '[QuizRunner] Set Quiz Move Response',
    SetQuestion = '[QuizRunner] Set Question',
    SetQuestionFileSrc = '[QuizRunner] Set Question File Blob',
}

export class SetCurrentStateResponse implements Action {
    readonly type = QuizRunnerActionType.SetCurrentStateResponse;

    constructor(public quizCurrentStateMessageResponse: QuizCurrentStateMessageResponse) { }
}

export class SetQuizMoveResponse implements Action {
    readonly type = QuizRunnerActionType.SetQuizMoveResponse;

    constructor(public quizMoveMessageResponse: QuizMoveMessageResponse) { }
}

export class SetQuestion implements Action {
    readonly type = QuizRunnerActionType.SetQuestion;

    constructor(public quizQuestion: QuizQuestion) { }
}

export class SetQuestionFileSrc implements Action {
    readonly type = QuizRunnerActionType.SetQuestionFileSrc;

    constructor(public questionFileBlob: Blob) { }
}

export type QuizRunnerActions = SetCurrentStateResponse
    | SetQuizMoveResponse
    | SetQuestion
    | SetQuestionFileSrc;
