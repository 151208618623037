import { Injectable } from '@angular/core';
import { getBlob, getDownloadURL, getStorage, ref, uploadBytes } from '@angular/fire/storage';
import { UploadResult } from 'firebase/storage';
import { Auth, getAuth } from '@angular/fire/auth';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseApp } from '@angular/fire/app';

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {

    private firebaseAuth: Auth;

    constructor(private firebaseApp: FirebaseApp) {
        this.firebaseAuth = getAuth(firebaseApp);
    }

    public uploadMediaFile(
        fileBlob: Blob,
        shareLink?: string,
        ownerId?: string
    ): Promise<UploadResult> {
        const auth = getAuth();
        ownerId = ownerId ?? auth.currentUser.uid;
        const fileUrl = 'users/' + ownerId + '/quiz/' + uuidv4() + '.' + fileBlob.type.split('/')[1];
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);
        if (shareLink) {
            return uploadBytes(fileRef, fileBlob, {
                customMetadata: {
                    creator: auth.currentUser.uid,
                    shareLink
                }
            });
        } else {
            return uploadBytes(fileRef, fileBlob);
        }
    }

    public downloadFile(fileUrl: string): Promise<string> {
        // const auth = getAuth();
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);
        return getDownloadURL(fileRef);
    }

    public downloadFileBlob(fileUrl: string): Promise<Blob> {
        // const auth = getAuth();
        const storage = getStorage();
        const fileRef = ref(storage, fileUrl);
        return getBlob(fileRef);
    }
}
