import { Injectable } from '@angular/core';
import { WebSocketConnect } from '../../libs/websocket/WebSocketConnect';
import { StreamReader } from '../../libs/websocket/StreamReader';
import { environment } from '../../environments/environment';
import {
    GeneralMessage,
    MessageType,
    PingMessage,
    QuizAction,
    QuizCurrentStateMessageResponse,
    QuizMoveMessageRequest,
    QuizMoveMessageResponse, QuizQuestion, QuizQuestionContentType, QuizStartGameMessageRequest
} from '../../../proto/generated/gambo_pb';
import { BehaviorSubject, Subject } from 'rxjs';
import { getDownloadURL, getMetadata, getStorage, ref } from '@angular/fire/storage';
import { getAuth } from 'firebase/auth';
import { AppState } from '../../app/app.state';
import { Store } from '@ngrx/store';
import * as quizRunnerActions from './store/quiz-runner.actions';
import { FileManagerService } from '../../libs/file-manager/file-manager.service';
// import { FirebaseApp } from '@angular/fire/app';
// import { Auth, getAuth } from '@angular/fire/auth';

@Injectable()
export class QuizRunnerService {

    // private ws: WebSocketConnect;
    // private streamReader: StreamReader;
    // public isHost = false;
    // public quizMoveMessageResponseSubject = new Subject<QuizMoveMessageResponse>();
    // public quizCurrentStateMessageResponseSubject = new Subject<QuizCurrentStateMessageResponse>();
    // public remainingTimeSubject = new Subject<number>();

    // auth: Auth;

    constructor() {
    }

    public isMediaQuestion(quizQuestionContentType: number) {
        return quizQuestionContentType === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_IMAGE ||
            quizQuestionContentType === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_VIDEO ||
            quizQuestionContentType === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO;
    }
}
