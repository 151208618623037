import { Component, Input } from '@angular/core';
import { ShortGameInfo } from '../games/games.service';

@Component({
    selector: 'app-game',
    templateUrl: './game.component.html',
    styleUrls: ['./game.component.scss']
})
export class GameComponent {

    @Input() public shortGameInfo: ShortGameInfo;

    constructor() {
    }
}
