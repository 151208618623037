<mat-progress-bar *ngIf="this.processing"
                  mode="indeterminate">
</mat-progress-bar>
<ng-container disabled="this.processing">
    <!--<audio *ngIf="shortAudioFileSrc"
           #shortAudio
           controls>
        <source #shortAudioSource
                [src]="domSanitizer.bypassSecurityTrustResourceUrl(shortAudioFileSrc)">
    </audio>-->
    <div class="track-line">
        <app-audio-player *ngIf="audioBuffers"
                          [audioBuffers]="audioBuffers"
                          [audioShape]="audioShape"
                          [playerKey]="'editor'"
                          [shouldRewind]="true"
                          [autoPlay]="false"
                          [loop]="true">
        </app-audio-player>
    </div>
    <mat-slider [discrete]="true"
                [min]="0"
                [max]="audioLength"
                class="cut-slider">
        <input [(ngModel)]="startCutValue" matSliderStartThumb>
        <input [(ngModel)]="endCutValue" matSliderEndThumb>
    </mat-slider>
    <button mat-raised-button
            (click)="cropFile()">
        Cut
    </button>
    <button (click)="processToVoice()"
            mat-raised-button
            color="primary">
        Leave a voice melody
    </button>
    <button (click)="processToMelody()"
            mat-raised-button
            color="primary">
        Leave a melody
    </button>
    <button (click)="processToMelodyWithVoice()"
            mat-raised-button
            color="primary">
        Leave a melody with a voice melody
    </button>
    <button (click)="reverseAudio()"
            mat-raised-button
            color="primary">
        Reverse file
    </button>
    <button (click)="resetFileChanges()"
            mat-raised-button
            color="accent">
        Set initial file
    </button>
</ng-container>
