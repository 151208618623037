import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { QuizRunnerService } from '../quiz-runner.service';
import { Subject, Subscription } from 'rxjs';
import { ShortGameInfo } from '../../games/games/games.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { questionStatusSelector, remainingTimeSelector } from '../store/quiz-runner.selectors';
import { AppState } from '../../../app/app.state';
import { QuizQuestionStatus } from '../../../../proto/generated/gambo_pb';

@Component({
    selector: 'app-quiz-timer',
    templateUrl: './quiz-timer.component.html',
    styleUrls: ['./quiz-timer.component.scss']
})
export class QuizTimerComponent implements OnInit, OnDestroy {

    readonly QuizQuestionStatus = QuizQuestionStatus;

    public questionStatus: number;
    public remainingTime: number;

    private unsubscribeSubject: Subject<void> = new Subject();

    constructor(
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(remainingTimeSelector)
        ).subscribe(remainingTime => {
            console.log('TIMER REMAINING TIME', remainingTime);
            this.remainingTime = remainingTime;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            console.log('TIMER QUESTION STATUS', questionStatus);
            this.questionStatus = questionStatus;
        });

        setInterval(() => {
            if (this.remainingTime > 0) {
                this.remainingTime--;
            }
        }, 1000);
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
