import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return combineLatest([
            this.authService.authSubject,
            this.authService.checkAuthSubject
        ]).pipe(
            filter(([authed, checkAuth]) => !checkAuth || authed),
            map(([authed, checkAuth]) => authed),
            take(1),
            map(result => {
                console.log('RESULT CHECKING GUARD', result);
                return result ? true : this.router.parseUrl('/auth');
            })
        );
    }
}
