import { AudioEditorActions, AudioEditorActionType, SendToProcessingSuccess } from './audio-editor.actions';
import { ProcessingType } from '../audio-editor.service';

export interface AudioEditorState {
    selectedFile: Blob;
    initialFile: Blob;
    croppedFile: Blob;
    processedVoiceFile: Blob;
    processedMelodyFile: Blob;
    processedMelodyWithVoice: Blob;
    reversedFile: Blob;
}

export const initialState: AudioEditorState = {
    selectedFile: null,
    initialFile: null,
    croppedFile: null,
    processedVoiceFile: null,
    processedMelodyFile: null,
    processedMelodyWithVoice: null,
    reversedFile: null
};

export type FileType = 'initial' | 'cropped' | 'processedVoice' | 'processedMelody' | 'reversed';

export function audioEditorReducer(state = initialState, action: AudioEditorActions): AudioEditorState {
    switch (action.type) {
        case AudioEditorActionType.ChangeSelectedFile:
            let selectedFile;
            switch (action.fileType) {
                case 'initial':
                    selectedFile = state.initialFile;
                    break;
                case 'cropped':
                    selectedFile = state.croppedFile;
                    break;
                case 'processedVoice':
                    selectedFile = state.processedVoiceFile;
                    break;
                case 'processedMelody':
                    selectedFile = state.processedMelodyFile;
                    break;
            }
            return {
                ...state,
                selectedFile
            };
        case AudioEditorActionType.SetInitialFile:
            return {
                ...state,
                initialFile: action.file,
                selectedFile: action.file
            };
        case AudioEditorActionType.SetCroppedFile:
            return {
                ...state,
                croppedFile: action.file,
                selectedFile: action.file
            };
        case AudioEditorActionType.SetReversedFile:
            return {
                ...state,
                reversedFile: action.file,
                selectedFile: action.file
            };
        case AudioEditorActionType.GetProcessedFileSuccess:
            if (action.processingType === 'transfer') {
                return {
                    ...state,
                    processedVoiceFile: action.file,
                    selectedFile: action.file
                };
            } else {
                return {
                    ...state,
                    processedMelodyFile: action.file,
                    selectedFile: action.file
                };
            }
        default:
            return state;
    }
}
