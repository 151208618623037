import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app/app.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { answeredPlayersListSelector, playersListSelector, questionStatusSelector } from '../store/quiz-runner.selectors';
import { GameUserInfo, QuizQuestionStatus } from '../../../../proto/generated/gambo_pb';

@Component({
    selector: 'app-quiz-runner-answered-players',
    templateUrl: './quiz-runner-answered-players.component.html',
    styleUrls: ['./quiz-runner-answered-players.component.scss']
})
export class QuizRunnerAnsweredPlayersComponent implements OnInit, OnDestroy {

    readonly QuizQuestionStatus = QuizQuestionStatus;

    public playersList = new Array<GameUserInfo>();
    public answeredPlayersList = new Array<number>();
    public questionStatus: number;

    private unsubscribeSubject: Subject<void> = new Subject();

    constructor(private store: Store<AppState>) { }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(playersListSelector)
        ).subscribe(playersList => {
            this.playersList = playersList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(answeredPlayersListSelector)
        ).subscribe(answeredPlayersList => {
            this.answeredPlayersList = answeredPlayersList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            this.questionStatus = questionStatus;
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

}
