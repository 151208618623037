import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { QuizRunnerService } from '../quiz-runner.service';
import { QuizQuestion, QuizQuestionContentType, QuizQuestionStatus } from '../../../../proto/generated/gambo_pb';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { questionFileSrcSelector, questionSelector, questionStatusSelector } from '../store/quiz-runner.selectors';
import { AppState } from '../../../app/app.state';
import { Subject } from 'rxjs';
import { QuizRunnerSocket } from '../quiz-runner.socket';
import { convertAudioBufferToShape } from '../../../libs/player/audio-buffert-shape-converter';
import { UnsubscribeSubjectComponent } from '../../../libs/unsubscribe-subject-component';

@Component({
    selector: 'app-quiz-runner-question',
    templateUrl: './quiz-runner-question.component.html',
    styleUrls: ['./quiz-runner-question.component.scss']
})
export class QuizRunnerQuestionComponent extends UnsubscribeSubjectComponent implements OnInit, OnDestroy {

    readonly QuizQuestionContentType = QuizQuestionContentType;
    readonly QuizQuestionStatus = QuizQuestionStatus;

    public quizQuestion: QuizQuestion;
    public questionFileBlob: Blob;
    public questionFileSrc: string;

    public sourceAudioFile: Blob;
    public sourceAudioBuffer: AudioBuffer;
    public audioBuffers: Array<Float32Array>;
    public audioShape: number[];
    public questionStatus: number;
    // public waveformCoordinates: number[][];

    stopMediaSubject: Subject<void> = new Subject<void>();

    constructor(
        public domSanitizer: DomSanitizer,
        public quizRunnerSocket: QuizRunnerSocket,
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        console.log('SUBSCRIBE IN QUESTION SHOWER');
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionSelector)
        ).subscribe(question => {
            this.quizQuestion = question;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionFileSrcSelector)
        ).subscribe(questionFileSrc => {
            console.log('UPDATED QUESTION FILE');
            this.questionFileBlob = questionFileSrc;
            if (this.questionFileBlob) {
                console.log('TYPE', this.questionFileBlob.type);
                if (this.quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO) {
                    this.sourceAudioFileSetter(this.questionFileBlob);
                }

                if (
                    this.quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_IMAGE
                    || this.quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_VIDEO
                ) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.questionFileBlob);

                    reader.onload = () => {
                        this.questionFileSrc = reader.result as string;
                    };
                }
            }
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(questionStatusSelector)
        ).subscribe(questionStatus => {
            this.questionStatus = questionStatus;
            if (
                questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED
                && this.quizQuestion.getType() === QuizQuestionContentType.QUIZ_QUESTION_CONTENT_TYPE_AUDIO
            ) {
                this.stopMediaSubject.next();
            }
        });
    }

    public sourceAudioFileSetter(file: Blob) {
        console.log('New file loaded to audio editor');
        /*if (this.sourceAudioBuffer) {
         return;
         }*/
        // this.processing = true;
        this.sourceAudioFile = file;
        // Convert blob into ArrayBuffer
        new Response(file).arrayBuffer().then(arrayBuffer => {
            const audioContext = new AudioContext();
            // Convert ArrayBuffer into AudioBuffer
            audioContext.decodeAudioData(arrayBuffer).then(audioBuffer => {
                this.sourceAudioBuffer = audioBuffer;
                // this.audioLength = this.sourceAudioBuffer.duration;
                // this.startCutValue = 0;
                // this.endCutValue = this.audioLength;
                // this.processing = false;

                this.audioBuffers = new Array<Float32Array>(this.sourceAudioBuffer.numberOfChannels);
                for (let i = 0; i < this.sourceAudioBuffer.numberOfChannels; i++) {
                    this.audioBuffers[i] = this.sourceAudioBuffer.getChannelData(i);
                }

                this.audioShape = convertAudioBufferToShape(audioBuffer.getChannelData(0), true);
                // const yOffset = 1;
                /*this.waveformCoordinates = this.audioShape.map((y, index) => {
                    // Shift the y value 50% so that a 0 position is in the middle.
                    return [(100 / (this.audioShape.length - 1)) * index, y + yOffset];
                });*/
                this.cdr.detectChanges();
            });
        });
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }
}
