import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyQuizComponent } from './privacy-policy-quiz/privacy-policy-quiz.component';
import { AppRoutingModule } from '../../app/app-routing.module';

@NgModule({
    declarations: [PrivacyPolicyQuizComponent],
    exports: [PrivacyPolicyQuizComponent],
    imports: [
        CommonModule,
        AppRoutingModule
    ]
})
export class PrivacyPolicyQuizModule {

}
