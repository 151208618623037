<div *ngIf="players.length"
     cdkOverlayOrigin
     #trigger="cdkOverlayOrigin"
     (click)="isOpen = !isOpen"
     class="short-players">
    <div class="short-players-left-part">
        <div class="avatars">
            <div *ngFor="let player of players"
                 class="avatar">
                <img [src]="player.getAvatarurl()"
                     class="avatar-image">
            </div>
        </div>
        <div class="players-number">
            {{ players.length }}
        </div>
    </div>
    <mat-icon *ngIf="!isOpen" class="mat-icon-rounded">chevron_right</mat-icon>
    <mat-icon *ngIf="isOpen" class="mat-icon-rounded">expand_less</mat-icon>
    <!--<button (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        {{isOpen ? "Close" : "Open"}}
    </button>-->
    <!--<mat-card *ngFor="let player of players"
              class="user-card">
        <div class="player-information">
            <img [src]="player.getAvatarurl()"
                 class="avatar-image">
            <div>{{ player.getNickname() }}</div>
        </div>
    </mat-card>-->

    <ng-template cdkConnectedOverlay
                 [cdkConnectedOverlayOrigin]="trigger"
                 [cdkConnectedOverlayOpen]="isOpen">
        <div class="unfolded-players-list">
            <div *ngFor="let player of players"
                      class="user-card">
                <div class="player-information">
                    <img [src]="player.getAvatarurl()"
                         class="avatar-image">
                    <div>{{ player.getNickname() }}</div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
