import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnswerInfo, AnswerType, QuizQuestionInfo } from '../../quiz-constructor.service';
import { AppState } from '../../../../app/app.state';
import { select, Store } from '@ngrx/store';
import * as quizConstructorActions from '../../store/quiz-constructor.actions';
import { Observable, Subscription } from 'rxjs';
import { selectedQuestionSelector } from '../../store/quiz-constructor.selectors';
import { last, takeUntil } from 'rxjs/operators';
import { UnsubscribeSubjectComponent } from '../../../../libs/unsubscribe-subject-component';

@Component({
    selector: 'app-answer-constructor',
    templateUrl: './answer-constructor.component.html',
    styleUrls: ['./answer-constructor.component.scss']
})
export class AnswerConstructorComponent extends UnsubscribeSubjectComponent implements OnInit, OnDestroy {

    readonly answerTypes = AnswerType;

    public selectedQuestion: QuizQuestionInfo;

    // TODO get premium info from service
    public premium = false;
    public answerFormGroup: FormGroup;
    // public answersFormArray: FormArray;
    public numberOfAnswers = 4;

    public textAnswers = new Array<AnswerInfo>(8);
    // public correctAnswers = new Array<number>();

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar
    ) {
        super();
    }

    ngOnInit(): void {
        for (let i = 0; i < 8; i++) {
            this.textAnswers[i] = {
                correct: false,
                number: i,
                value: ''
            };
        }

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(selectedQuestionSelector)
        ).subscribe(selectedQuestion => {
            this.selectedQuestion = selectedQuestion;
            for (let i = 0; i < 8; i++) {
                if (this.selectedQuestion.answers[i]) {
                    this.textAnswers[i] = {
                        correct: this.selectedQuestion.answers[i].correct,
                        value: this.selectedQuestion.answers[i].value,
                        number: this.selectedQuestion.answers[i].number
                    };
                }
            }
        });

        this.numberOfAnswers = this.selectedQuestion.answers.length;
        console.log(this.textAnswers);
        this.answerFormGroup = this.formBuilder.group({
            timeControl: new FormControl(this.selectedQuestion.timeout, [Validators.required, Validators.min(1)]),
            pointsControl: new FormControl(this.selectedQuestion.points, [Validators.required, Validators.min(0)]),
            numberOfAnswersControl: new FormControl(this.numberOfAnswers, [Validators.required]),
            customAnswerText: new FormControl(
                this.selectedQuestion.correctAnswer,
                [Validators.required, Validators.min(1), Validators.max(1000)]
            ),
            // textAnswerControls: this.formBuilder.array(this.createFormArray(this.textAnswers))
            // TODO array instead list
            textAnswer1Control: new FormControl(
                this.textAnswers[0].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer2Control: new FormControl(
                this.textAnswers[1].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer3Control: new FormControl(
                this.textAnswers[2].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer4Control: new FormControl(
                this.textAnswers[3].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer5Control: new FormControl(
                this.textAnswers[4].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer6Control: new FormControl(
                this.textAnswers[5].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer7Control: new FormControl(
                this.textAnswers[6].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            ),
            textAnswer8Control: new FormControl(
                this.textAnswers[7].value,
                [Validators.required, Validators.minLength(1), Validators.maxLength(200)]
            )
        });
        // this.answersFormArray = this.formBuilder.array(this.createFormArray(this.textAnswers));

        this.answerFormGroup.get('timeControl').valueChanges.subscribe(newTime =>
            this.store.dispatch(new quizConstructorActions.SetQuestionTimeout(newTime))
        );
        this.answerFormGroup.get('pointsControl').valueChanges.subscribe(newPoints =>
            this.store.dispatch(new quizConstructorActions.SetQuestionPoints(newPoints))
        );
        // this.answerFormGroup.get('textAnswer1Control').valueChanges.subscribe(newPoints => console.log(newPoints));
    }

    createFormArray(values: any): FormGroup[] {
        return values.map(x => this.formBuilder.group({
            textAnswer: x.value
        }));
    }

    changeSelectedAnswerType(answerType: AnswerType) {
        if (answerType === AnswerType.CUSTOM) {
            this.snackBar.open(
                'Buy subscription',
                '',
                {
                    duration: 3000
                }
            );
        } else {
            this.store.dispatch(new quizConstructorActions.SetAnswerType(answerType));
        }
    }

    public counter(i: number) {
        return new Array(i);
    }

    formatTimeLabel(value: number) {
        if (value >= 60) {
            return Math.round(value / 60) + ' m';
        }

        return value + ' s';
    }

    setCorrectAnswer(i: number) {
        if (this.selectedQuestion.answerType === AnswerType.ONE) {
            this.textAnswers.forEach((answer, index) => this.textAnswers[index] = {
                ...answer,
                correct: false
            });
            this.textAnswers[i] = {
                ...this.textAnswers[i],
                correct: true
            };
        } else if (this.selectedQuestion.answerType === AnswerType.MULTI) {
            this.textAnswers[i] = {
                ...this.textAnswers[i],
                correct: !this.textAnswers[i].correct
            };
        }
        this.onAnswerChange();
    }

    answersChanged(numberOfAnswer: number, eventTarget: EventTarget) {
        this.textAnswers[numberOfAnswer] = {
            ...this.textAnswers[numberOfAnswer],
            value: (eventTarget as HTMLInputElement).value
        };
        this.onAnswerChange();
    }

    onAnswerChange() {
        this.store.dispatch(new quizConstructorActions.SetAnswersText(this.textAnswers.slice(0, this.numberOfAnswers)));
    }

    customAnswersChanged(customAnswerTextTarget: EventTarget) {
        this.store.dispatch(new quizConstructorActions.SetCustomAnswerText((customAnswerTextTarget as HTMLInputElement).value));
    }
}
