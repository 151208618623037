import { Action } from '@ngrx/store';
import {
    AnswerInfo,
    AnswerType,
    QuestionType,
    QuizDescriptionInfo,
    QuizInfo,
    QuizQuestionInfo,
    QuestionTemplateInfo,
    UserRooms
} from '../quiz-constructor.service';

export enum QuizConstructorActionType {
    GetAllQuizzes = '[QuizConstructor] Get All Quizzes',
    GetAllQuizzesSuccess = '[QuizConstructor] Get All Quizzes Success',
    GetUserRooms = '[QuizConstructor] Get User Rooms',
    GetUserRoomsSuccess = '[QuizConstructor] Get User Rooms Success',
    GetAllModeratingQuizzes = '[QuizConstructor] Get All Moderating Quizzes',
    GetAllModeratingQuizzesSuccess = '[QuizConstructor] Get All Moderating Quizzes Success',
    GetQuiz = '[QuizConstructor] Get Quiz',
    GetSharedQuiz = '[QuizConstructor] Get Shared Quiz',
    GetQuizSuccess = '[QuizConstructor] Get Quiz Success',
    GetQuizError = '[QuizConstructor] Get Quiz Error',
    CreateQuiz = '[QuizConstructor] Create Quiz',
    UpdateQuiz = '[QuizConstructor] Update Quiz',
    ReviewQuiz = '[QuizConstructor] Review Quiz',
    EnableQuizCreationShare = '[QuizConstructor] Enable Quiz Creation Share',
    EnableQuizCreationShareSuccess = '[QuizConstructor] Enable Quiz Creation Share Success',
    DisableQuizCreationShare = '[QuizConstructor] Disable Quiz Creation Share',
    DisableQuizCreationShareSuccess = '[QuizConstructor] Disable Quiz Creation Share Success',
    DeleteQuiz = '[QuizConstructor] Delete Quiz',
    SetSelectedQuiz = '[QuizConstructor] Set Selected Quiz',
    SetSelectedQuizSuccess = '[QuizConstructor] Set Selected Quiz Success',
    SetSelectedQuestion = '[QuizConstructor] Set Selected Question Success',
    UploadQuestionMedia = '[QuizConstructor] Upload Question Media',
    UploadQuestionMediaSuccess = '[QuizConstructor] Upload Question Media Success',
    UploadQuestionMediaError = '[QuizConstructor] Upload Question Media Error',
    UploadAnswerDescriptionMedia = '[QuizConstructor] Upload Answer Description Media',
    UploadAnswerDescriptionMediaSuccess = '[QuizConstructor] Upload Answer Description Media Success',
    UploadAnswerDescriptionMediaError = '[QuizConstructor] Upload Answer Description Media Error',
    AddQuestion = '[QuizConstructor] Add Question',
    AddQuestionSuccess = '[QuizConstructor] Add Question Success',
    AddQuestionTemplate = '[QuizConstructor] Add Question Template',
    AddQuestionTemplateSuccess = '[QuizConstructor] Add Question Template Success',
    UpdateQuestion = '[QuizConstructor] Update Question',
    UpdateQuestionSuccess = '[QuizConstructor] Update Question Success',
    DeleteQuestion = '[QuizConstructor] Delete Question',
    SetQuestionFileBlob = '[QuizConstructor] Set Question File Blob Success',
    SetQuestionType = '[QuizConstructor] Set Question Type Success',
    SetAnswerType = '[QuizConstructor] Set Answer Type Success',
    SetQuestionText = '[QuizConstructor] Set Question Text Success',
    SetAnswersText = '[QuizConstructor] Set Answers Text Success',
    SetCustomAnswerText = '[QuizConstructor] Set Custom Answer Text Success',
    SetQuestionTimeout = '[QuizConstructor] Set Question Timeout Success',
    SetQuestionPoints = '[QuizConstructor] Set Question Points Success',
    SetAnswerDescriptionValue = '[QuizConstructor] Set Answer Description Value Success',
    SetAnswerDescriptionType = '[QuizConstructor] Set Answer Description Type Success',
    SetAnswerDescriptionMedia = '[QuizConstructor] Set Answer Description Media Success',
    SetAnswerDescriptionFileBlob = '[QuizConstructor] Set Answer Description File Blob Success',
    GetQuestionTemplateList = '[QuizConstructor] Get Question Template List',
    GetQuestionTemplateListSuccess = '[QuizConstructor] Get Question Template List Success',
    GetQuestionTemplateListError = '[QuizConstructor] Get Question Template List Error',
}

export class GetAllQuizzes implements Action {
    readonly type = QuizConstructorActionType.GetAllQuizzes;
}

export class GetAllQuizzesSuccess implements Action {
    readonly type = QuizConstructorActionType.GetAllQuizzesSuccess;

    constructor(public quizList: Array<QuizInfo>) { }
}

export class GetUserRooms implements Action {
    readonly type = QuizConstructorActionType.GetUserRooms;
}

export class GetUserRoomsSuccess implements Action {
    readonly type = QuizConstructorActionType.GetUserRoomsSuccess;

    constructor(public userRooms: UserRooms) { }
}

export class GetAllModeratingQuizzes implements Action {
    readonly type = QuizConstructorActionType.GetAllModeratingQuizzes;
}

export class GetAllModeratingQuizzesSuccess implements Action {
    readonly type = QuizConstructorActionType.GetAllModeratingQuizzesSuccess;

    constructor(public quizList: Array<QuizInfo>) { }
}

export class GetQuiz implements Action {
    readonly type = QuizConstructorActionType.GetQuiz;

    constructor(public quizId: string) { }
}

export class GetSharedQuiz implements Action {
    readonly type = QuizConstructorActionType.GetSharedQuiz;

    constructor(public shareLink: string) { }
}

export class GetQuizSuccess implements Action {
    readonly type = QuizConstructorActionType.GetQuizSuccess;

    constructor(public quizInfo: QuizInfo) { }
}

export class GetQuizError implements Action {
    readonly type = QuizConstructorActionType.GetQuizError;

    constructor(public error: string) { }
}

export class CreateQuiz implements Action {
    readonly type = QuizConstructorActionType.CreateQuiz;

    constructor(public quizInfo: QuizDescriptionInfo) { }
}

export class UpdateQuiz implements Action {
    readonly type = QuizConstructorActionType.UpdateQuiz;

    constructor(public quizInfo: QuizInfo) { }
}

export class ReviewQuiz implements Action {
    readonly type = QuizConstructorActionType.ReviewQuiz;

    constructor(public quizInfo: QuizInfo) { }
}

export class EnableQuizCreationShare implements Action {
    readonly type = QuizConstructorActionType.EnableQuizCreationShare;

    constructor(public quizId: string) { }
}

export class DisableQuizCreationShare implements Action {
    readonly type = QuizConstructorActionType.DisableQuizCreationShare;

    constructor(public quizId: string) { }
}

export class DeleteQuiz implements Action {
    readonly type = QuizConstructorActionType.DeleteQuiz;

    constructor(public quizId: string) { }
}

export class SetSelectedQuiz implements Action {
    readonly type = QuizConstructorActionType.SetSelectedQuiz;

    constructor(public quizInfo: QuizInfo) { }
}

export class SetSelectedQuizSuccess implements Action {
    readonly type = QuizConstructorActionType.SetSelectedQuizSuccess;

    constructor(public quizInfo: QuizInfo) { }
}

export class SetSelectedQuestion implements Action {
    readonly type = QuizConstructorActionType.SetSelectedQuestion;

    constructor(public quizQuestionInfo: QuizQuestionInfo, public questionTemplate?: boolean) { }
}

export class UploadQuestionMedia implements Action {
    readonly type = QuizConstructorActionType.UploadQuestionMedia;

    constructor() { }
}

export class UploadQuestionMediaSuccess implements Action {
    readonly type = QuizConstructorActionType.UploadQuestionMediaSuccess;

    constructor(public fileUrl: string) { }
}

export class UploadQuestionMediaError implements Action {
    readonly type = QuizConstructorActionType.UploadQuestionMediaError;

    constructor() { }
}

export class UploadAnswerDescriptionMedia implements Action {
    readonly type = QuizConstructorActionType.UploadAnswerDescriptionMedia;

    constructor() { }
}

export class UploadAnswerDescriptionMediaSuccess implements Action {
    readonly type = QuizConstructorActionType.UploadAnswerDescriptionMediaSuccess;

    constructor(public fileUrl: string) { }
}

export class UploadAnswerDescriptionMediaError implements Action {
    readonly type = QuizConstructorActionType.UploadAnswerDescriptionMediaError;

    constructor() { }
}

export class AddQuestion implements Action {
    readonly type = QuizConstructorActionType.AddQuestion;

    constructor() { }
}

export class AddQuestionSuccess implements Action {
    readonly type = QuizConstructorActionType.AddQuestionSuccess;

    constructor(public id: string) { }
}

export class AddQuestionTemplate implements Action {
    readonly type = QuizConstructorActionType.AddQuestionTemplate;

    constructor() { }
}

export class AddQuestionTemplateSuccess implements Action {
    readonly type = QuizConstructorActionType.AddQuestionTemplateSuccess;

    constructor(public id: string) { }
}

export class UpdateQuestion implements Action {
    readonly type = QuizConstructorActionType.UpdateQuestion;

    constructor() { }
}

export class UpdateQuestionSuccess implements Action {
    readonly type = QuizConstructorActionType.UpdateQuestionSuccess;

    constructor(public result: boolean) { }
}

export class DeleteQuestion implements Action {
    readonly type = QuizConstructorActionType.DeleteQuestion;

    constructor(public questionId: string) { }
}

export class SetQuestionFileBlob implements Action {
    readonly type = QuizConstructorActionType.SetQuestionFileBlob;

    constructor(public questionFileBlob: Blob) { }
}

export class SetQuestionType implements Action {
    readonly type = QuizConstructorActionType.SetQuestionType;

    constructor(public questionType: QuestionType) { }
}

export class SetAnswerType implements Action {
    readonly type = QuizConstructorActionType.SetAnswerType;

    constructor(public answerType: AnswerType) { }
}

export class SetQuestionText implements Action {
    readonly type = QuizConstructorActionType.SetQuestionText;

    constructor(public questionText: string) { }
}

export class SetAnswersText implements Action {
    readonly type = QuizConstructorActionType.SetAnswersText;

    constructor(public questionAnswersText: Array<AnswerInfo>) { }
}

export class SetCustomAnswerText implements Action {
    readonly type = QuizConstructorActionType.SetCustomAnswerText;

    constructor(public customAnswerText: string) { }
}

export class SetQuestionTimeout implements Action {
    readonly type = QuizConstructorActionType.SetQuestionTimeout;

    constructor(public timeout: number) { }
}

export class SetQuestionPoints implements Action {
    readonly type = QuizConstructorActionType.SetQuestionPoints;

    constructor(public points: number) { }
}

export class SetAnswerDescriptionValue implements Action {
    readonly type = QuizConstructorActionType.SetAnswerDescriptionValue;

    constructor(public answerDescriptionValue: string) { }
}

export class SetAnswerDescriptionType implements Action {
    readonly type = QuizConstructorActionType.SetAnswerDescriptionType;

    constructor(public answerDescriptionType: QuestionType) { }
}

export class SetAnswerDescriptionMedia implements Action {
    readonly type = QuizConstructorActionType.SetAnswerDescriptionMedia;

    constructor(public answerDescriptionMedia: string) { }
}

export class SetAnswerDescriptionFileBlob implements Action {
    readonly type = QuizConstructorActionType.SetAnswerDescriptionFileBlob;

    constructor(public answerDescriptionFileBlob: Blob) { }
}

export class GetQuestionTemplateList implements Action {
    readonly type = QuizConstructorActionType.GetQuestionTemplateList;

    constructor() { }
}

export class GetQuestionTemplateListSuccess implements Action {
    readonly type = QuizConstructorActionType.GetQuestionTemplateListSuccess;

    constructor(public quizQuestionTemplates: Array<QuestionTemplateInfo>) { }
}

export class GetQuestionTemplateListError implements Action {
    readonly type = QuizConstructorActionType.GetQuestionTemplateListError;

    constructor() { }
}

export type QuizConstructorActions = GetAllQuizzes
    | GetAllQuizzesSuccess
    | GetUserRooms
    | GetUserRoomsSuccess
    | GetAllModeratingQuizzes
    | GetAllModeratingQuizzesSuccess
    | GetQuiz
    | GetSharedQuiz
    | GetQuizSuccess
    | GetQuizError
    | CreateQuiz
    | UpdateQuiz
    | ReviewQuiz
    | DeleteQuiz
    | SetSelectedQuiz
    | SetSelectedQuizSuccess
    | SetSelectedQuestion
    | UploadQuestionMedia
    | UploadQuestionMediaSuccess
    | UploadQuestionMediaError
    | UploadAnswerDescriptionMedia
    | UploadAnswerDescriptionMediaSuccess
    | UploadAnswerDescriptionMediaError
    | AddQuestion
    | AddQuestionSuccess
    | AddQuestionTemplate
    | AddQuestionTemplateSuccess
    | UpdateQuestion
    | UpdateQuestionSuccess
    | DeleteQuestion
    | SetQuestionFileBlob
    | SetQuestionType
    | SetAnswerType
    | SetQuestionText
    | SetAnswersText
    | SetCustomAnswerText
    | SetQuestionTimeout
    | SetQuestionPoints
    | SetAnswerDescriptionValue
    | SetAnswerDescriptionType
    | SetAnswerDescriptionMedia
    | SetAnswerDescriptionFileBlob
    | GetQuestionTemplateListSuccess
    | GetQuestionTemplateListError;
