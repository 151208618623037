<div *ngIf="questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED"
     class="answered-players">
    <div *ngFor="let player of playersList"
         class="player neumorphic"
         [ngClass]="{'neumorphic-pressed': answeredPlayersList.includes(player.getPlayernumber())}">
        <img [src]="player.getAvatarurl()"
             class="avatar-image">
        <div>{{ player.getNickname() }}</div>
<!--        <span><strong>{{ player.getNickname() }}</strong></span>-->
    </div>
</div>
