import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class TranslateLoaderWithLangAutodetect extends TranslateHttpLoader {
    protected langLoaded = '';
    protected langNeeded = '';
    private historyLang = [];

    constructor(
        private defaultLang: string,
        http: HttpClient,
        prefix: string,
        suffix?: string
    ) {
        super(http, prefix, suffix);
    }

    public getTranslation(lang: string): Observable<object> {
        if (this.langNeeded === '') {
            this.langNeeded = lang;
            this.langLoaded = lang;
        }

        return this.getCatchableTranslation(lang);
    }

    protected getCatchableTranslation(lang: string): Observable<object> {
        return this.makeCatchableErrors(super.getTranslation(lang), lang);
    }

    protected makeCatchableErrors(observable: Observable<object>, lang): Observable<object> {

        return observable
            .pipe(
                catchError(
                    () => this.getTranlsationErrorHandler(lang, this.historyLang)
                )
            );
    }

    protected getTranlsationErrorHandler(lang: string, historyLang: string[]): Observable<object> {
        const nextLang = this.getLangOnError(lang, historyLang);
        if (nextLang !== null) {
            return this.getCatchableTranslation(nextLang);
        } else {
            return of({});
        }
    }

    protected getLangOnError(lang: string, history: string[]): string | null {
        let nextLang = null;
        if (lang !== 'en' && lang !== 'ru') {
            if (this.defaultLang === 'ru') {
                nextLang = 'ru';
            } else {
                nextLang = 'en';
            }
        }

        if (
            lang === 'en'
            && (
                this.defaultLang === 'ru'
                || this.defaultLang === 'ru'
            )
        ) {
            return null;
        }

        if (lang === 'en') {
            nextLang = 'ru';
        }
        if (lang === 'ru') {
            nextLang = 'en';
        }

        if (history.includes(nextLang) === false) {
            history.push(nextLang);
            this.langLoaded = nextLang;
            return nextLang;
        }

        return null;
    }

}
