import { TranslateService } from '@ngx-translate/core';
import { ISO_639_1 } from './lang-iso-map';
import { Injectable } from '@angular/core';

export class I18NDefaultLangService {

    public static GetDefaultLanguage(): string {
        let defaultLang = localStorage.getItem('selectedLang');

        if (!defaultLang) {
            defaultLang = I18NDefaultLangService.GetBrowserLang();
        }

        return defaultLang;
    }

    public static GetBrowserLang(): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return undefined;
        }

        let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
        browserLang = browserLang || window.navigator.language
            || (window.navigator as any).browserLanguage || (window.navigator as any).userLanguage;

        if (typeof browserLang === 'undefined') {
            return undefined;
        }

        if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }

        if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }

        return browserLang;
    }
}
