import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomManagerComponent } from './room-manager/room-manager.component';
import { RoomManagerService } from './room-manager.service';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthModule } from '../auth/auth.module';
import { HeaderModule } from '../header/header.module';
import { RoomManagerUserListComponent } from './room-manager-user-list/room-manager-user-list.component';

@NgModule({
    declarations: [
        RoomManagerComponent,
        RoomManagerUserListComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild(),
        QRCodeModule,
        AuthModule,
        HeaderModule
    ]
})
export class RoomManagerModule {}
