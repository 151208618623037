<div [ngClass]="{'started-question':
                questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_STARTED
                || questionStatus === QuizQuestionStatus.QUIZ_QUESTION_STATUS_BUTTON_BLOCKED
                }"
     class="players-wrapper">
    <div *ngFor="let scoreData of scoreBoard"
         [ngClass]="{'waiting-player': !answeredPlayersList.includes(scoreData.getPlayernumber())}"
         class="player-row">
        <img [src]="findPlayerByNumber(scoreData.getPlayernumber()).getAvatarurl()"
             class="player-avatar">
        <!--<div class="player-avatar-wrapper">
            <img [src]="findPlayerByNumber(scoreData.getPlayernumber()).getAvatarurl()"
                 class="player-avatar">
        </div>-->
        <!--<div [matBadge]="scoreData.getTotalscore()" matBadgePosition="below after" class="player-avatar">
            <img [src]="findPlayerByNumber(scoreData.getPlayernumber()).getAvatarurl()"
                 class="avatar-image">
        </div>-->
        <div class="player-information">
            <div class="player-score">{{ scoreData.getTotalscore() }}</div>
            <div class="player-nickname">{{ findPlayerByNumber(scoreData.getPlayernumber()).getNickname() }}</div>
        </div>
    </div>
</div>
