// import { TRANSFORMATION_PROGRESS_STATUS } from './interfaces';

// env
/*export const ENV = {
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'production',
};*/

// Google Analytics
/*export const APP_VERSION = process.env.REACT_APP_VERSION;
export const TRACKING_ID = 'UA-168925634-1';
export enum TRACKING_CATEGORIES {
  ADJUSTMENTS = 'Adjustments',
  CURATED = 'Curated',
  DOWNLOAD = 'Download',
  ERROR = 'Error',
  FOOTER = 'Footer',
  LANDING = 'Landing',
  RECORD = 'Record',
  TOOLTIP = 'Tooltip',
}
export enum TRACKING_LABELS {
  BUTTON_CLICK = 'button click',
  CHANGE_VALUE = 'change value',
  INPUT_CHANGE = 'input change',
  LINK_CLICK = 'link click',
  OPEN_MODAL = 'open modal',
  OPEN_TOOLTIP = 'open tooltip',
  OUTPUT_CHANGE = 'output change',
  PROGRAMMATIC = 'programmatic',
}*/

/*// Routing
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const SHORT_LINK = 'g.co/tonetransfer';

// Videos
export const VIGNETTE_INTRO_URL = PUBLIC_URL + '/video/intro.mp4';
// TODO: Replace once real video is uploaded.
export const FILM_YOUTUBE_ID = 'bXBliLjImio';

// RESPONSIVE BREAKPOINTS (make sure to sync with css)
export const BREAKPOINT_MEDIUM = 1000;

// Strings to build URLs for API endpoints and static files.
export const PATHS = {
  ABOUT_PAGE: PUBLIC_URL + '/about',
  CURATED_AUDIO: '//www.gstatic.com/aiux/tone-transfer/audio',
  IMAGES: PUBLIC_URL + '/images',
  BACKGROUNDS: PUBLIC_URL + '/images/backgrounds',
  MODELS: process.env.REACT_APP_MODEL_PATH,
  COLAB: 'https://colab.sandbox.google.com/github/magenta/ddsp/blob/master/ddsp/colab/demos/timbre_transfer.ipynb',
  PLUGIN: 'https://g.co/magenta/ddsp-vst',
};*/

// Curated audio
export const AUDIOFILE_EXTENSION = 'mp3';
export const DEFAULT_AUDIO_DURATION = 8;

// Downloaded filenames
export const DOWNLOAD_YOUR_RECORDING_FILENAME =
  'Tone Transfer - Your Recording.wav';

// recording progress states step numbers
/*export const RECORDING_PROGRESS_STEPS = {
  [TRANSFORMATION_PROGRESS_STATUS.INITIAL]: 0,
  [TRANSFORMATION_PROGRESS_STATUS.PROCESSING]: 1,
  [TRANSFORMATION_PROGRESS_STATUS.TRANSFORMING]: 2,
  [TRANSFORMATION_PROGRESS_STATUS.DONE]: 3,
};*/

// Firestore collection name
// export const JOB_COLLECTION_ID = process.env.REACT_APP_COLLECTION;

// Instruction Keys
export enum INSTRUCTION_STEP {
  NO_INPUT_SELECTED = 'no-input-selected',
  NO_OUTPUT_SELECTED = 'no-output-selected',
  RECORD_START = 'record-start',
  RECORD_IN_PROGRESS = 'record-in-progress',
  RECORD_REVIEW = 'record-review',
  TRANSFORMATION_IN_PROGRESS = 'transformation-in-progress',
  TRANSFORMATION_COMPLETE = 'transformation-complete',
}

// Variations of the instructional arrow position
export const ARROW_POSITION = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
};

// Default sample rate used throughout the app when creating new AudioContexts
export const DEFAULT_SAMPLE_RATE = 48000;

// Length of array for wavepath
export const SAMPLE_LENGTH = 40;

// Wave animation duration when idle
export const ANIM_TIMEOUT_INTERVAL = 3500;

// Colors
export const COLOR_MAGENTA_BLUE = '#10293e';
export const COLOR_MAGENTA_PINK = '#b72872';

// Recording times
export const MIN_RECORD_TIME = 5;
export const MAX_RECORD_TIME = 15000;

// Allowed audio file types
export const ALLOWED_AUDIO_FILES = '.wav,.mp3,.oga,.aac';

export const WAVE_TRANSITION_DURATION = 1000;

export const INPUT_KEY = 'input';

// Input keys.
export enum INPUT_KEYS {
  ACAPELLA = 'acapella',
  BIRDS = 'birds',
  CARNATIC_SINGING = 'carnatic_singing',
  CELLO = 'cello',
  POTS_AND_PANS = 'pots_and_pans',
  SYNTHESIZER = 'synthesizer',
  RECORD = 'record',
}

// Transformation keys.
export enum TRANSFORMATION_KEYS {
  NONE = 'none',
  FLUTE = 'flute',
  TENOR_SAXOPHONE = 'tenor_saxophone',
  TRUMPET = 'trumpet',
  VIOLIN = 'violin',
}

// Audio Adjustments keys.
export enum ADJUSTMENT_KEYS {
  LOUDNESS = 'loudness',
  MIX = 'mix',
  OCTAVE = 'octave',
}

export enum ADJUST_TYPE {
  LOWER = 'lower',
  HIGHER = 'higher',
}

// Audio Adjustment steps
export const ADJUSTMENT_STEPS = {
  [ADJUSTMENT_KEYS.MIX]: 5,
  [ADJUSTMENT_KEYS.LOUDNESS]: 5,
  [ADJUSTMENT_KEYS.OCTAVE]: 1,
};

// Audio Adjustments options.
export const OCTAVE_ADJUSTMENTS = [-1, 0, 1] as const;
export const LOUDNESS_ADJUSTMENTS = [-10, -5, 0, 5, 10] as const;
// tslint:disable-next-line: ban
export const MIX_ADJUSTMENTS = [...Array(101).keys()] as const;
export const MIX_ADJUSTMENT_DEFAULT = 100;

// All possible octave/loudness combinations.
export const ADJUSTMENT_OPTIONS = [].concat(
  ...OCTAVE_ADJUSTMENTS.map(octave => {
    return LOUDNESS_ADJUSTMENTS.map(loudness => [octave, loudness]);
  })
);

// The selectors to apply footer animations to. The animation
// is staggered, so the order represents the staggered sequence.
export const FOOTER_ANIMATION_STEP_SELECTORS = [
  '.controls--input',
  '.backgrounds',
  '.player__controls',
  '.column--primary',
  '.controls--output',
];
