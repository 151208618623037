import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuizzesComponent } from './quizzes.component';
import { MaterialAllInOneModule } from '../../libs/material-all-in-one/material-all-in-one.module';
import { I18NModule } from '../../libs/translate/i18n.module';

@NgModule({
    declarations: [
        QuizzesComponent
    ],
    imports: [
        CommonModule,
        MaterialAllInOneModule,
        I18NModule.forChild()
    ]
})
export class QuizzesModule {}
