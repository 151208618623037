import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { QuizInfo } from '../quiz-constructor/quiz-constructor.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class QuizzesService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    public getAllQuizzes(): Observable<Array<QuizInfo>> {
        return this.httpClient.get<Array<QuizInfo>>(
            environment.URLPrefix + '/quiz/getAllPublic',
            {}
        );
    }
}
