import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    QuizInfo,
    QuizConstructorService,
    QuestionType,
    QuizDescriptionInfo,
    QuizStatus,
    UserRooms,
    RoomInfo
} from '../quiz-constructor.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateQuizDialogComponent } from './create-quiz-dialog/create-quiz-dialog.component';
import { Router } from '@angular/router';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth/auth.service';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, getMetadata } from '@angular/fire/storage';
import { getAuth } from 'firebase/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { select, Store } from '@ngrx/store';
import { quizListSelector, userRoomsSelector } from '../store/quiz-constructor.selectors';
import * as quizConstructorActions from '../store/quiz-constructor.actions';
import { AppState } from '../../../app/app.state';
import { GameUserInfo } from '../../../../proto/generated/gambo_pb';
import { MatMenuTrigger } from '@angular/material/menu';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UserList } from '../../friends/friends/friends.service';
import { HttpClient } from '@angular/common/http';

import * as lamejs from 'lamejstmp';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Clipboard } from '@angular/cdk/clipboard';
import { QuestionsBankComponent } from '../questions-bank/questions-bank.component';

export interface TypedRoomInfo extends RoomInfo {
    roomStatus: 'created' | 'started' | 'finished';
}

@Component({
    selector: 'app-quiz-list',
    templateUrl: './quiz-list.component.html',
    styleUrls: ['./quiz-list.component.scss']
})
export class QuizListComponent implements OnInit, OnDestroy {

    readonly QuizStatus = QuizStatus;

    public questionType = QuestionType.VIDEO;
    public nickname: string;

    public quizList = new Array<QuizInfo>();
    public userRooms: Array<TypedRoomInfo> = new Array<TypedRoomInfo>();

    public myFile: Blob;
    public myFileShort: Blob;
    public myFileShortProcessed: Blob;
    public myFileSrc: string;
    public myFileSrcShort: string;
    public myFileSrcShortProcessed: string;
    public uploadMetadata = false;
    public premium = false;
    public questionTypes = QuestionType;
    public spinnerMode: ProgressSpinnerMode = 'indeterminate';
    public enabledSpinner = false;

    private unsubscribeSubject: Subject<void> = new Subject();


    // FOR AUDIO ONLY
    public audioContext: AudioContext;
    public start = 20;
    public end = 30;
    public filename = '';

    constructor(
        private store: Store<AppState>,
        private quizService: QuizConstructorService,
        private matDialog: MatDialog,
        private router: Router,
        private authService: AuthService,
        public domSanitizer: DomSanitizer,
        private httpClient: HttpClient,
        private clipboard: Clipboard,
        //private matDialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(quizListSelector)
        ).subscribe(quizList => {
            this.quizList = quizList;
        });

        this.store.pipe(
            takeUntil(this.unsubscribeSubject),
            select(userRoomsSelector)
        ).subscribe(userRooms => {
            this.userRooms = [
                ...userRooms.createdRooms.map(room => ({ ...room, roomStatus: 'created' } as TypedRoomInfo)),
                ...userRooms.startedRooms.map(room => ({ ...room, roomStatus: 'started' } as TypedRoomInfo)),
                ...userRooms.finishedRooms.map(room => ({ ...room, roomStatus: 'finished' } as TypedRoomInfo))
            ].sort((firstRoom, secondRoom) => {
                return firstRoom.createTime > secondRoom.createTime ? -1 : 1;
            });
            // this.userRooms = userRooms;
        });
        this.store.dispatch(new quizConstructorActions.GetAllQuizzes());
        this.store.dispatch(new quizConstructorActions.GetUserRooms());
    }

    private setRoomInfo(roomInfo: RoomInfo) {
        console.log(roomInfo.roomId);
        localStorage.setItem('roomId', roomInfo.roomId.toString());
        localStorage.setItem('isHost', roomInfo.host.toString());
    }

    public open(roomInfo: TypedRoomInfo) {
        this.setRoomInfo(roomInfo);
        if (roomInfo.roomStatus === 'created') {
            localStorage.setItem('game', 'quiz');
            this.router.navigate(
                ['room-manager'],
                {
                    queryParams: {
                        action: roomInfo.host ? 'run' : 'join'
                    }
                }
            );
        }
        if (roomInfo.roomStatus === 'started') {
            this.setRoomInfo(roomInfo);
            this.router.navigate(['quiz-runner']);
        }
        // this.router.navigate(['room-manager']);
    }

    /*public openCreated(roomInfo: RoomInfo) {
        this.setRoomInfo(roomInfo);
        localStorage.setItem('game', 'quiz');
        this.router.navigate(
            ['room-manager'],
            {
                queryParams: {
                    action: roomInfo.host ? 'run' : 'join'
                }
            }
        );
        // this.router.navigate(['room-manager']);
    }

    public openStarted(roomInfo: RoomInfo) {
        this.setRoomInfo(roomInfo);
        this.router.navigate(['quiz-runner']);
    }*/

    public fileChanged(file: Blob) {
        this.myFile = file;
    }

    public uploadImage() {
        try {
            this.enabledSpinner = true;
            console.log('Enabled spinner', this.enabledSpinner);
            const storage = getStorage();
            const auth = getAuth();
            const userUid = auth.currentUser.uid;
            // const alexeyUserUid = 'UmlSt0mhKSe0S5V48POIZXl035E2';
            /*const metadata = {
                customMetadata: {
                    allowedUserIds: '[' + alexeyUserUid + ']'
                }
            };*/
            console.log('Upload metadata', this.uploadMetadata);
            const fileRef = ref(storage, 'users/' + userUid + '/quiz/test.mp4');
            (this.uploadMetadata ? uploadBytes(fileRef, this.myFile/*, metadata*/) : uploadBytes(fileRef, this.myFile))
                .then((snapshot) => {
                    this.enabledSpinner = false;
                    console.log('Uploaded a blob or a file!');
                }).catch((error) => {
                this.enabledSpinner = false;
                console.log('Error while uploading picture', error);
                console.log('Error code for uploading picture: ' + error.code);
            });
        } catch {
            this.enabledSpinner = false;
        }
    }

    public downloadImage() {
        try {
            this.enabledSpinner = true;
            const storage = getStorage();
            const auth = getAuth();
            console.log(auth.currentUser.uid);
            // const userUid = auth.currentUser.uid;
            // gs://gambo-231511.appspot.com/users/jiV4BXIW8lUio7quD3MGDo7SaEF3/quiz/2ec81654-ab73-4f8c-9604-eea8b193413f.mp4
            const userUid = 'jiV4BXIW8lUio7quD3MGDo7SaEF3';
            const fileRef = ref(storage, 'users/' + userUid + '/quiz/03c45c0a-2b91-41fe-be90-3bc797f04fa6.mp4');
            getDownloadURL(fileRef)
                .then((result) => {
                    this.myFileSrc = result;
                    getMetadata(fileRef)
                        .then((result1) => {
                            this.enabledSpinner = false;
                            console.log('Metadata for image', result1);
                        })
                        .catch((error) => {
                            this.enabledSpinner = false;
                            console.log('Error getting metadata', error);
                            console.log('Error code for getting metadata: ' + error.code);
                        });
                })
                .catch((error) => {
                    this.enabledSpinner = false;
                    console.log('Error while downloading picture', error);
                    console.log('Error code for downloading picture: ' + error.code);
                });
        } catch {
            this.enabledSpinner = false;
        }
    }

    public deleteImage() {
        try {
            this.enabledSpinner = true;
            const storage = getStorage();
            const auth = getAuth();
            const userUid = auth.currentUser.uid;
            const fileRef = ref(storage, 'users/' + userUid + '/quiz/test.mp4');
            deleteObject(fileRef).then(() => {
                this.enabledSpinner = false;
                console.log('File was deleted successfully');
                this.myFileSrc = null;
            }).catch((error) => {
                this.enabledSpinner = false;
                console.log('Error while deleting picture', error);
                console.log('Error code for deleting picture: ' + error.code);
            });
        } catch {
            this.enabledSpinner = false;
        }
    }

    public openQuizEditDialog(quizInfo?: QuizInfo) {
        let quizDescription: QuizDescriptionInfo = {
            name: '',
            description: '',
            public: false,
            // creationShare: false
        };
        if (quizInfo) {
            quizDescription = { ...quizInfo };
        }
        this.matDialog.open(CreateQuizDialogComponent, {
            width: '400px',
            data: quizDescription
        }).afterClosed().subscribe(result => {
            if (result) {
                if (quizInfo) {
                    const sendQuizInfo: QuizInfo = {
                        ...quizInfo,
                        ...result
                    };
                    this.store.dispatch(new quizConstructorActions.UpdateQuiz(sendQuizInfo));
                } else {
                    this.store.dispatch(new quizConstructorActions.CreateQuiz(result as QuizDescriptionInfo));
                }
            }
        });
    }

    public changeCreationShareState(matSlideToggleChange: MatSlideToggleChange, quizId: string) {
        if (matSlideToggleChange.checked) {
            this.store.dispatch(new quizConstructorActions.EnableQuizCreationShare(quizId));
        } else {
            this.store.dispatch(new quizConstructorActions.DisableQuizCreationShare(quizId));
        }
    }

    public deleteQuiz(id: string) {
        this.store.dispatch(new quizConstructorActions.DeleteQuiz(id));
    }

    public openQuiz(quizInfo: QuizInfo) {
        this.store.dispatch(new quizConstructorActions.GetQuiz(quizInfo.id));
        this.router.navigate(['quiz-editor']);
    }

    public updateQuizStatus(quizInfo: QuizInfo, status: QuizStatus) {
        this.store.dispatch(new quizConstructorActions.UpdateQuiz({
            ...quizInfo,
            status
        }));
    }

    runQuiz(quizId: string) {
        localStorage.setItem('game', 'quiz');
        localStorage.setItem('quizId', quizId);
        localStorage.setItem('quizType', 'EMPTY');
        localStorage.removeItem('roomId');
        this.router.navigate(['/room-manager'], { queryParams: { action: 'run' } });
    }

    private localSignOut() {
        localStorage.removeItem('sid');
        this.authService.authSubject.next(false);
        this.router.navigate(['auth']);
    }

    signOut() {
        this.authService.signOut().subscribe((ans) => {
            console.log('Sign out result', ans);
            if (ans.result) {
                this.localSignOut();
            }
        });
    }

    link() {
        this.authService.linkWithGoogle(this.nickname);
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
        // this.quizListS.unsubscribe();
    }

    public convert(n) {
        const v = n < 0 ? n * 32768 : n * 32767;       // convert in range [-32768, 32767]
        return Math.max(-32768, Math.min(32768, v)); // clamp
    }

    checkFileStatus() {
        this.httpClient.get<{ status: string }>(
            'https://ai-partygames.com/alex/test/status/' + this.filename
        ).subscribe(ans => {
            console.log('STATUS', ans.status);
        });
    }

    getFile() {
        this.httpClient.get(
            'https://ai-partygames.com/alex/test/result/' + this.filename,
            {
                // Accept: 'audio/x-wav',
                // responseType: 'blob' as 'json',
                // observe: 'events',
                responseType: 'blob',
            }
        ).subscribe(ans => {
            this.myFileShortProcessed = ans;
            const reader = new FileReader();
            reader.readAsDataURL(this.myFileShortProcessed);
            reader.onload = () => {
                this.myFileSrcShortProcessed = reader.result as string;
            };
        });
    }

    copyShareLinkToClipboard(shareId: string) {
        if (!shareId) {
            return;
        }
        const link = 'ai-partygames.com/quiz-editor?' + shareId;
        this.clipboard.copy(link);
    }

    bankWithQuestions() {
        this.matDialog.open(QuestionsBankComponent, {
            width: '90vw'
        });
    }
}
