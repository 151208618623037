import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AudioInterface } from '../../../../libs/player/audio-interface';
import { ANIM_TIMEOUT_INTERVAL, SAMPLE_LENGTH, WAVE_TRANSITION_DURATION } from '../../../../libs/player/constants';

@Component({
    selector: 'app-wave-control',
    templateUrl: './wave-control.component.html',
    styleUrls: ['./wave-control.component.scss']
})
export class WaveControlComponent implements OnInit, OnChanges, OnDestroy {

    readonly ANIM_TIMEOUT_INTERVAL = ANIM_TIMEOUT_INTERVAL;
    readonly WAVE_TRANSITION_DURATION = WAVE_TRANSITION_DURATION;

    @Input() public audioInterface: AudioInterface;
    @Input() public audioShape: number[];
    @Input() public interactionHandler: (scrubberWidth: number, offsetX: number, shouldPlay: boolean) => void;
    @Input() public color?: string;
    @Input() public duration: number;
    @Input() public visualization: 'bars' | 'line';

    @ViewChild('waveformContainer', { static: true })
    public waveformContainer: ElementRef<HTMLDivElement>;

    animTimeout: ReturnType<typeof setTimeout>;
    waveformLength: number;

    public isDraggingPlayhead: boolean;
    public isWaveIdle: boolean;
    public waveformCoordinates?: number[][];

    constructor() {
        this.isDraggingPlayhead = false;
        this.isWaveIdle = true;
        this.waveformCoordinates = [];
        this.waveformLength = 0;
        this.animTimeout = null;
    }

    calculateX = (index: number) => {
        // TODO upgrade this approach
        return (100 / (this.audioShape.length - 1)) * index;
    }

    ngOnInit() {
        if (!this.audioShape) {
            this.animTimeout = setTimeout(this.animCallback, 0);
        } else {
            this.setWaveformCoordinates();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.audioShape !== changes?.audioShape.previousValue) {
            clearTimeout(this.animTimeout);

            if (!this.audioShape) {
                this.animTimeout = setTimeout(this.animCallback, 0);
            } else {
                this.setWaveformCoordinates();
            }
        }
    }

    ngOnDestroy() {
        clearTimeout(this.animTimeout);
    }

    getInteractionX(event) {
        return event.clientX
            ? event.clientX
            : event.touches && event.touches[0]
                ? event.touches[0].clientX
                : event.changedTouches && event.changedTouches[0]
                    ? event.changedTouches[0].clientX
                    : 0;
    }

    public handleInteraction(clientX: number, shouldPlay: boolean = false) {
        if (
            !this.audioShape ||
            !this.waveformContainer ||
            !this.waveformContainer.nativeElement
        ) {
            return;
        }
        const waveformRect = this.waveformContainer.nativeElement.getBoundingClientRect();
        const offset = clientX - waveformRect.left;

        this.interactionHandler(waveformRect.width, offset, shouldPlay);
    }

    public handleMouseDown(event: MouseEvent | TouchEvent) {
        this.isDraggingPlayhead = true;
        this.handleInteraction(this.getInteractionX(event));
    }

    public handleMouseLeave(event: MouseEvent | TouchEvent) {
        if (!this.isDraggingPlayhead) {
            return null;
        }
        this.isDraggingPlayhead = false;
        this.handleInteraction(this.getInteractionX(event), true);
    }

    public handleMouseMove(event: MouseEvent | TouchEvent) {
        if (!this.isDraggingPlayhead) {
            return null;
        }
        this.handleInteraction(this.getInteractionX(event));
    }

    public handleMouseUp(event: MouseEvent | TouchEvent) {
        if (!this.isDraggingPlayhead || this.isWaveIdle) {
            return null;
        }
        this.isDraggingPlayhead = false;
        this.handleInteraction(this.getInteractionX(event), true);
        // Prevent a touchEnd from triggering mouseDown.
        event.preventDefault();
    }

    generateRandomYCoordinate = () => Math.random() / 4 + 0.75;

    generateRandomCoordinates = () => {
        const segmentWidth = Math.round(100 / SAMPLE_LENGTH);
        const paths = [];
        for (let i = 0; i < SAMPLE_LENGTH; i++) {
            paths.push([segmentWidth * i, this.generateRandomYCoordinate()]);
        }

        return paths;
    }

    animCallback = () => {
        this.setWaveformCoordinates();
        if (!this.audioShape) {
            this.animTimeout = setTimeout(this.animCallback, ANIM_TIMEOUT_INTERVAL);
        }
    }

    setWaveformCoordinates = () => {
        const yOffset = 1;
        let waveformCoordinates;

        if (this.audioShape) {
            waveformCoordinates = this.audioShape.map((y, index) => {
                // Shift the y value 50% so that a 0 position is in the middle.
                return [this.calculateX(index), y + yOffset];
            });
        } else {
            // generates a new waveform path if there isn't an audioShape
            waveformCoordinates = this.generateRandomCoordinates();
        }
        this.isWaveIdle = !this.audioShape;
        this.waveformCoordinates = waveformCoordinates;
    }
}
