<div class="quiz-list-wrapper">
    <div class="header">{{ "QUIZ.MY_QUIZZES" | translate }}</div>
    <div *ngIf="userRooms.length">
        <span>Все</span>
        <div class="user-rooms-wrapper">
            <div *ngFor="let room of userRooms"
                 class="user-room"
                 (click)="open(room)">
                <div>{{ room.roomName }}</div>
                <div>{{ room.roomStatus }}</div>
                <button *ngIf="room.roomStatus !== 'finished'"
                        (click)="open(room)">
                    Return to game
                    <mat-icon class="mat-icon-rounded">chevron_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <!--<div *ngIf="userRooms.createdRooms.length">
        <span>Созданные</span>
        <div class="user-rooms-wrapper">
            <div *ngFor="let room of userRooms.createdRooms"
                 class="user-room"
                 (click)="openCreated(room)">
                {{ room.roomName }}
            </div>
        </div>
    </div>
    <div *ngIf="userRooms.startedRooms.length">
        <span>Начатые</span>
        <div class="user-rooms-wrapper">
            <div *ngFor="let room of userRooms.startedRooms"
                 class="user-room"
                 (click)="openStarted(room)">
                {{ room.roomName }}
            </div>
        </div>
    </div>
    <div *ngIf="userRooms.finishedRooms.length">
        <span>Завершенные</span>
        <div class="user-rooms-wrapper">
            <div *ngFor="let room of userRooms.finishedRooms"
                 class="user-room">
                {{ room.roomName }}
            </div>
        </div>
    </div>-->
    <div style="display: flex; justify-content: space-between; margin: 25px;">
        {{ "QUIZ.MY_QUIZZES" | translate }}
        <button mat-raised-button
                (click)="openQuizEditDialog()"
                class="add-quiz-button">
            {{ "QUIZ.CREATE" | translate }}
            <mat-icon class="mat-icon-rounded">add_circle</mat-icon>
        </button>
    </div>

    <div *ngFor="let quiz of quizList"
         class="quiz-card">
        <div>
            <div>{{ quiz.name }}</div>
            <div>{{ quiz.description }}</div>
        </div>
        <div *ngIf="quiz.public"
             class="quiz-status">
            {{ "QUIZ.STATUS." + quiz.status | translate }}
        </div>
        <div>
            <mat-slide-toggle [checked]="!!quiz.shareLink"
                              [matTooltip]="'QUIZ.SHARE_LINK' | translate"
                              (change)="changeCreationShareState($event, quiz.id)">
            </mat-slide-toggle>
            <mat-icon (click)="copyShareLinkToClipboard(quiz.shareLink)"
                      class="mat-icon-rounded"
                      [ngClass]="!quiz.shareLink ? 'share-link-copy-disabled' : 'share-link-copy'">
                content_copy
            </mat-icon>
        </div>
    <!--    <mat-card-subtitle>{{ quiz.id }}</mat-card-subtitle>-->
        <!--<mat-card-content>
            <div>{{ "QUIZ." + (quiz.public ? "PUBLIC" : "PRIVATE") | translate }}</div>
            <div *ngIf="quiz.public">{{ "QUIZ.STATUS." + quiz.status | translate }}</div>
            <mat-slide-toggle [checked]="!!quiz.shareLink"
                              (change)="changeCreationShareState($event, quiz.id)">
                {{ 'QUIZ.SHARE_LINK' | translate }}
            </mat-slide-toggle>
            <div>{{ quiz.shareLink }}</div>
            &lt;!&ndash;        <div *ngIf="quiz.creationShare">{{ "QUIZ.SHARE_LINK" | translate }}: </div>&ndash;&gt;
            <div *ngIf="quiz.status === QuizStatus.REJECTED">{{ "QUIZ.REJECTION_REASON" | translate }}: {{ quiz.rejectionReason }}</div>
        </mat-card-content>-->
        <div>
            <button *ngIf="quiz.public && (quiz.status === QuizStatus.DRAFT || quiz.status === QuizStatus.REJECTED)"
                    (click)="updateQuizStatus(quiz, QuizStatus.IN_REVIEW)">
                {{ "QUIZ.PUBLISH" | translate }}
            </button>
            <button *ngIf="quiz.public && (quiz.status === QuizStatus.READY || quiz.status === QuizStatus.IN_REVIEW)"
                    (click)="updateQuizStatus(quiz, QuizStatus.DRAFT)">
                {{ "QUIZ.REMOVE_FROM_PUBLISHING" | translate }}
            </button>
            <button (click)="runQuiz(quiz.id)">
                <mat-icon class="mat-icon-rounded">play_circle</mat-icon>
            </button>
            <button (click)="openQuiz(quiz)">
                <mat-icon class="mat-icon-rounded">edit</mat-icon>
            </button>
            <button (click)="openQuizEditDialog(quiz)">
                <mat-icon class="mat-icon-rounded">edit</mat-icon>
            </button>
        </div>

        <button (click)="deleteQuiz(quiz.id)">
            <mat-icon color="accent"
                      class="mat-icon-rounded">
                delete
            </mat-icon>
        </button>
        <!--<div class="corner-buttons">
            <button mat-mini-fab
                    (click)="openQuizEditDialog(quiz)"
                    color="primary">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab
                    (click)="deleteQuiz(quiz.id)"
                    color="accent">
                <mat-icon>delete</mat-icon>
            </button>
        </div>-->
    </div>
    <!--<mat-card *ngFor="let quiz of quizList"
              class="quiz-card">
        <mat-card-header>
            <mat-card-title>{{ quiz.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-subtitle>{{ quiz.id }}</mat-card-subtitle>
        <mat-card-content>
            <div>{{ quiz.description }}</div>
            <div>{{ "QUIZ." + (quiz.public ? "PUBLIC" : "PRIVATE") | translate }}</div>
            <div *ngIf="quiz.public">{{ "QUIZ.STATUS." + quiz.status | translate }}</div>
            <mat-slide-toggle [checked]="!!quiz.shareLink"
                              (change)="changeCreationShareState($event, quiz.id)">
                {{ 'QUIZ.SHARE_LINK' | translate }}
            </mat-slide-toggle>
            <div>{{ quiz.shareLink }}</div>
    &lt;!&ndash;        <div *ngIf="quiz.creationShare">{{ "QUIZ.SHARE_LINK" | translate }}: </div>&ndash;&gt;
            <div *ngIf="quiz.status === QuizStatus.REJECTED">{{ "QUIZ.REJECTION_REASON" | translate }}: {{ quiz.rejectionReason }}</div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button
                    (click)="runQuiz(quiz.id)">
                {{ "QUIZ.RUN" | translate }}
            </button>
            <button mat-raised-button
                    (click)="openQuiz(quiz)">
                {{ "QUIZ.EDIT" | translate }}
            </button>
            <button *ngIf="quiz.public && (quiz.status === QuizStatus.DRAFT || quiz.status === QuizStatus.REJECTED)"
                    mat-raised-button
                    (click)="updateQuizStatus(quiz, QuizStatus.IN_REVIEW)">
                {{ "QUIZ.PUBLISH" | translate }}
            </button>
            <button *ngIf="quiz.public && (quiz.status === QuizStatus.READY || quiz.status === QuizStatus.IN_REVIEW)"
                    mat-raised-button
                    (click)="updateQuizStatus(quiz, QuizStatus.DRAFT)">
                {{ "QUIZ.REMOVE_FROM_PUBLISHING" | translate }}
            </button>
        </mat-card-actions>
        <div class="corner-buttons">
            <button mat-mini-fab
                    (click)="openQuizEditDialog(quiz)"
                    color="primary">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab
                    (click)="deleteQuiz(quiz.id)"
                    color="accent">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </mat-card>-->
    <br/><br/>
    <!--<button mat-raised-button
            (click)="openQuizEditDialog()">
        {{ "FRIENDS.ADD" | translate }}
    </button>-->
    <br/><br/>
    <button mat-raised-button
            color="accent"
            (click)="signOut()">
        SIGN OUT
    </button>
    <br/><br/>

    <button mat-raised-button
            color="accent"
            (click)="bankWithQuestions()">
        QUESTION BANK
    </button>
    <br/><br/>

    <!--Testing audion-->
    <!--<input hidden
           (change)="onFileSelected(fileInput.files[0])"
           #fileInput
           type="file"
           [accept]="accept">-->
    <!--<button *ngIf="filename !== ''"
            mat-raised-button
            color="accent"
            (click)="checkFileStatus()">
        CHECK FILE STATUS
    </button>
    <button *ngIf="filename !== ''"
            mat-raised-button
            color="accent"
            (click)="getFile()">
        GET FILE
    </button>
    <audio *ngIf="myFileSrcShortProcessed" controls><source [src]="domSanitizer.bypassSecurityTrustResourceUrl(myFileSrcShortProcessed)"></audio>

    <br/><br/>
    <mat-progress-spinner [mode]="spinnerMode" *ngIf="enabledSpinner"></mat-progress-spinner>
    <br/>
    <div *ngIf="myFile">
        <mat-checkbox color="accent" [(ngModel)]="uploadMetadata">
            Upload metadata
        </mat-checkbox>
        <button mat-raised-button
                color="accent"
                (click)="uploadImage()">
            UPLOAD IMAGE
        </button>
    </div>
    <br/>
    <div>
        <button mat-raised-button
                style="margin-right: 2px"
                color="primary"
                *ngIf="!myFile"
                (click)="downloadImage()">
            DOWNLOAD IMAGE
        </button>
        <button mat-raised-button
                style="margin-left: 2px"
                color="warn"
                (click)="deleteImage()">
            DELETE IMAGE
        </button>
        <br/>
        <img [src]="myFileSrc" *ngIf="myFileSrc && questionType === questionTypes.IMAGE" style="height: 300px; width: 500px" alt="No Picture available"/>
        <video [src]="myFileSrc" *ngIf="myFileSrc && questionType === questionTypes.VIDEO" height="200" controls></video>
        <audio *ngIf="myFileSrc && questionType === questionTypes.AUDIO" controls><source [src]="domSanitizer.bypassSecurityTrustResourceUrl(myFileSrc)"></audio>
    </div><br/>
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Nickname</mat-label>
            <input matInput type="text" [(ngModel)]="this.nickname">
        </mat-form-field>
        <br/>
        <button mat-raised-button
                color="primary"
                *ngIf="this.nickname"
                (click)="link()">
            LINK ACCOUNTS
        </button>
    </div>-->
</div>
