import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Recommended to use "combine" type instead of "transfer" type to prevent double tone transfer
export type ProcessingType = 'combine' | 'transfer' | 'accompaniment';

@Injectable()
export class AudioEditorService {

    public processingType: ProcessingType;

    constructor(private httpClient: HttpClient) {
    }

    public sendFileToProcess(processingType: ProcessingType, file: Blob): Observable<string> {
        console.log('DEBUG: SEND TO PROCESS');
        const form = new FormData();
        form.set('song', file);
        // high_confidence = 0 | 1 (по умолчанию 0) - заменяет частоты, отгаданные с низкой точностью, на ближайшие слева или справа
        // lower_high_frequency = 0 | 1 (по умолчанию 0) - занижает высокие частоты
        this.processingType = processingType;

        return this.httpClient.post<{ name: string }>(
            'https://ai-partygames.com/alex/test/process/'
            + (processingType === 'accompaniment' ? 'accompaniment' : 'combine')
            + '?high_confidence=1&lower_high_frequency=1&remove_low_frequency=1',
            form
        ).pipe(
            map(ans => ans.name)
        );
    }

    public getFileStatus(filename: string): Observable<{filename: string, status: string}> {
        console.log('DEBUG: GET STATUS');
        return this.httpClient.get<{ status: string }>(
            'https://ai-partygames.com/alex/test/status/' + filename
        ).pipe(
            map(ans => {
                return {
                    filename,
                    status: ans.status
                };
            })
        );
    }

    public getProcessedFile(filename: string): Observable<Blob> {
        console.log('DEBUG: GET FILE');
        return this.httpClient.get(
            'https://ai-partygames.com/alex/test/result/' + filename + '/'
            + (this.processingType === 'transfer' ? 'tt' : (this.processingType === 'combine' ? 'combined' : 'accompaniment')),
            {
                // Accept: 'audio/x-wav',
                // responseType: 'blob' as 'json',
                // observe: 'events',
                responseType: 'blob',
            }
        )/*.subscribe(ans => {
            const myFileShortProcessed = ans;
            const reader = new FileReader();
            reader.readAsDataURL(this.myFileShortProcessed);
            reader.onload = () => {
                this.myFileSrcShortProcessed = reader.result as string;
            };
        })*/;
    }
}
