<div class="dialog-wrapper">
    <div mat-dialog-content
         class="dialog-content-wrapper">
        <div class="header">{{ 'QUIZ.AI_QUIZ.TOPIC' | translate }}</div>
        <div *ngIf="this.topics.length === 0 || !this.userInfo"
             class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
        <mat-form-field>
            <mat-label>{{ 'QUIZ.AI_QUIZ.SELECT_LANGUAGE' | translate }}</mat-label>
            <mat-select [value]="language"
                        (valueChange)="changeLanguageHandler($event)">
                <mat-option [value]="'RUSSIAN'">Русский</mat-option>
                <mat-option [value]="'ENGLISH'">English</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="this.topics.length > 0">
            <mat-label>{{ 'QUIZ.AI_QUIZ.SELECT_TOPIC' | translate }}</mat-label>
            <mat-select [value]="this.topics[0]"
                        (valueChange)="changeTopicHandler($event)">
                <mat-option [value]="'custom'">{{ 'QUIZ.AI_QUIZ.CUSTOM_TOPIC' | translate }}</mat-option>
                <mat-option *ngFor="let topic of getMediumTopics()"
                            [value]="topic.name">
                    {{ topic.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedTopic === 'custom'"
                        appearance="fill"
                        class="dialog-input">
            <mat-label>{{ 'QUIZ.AI_QUIZ.CUSTOM_TOPIC_LABEL' | translate }}</mat-label>
            <input matInput
                   minlength="1"
                   maxlength="50"
                   [value]="customTopic"
                   (input)="changeCustomTopicHandler($event.target)">
        </mat-form-field>
        <mat-form-field *ngIf="selectedTopic === 'custom'">
            <mat-label>{{ 'QUIZ.AI_QUIZ.SELECT_QUIZ_DIFFICULTY' | translate }}</mat-label>
            <mat-select [value]="quizDifficulty"
                        (valueChange)="changeQuizDifficultyHandler($event)">
                <mat-option [value]="'EASY'">{{ 'QUIZ.AI_QUIZ.DIFFICULTY.EASY' | translate }}</mat-option>
                <mat-option [value]="'MEDIUM'">{{ 'QUIZ.AI_QUIZ.DIFFICULTY.MEDIUM' | translate }}</mat-option>
                <mat-option [value]="'HARD'">{{ 'QUIZ.AI_QUIZ.DIFFICULTY.HARD' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedTopic === 'custom'">
            <mat-label>{{ 'QUIZ.AI_QUIZ.SELECT_QUESTIONS_COUNT' | translate }}</mat-label>
            <mat-select [value]="questionsCount"
                        (valueChange)="changeQuestionsCountHandler($event)">
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="15">15</mat-option>
                <mat-option [value]="20">20</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="selectedTopic === 'custom' && !userInfo.premium"
         class="premium-tooltip">
        <div>
            <div>{{ 'QUIZ.AI_QUIZ.NEED_PREMIUM' | translate }}</div>
            <div>{{ 'QUIZ.AI_QUIZ.COST' | translate }}</div>
        </div>
        <button mat-raised-button color="primary" (click)="sendForm()">{{ 'QUIZ.AI_QUIZ.PAY' | translate }}</button>
    </div>
    <!--<button mat-raised-button
            color="primary"
            [disabled]="quizTopic === '' || quizTopic !== 'Общая'"
            (click)="createGame()"
            cdkFocusInitial>
        {{ 'COMMON.CREATE' | translate }}
    </button>-->
    <button mat-raised-button
            color="primary"
            [disabled]="quizTopic === '' || selectedTopic === 'custom' && !userInfo.premium"
            (click)="createGame()"
            cdkFocusInitial>
        {{ 'COMMON.CREATE' | translate }}
    </button>
</div>
